import React, { useContext, useEffect, useState } from 'react';
// import DataTable from 'react-data-table-component';
import { CadDashboardTableCustomStyles, getShowingDateText, getShowingYearMonthDate, stringToBase64 } from '../../../Components/Common/Utility';
import DateTimeCounter from '../../Common/DateTimeCounter';
import { useNavigate } from 'react-router-dom';
import { IncidentContext } from '../../../CADContext/Incident';
import {
  compareStrings
} from "../../../CADUtils/functions/common";
import ResourcesStatusServices from "../../../CADServices/APIs/resourcesStatus";
import { useSelector } from 'react-redux';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import MasterTableListServices from '../../../CADServices/APIs/masterTableList'
import { useQuery } from 'react-query';
import Tooltip from '../../Common/Tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import MonitorServices from '../../../CADServices/APIs/monitor'

const ResourcesTableSection = (props) => {
  const { resources, isResourceStatusTab = false, isCADMap = false, isViewEventDetails = false } = props
  const { resourceRefetch } = useContext(IncidentContext);
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [editValue, setEditValue] = useState("");
  const [loginAgencyID, setLoginAgencyID] = useState();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 670);
  const [statusData, setStatusData] = useState({}); // Holds the status for each row
  const [resourceStatusColorData, setResourceStatusColorData] = useState([]);
  const [loginPinID, setLoginPinID,] = useState('');
  const [rows, setRows] = useState(5);
  const [first, setFirst] = useState(0);
  
  const getFilteredOptions = (status) => {
    let validStatusCodes = [];
    if (status === 'DP') {
      validStatusCodes = ['AV', 'ER', 'EH', 'AR', 'AH', 'DP'];
    } else if (status === 'ER') {
      validStatusCodes = ['AV', 'EH', 'AR', 'AH', 'ER'];
    } else if (status === 'EH') {
      validStatusCodes = ['AV', 'ER', 'AR', 'AH', 'EH'];
    } else if (status === 'AR') {
      validStatusCodes = ['AV', 'AH', 'AR'];
    } else {
      validStatusCodes = resourceStatusColorData.map(option => option.ResourceStatusCode);
    }
    return resourceStatusColorData.filter(option => validStatusCodes.includes(option.ResourceStatusCode));
  };

  const getResourceStatusColorKey = `/CAD/ResourceStatusColor/GetResourceStatusColor`;
  const { data: resourceStatusColorList, isSuccess: isFetchResourceStatusColorList } = useQuery(
    [getResourceStatusColorKey, {
      IsActive: 1
    }],
    MasterTableListServices.getResourceStatusColor,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
  useEffect(() => {
    if (isFetchResourceStatusColorList && resourceStatusColorList) {
      const res = JSON.parse(resourceStatusColorList?.data?.data);
      const data = res?.Table
      setResourceStatusColorData(data || [])
    } else {
      setResourceStatusColorData([])
    }
  }, [isFetchResourceStatusColorList, resourceStatusColorList])

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID);
      setLoginPinID(localStoreData?.PINID)
    }
  }, [localStoreData]);

  const handleStatusChange = async (row, newValue) => {
    const data = {
      Status: newValue,
      IncidentID: row?.IncidentID,
      Resources: row?.ResourceID,
      CreatedByUserFK: loginAgencyID,
    };

    try {
      const response = await ResourcesStatusServices.incidentRecourseStatus(data);
      if (response?.status === 200) {
        resourceRefetch();
      } else {
        console.error("Failed to update status:", response);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  useEffect(() => {
    const initialStatusData = {};
    resources.forEach(row => {
      initialStatusData[row.IncidentID] = row.Status;
    });
    setStatusData(initialStatusData);
  }, [resources]);

  const getStatusColors = (statusCode) => {
    const statusItem = resourceStatusColorData.find(item => item.ResourceStatusCode === statusCode);
    return statusItem
      ? { backgroundColor: statusItem.BackColor, color: statusItem.ForeColor }
      : {}; // Default to empty if no match found
  };

  const initialCols = [
    {
      name: 'Resource Type',
      selector: (row) => row.ResourceTypeCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceTypeCode, rowB.ResourceTypeCode),
      width: isSmallScreen ? "130px" : "130px",
    },
    {
      name: 'Resource #',
      selector: (row) => {
        return (<>{row.ResourceNumber} {row.IsPrimaryResource ? <span
          style={{
            backgroundColor: "#3358ff", color: "#ffffff",
            padding: '4px 4px',
            borderRadius: '4px',
            display: 'inline-block',
            minWidth: '22px',
            textAlign: 'center'
          }}
        >
          {"P"}
        </span> : ""} </>)
      },
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceNumber, rowB.ResourceNumber),
      width: isSmallScreen ? "160px" : "160px",
    },
    {
      name: 'Status',
      selector: (row) => {
        const colors = getStatusColors(row.Status); // Get colors based on Status
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                ...colors,
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                minWidth: '50px',
                textAlign: 'center'
              }}
            >
              {row.Status}
            </span>
            {(row.Status !== "AV") && (
              <select
                onChange={(e) => handleStatusChange(row, e.target.value)}
                value={
                  getFilteredOptions(row.Status)?.find((i) => i?.ResourceStatusCode === row.Status)?.ResourceStatusCode ||
                  getFilteredOptions(row.Status)?.[0]?.ResourceStatusCode
                }
                disabled={isViewEventDetails}
                className="form-select status-dropdown"
                style={{
                  width: '20px',
                  padding: '2px',
                  fontSize: '16px',
                  marginLeft: '5px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  appearance: 'auto',
                }}
              >
                {getFilteredOptions(row.Status)?.map((option, index) => (
                  <option key={index} value={option.ResourceStatusCode}>
                    {`${option.ResourceStatusCode} | ${option.Description}`}&nbsp;
                  </option>
                ))}
              </select>
            )
            }
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Status, rowB.Status),
      width: isSmallScreen ? "120px" : "120px",
    },
    {
      name: 'Status Date & Time',
      selector: (row) => (row.StatusDT ? getShowingDateText(row.StatusDT) : ""),
      sortable: true,
      width: isSmallScreen ? "170px" : "170px",

    },
    {
      name: 'E Timer',
      selector: (row) => (row.StatusDT && row.Status !== "AV" ? <DateTimeCounter data={row.StatusDT} /> : ""),
      sortable: true,
      width: isSmallScreen ? "100px" : "100px",

    },
    {
      name: 'CAD Event #',
      selector: (row) => row.CADIncidentNumber,
      sortable: true,
      width: isSmallScreen ? "100px" : "120px",

    },
    {
      name: 'Incident Recvd DT&TM',
      selector: (row) => (row.IncidentDtTm ? getShowingDateText(row.IncidentDtTm) : ""),
      sortable: true,
      width: isSmallScreen ? "180px" : "180px",

    },
    {
      name: 'RMS Incident#',
      selector: (row) => row.IncidentNumber,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.IncidentNumber, rowB.IncidentNumber),
      width: isSmallScreen ? "170px" : "170px",
    },
    {
      name: 'CFS Code',
      selector: (row) => row.CFSCODE,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCODE, rowB.CFSCODE),
      width: isSmallScreen ? "170px" : "170px",
    },
    {
      name: 'CFS Description',
      selector: (row) => row.CFSCodeDescription,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCodeDescription, rowB.CFSCodeDescription),
      width: isSmallScreen ? "190px" : "190px",
      cell: (row) => (
        <Tooltip text={row?.CFSCodeDescription || ''} maxLength={15} />
      ),
    },
    {
      name: 'Zone',
      selector: (row) => row.ZoneCode,
      sortable: true,
      width: isSmallScreen ? "100px" : "100px",
    },
    {
      name: 'Officer 1',
      selector: (row) => row.Officer1,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Officer1, rowB.Officer1),
      width: isSmallScreen ? "110px" : "110px",
    },
    {
      name: 'Officer 2',
      selector: (row) => row.Officer2,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Officer2, rowB.Officer2),
      width: isSmallScreen ? "110px" : "110px",

    },
  ];

  const ResourceStatusColumns = [
    {
      name: 'Resource Type',
      selector: (row) => row.ResourceTypeCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceTypeCode, rowB.ResourceTypeCode),
      width: "20%",
    },
    {
      name: 'Resource #',
      selector: (row) => row.ResourceNumber,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceNumber, rowB.ResourceNumber),
      width: "20%",
    },
    {
      name: 'Incident #',
      selector: (row) => row.CADIncidentNumber,
      sortable: true,
      width: "20%",
    },
    {
      name: 'Status',
      selector: (row) => {
        const colors = getStatusColors(row.Status); // Get colors based on Status
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                ...colors,
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                minWidth: '50px',
                textAlign: 'center'
              }}
            >
              {row.Status}
            </span>
            {row.Status !== "AV" && (
              <select
                onChange={(e) => handleStatusChange(row, e.target.value)}
                className="form-select status-dropdown"
                style={{
                  width: '20px',
                  padding: '2px',
                  fontSize: '16px',
                  marginLeft: '5px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  appearance: 'auto'
                }}
              >
                {resourceStatusColorData.map((option, index) => (
                  <option key={index} value={option.ResourceStatusCode}>
                    {`${option.ResourceStatusCode} | ${option.Description}`}&nbsp;
                  </option>
                ))}
              </select>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Status, rowB.Status),
      width: "20%",
    },
    {
      name: 'Status Date & Time',
      selector: (row) => (row.StatusDT ? getShowingYearMonthDate(row.StatusDT) : ""),
      sortable: true,
      width: "20%",
    },
  ];

  const MapResourceStatusColumns = [
    // {
    //   name: 'Resource Type',
    //   selector: (row) => row.ResourceTypeCode,
    //   sortable: true,
    //   sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceTypeCode, rowB.ResourceTypeCode),
    //   width: "50%",
    // },
    {
      name: 'Resource #',
      selector: (row) => {
        const colors = getStatusColors(row.Status); // Get colors based on Status

        return (<>{row.ResourceNumber} {row.PrimaryResourceName ? <span
          style={{
            backgroundColor: "#3358ff", color: "#ffffff",
            padding: '4px 4px',
            borderRadius: '4px',
            display: 'inline-block',
            minWidth: '22px',
            textAlign: 'center'
          }}
        >
          P
        </span> : ""} </>)
      },
      width: "50%",
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceNumber, rowB.ResourceNumber),
    },
    {
      name: 'Status',
      selector: (row) => {
        const colors = getStatusColors(row.Status); // Get colors based on Status
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                ...colors,
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                minWidth: '50px',
                textAlign: 'center'
              }}
            >
              {row.Status}
            </span>
            {row.Status !== "AV" && (
              <select
                onChange={(e) => handleStatusChange(row, e.target.value)}
                className="form-select status-dropdown"
                style={{
                  width: '20px',
                  padding: '2px',
                  fontSize: '16px',
                  marginLeft: '5px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  appearance: 'auto'
                }}
              >
                {resourceStatusColorData.map((option, index) => (
                  <option key={index} value={option.ResourceStatusCode}>
                    {`${option.ResourceStatusCode} | ${option.Description}`}&nbsp;
                  </option>
                ))}
              </select>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Status, rowB.Status),
      width: "50%",

    },

  ];

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1400);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isResourceStatusTab) {navigate(`/cad/dashboard-page?IncId=${stringToBase64(incidentID)}&IncNo=${incidentNumber}&IncSta=true`); }
  // }, [incidentID, incidentNumber]);

  const conditionalRowStyles = [
    {
      when: row => row === editValue,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#001f3fbd',
          color: 'white',
        },
      },
    }
  ];

  const onIncidentRowClick = (row) => {
    if (row?.ResourceID) {
      setEditValue(row);
      if (!isResourceStatusTab) {
        navigate(`/cad/dashboard-page?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.CADIncidentNumber}&resourceID=${row?.ResourceID}&isResourceView=true&IncSta=true`);
      }
    } else {
      setEditValue(null);
      navigate(`/cad/dashboard-page`);
    }
  };

  const [columns, setColumns] = useState([]);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
  const [draggingColumnIndex, setDraggingColumnIndex] = useState(null);

  const sanitizeColumns1 = (columns) => {
    return columns?.map((col) => ({
      name: col.name.props?.header,
      selector: col.selector,
      sortable: col.sortable,
      width: col.width,
    }));
  };

  const restoreColumns = (savedColumns) => {
    //     // Input data
    let inputData = `${savedColumns}`;

    // Step 1: Parse JSON string into an array (if it's a string)
    if (typeof inputData === 'string') {
      try {
        inputData = JSON.parse(inputData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }

    // Step 2: Ensure inputData is an array
    if (!Array.isArray(inputData)) {
      console.error('Input data is not an array.');
    } else {
      // Step 3: Map over the array and process each column
      savedColumns = inputData.map((col) => {
        return {
          name: col?.name,
          width: col?.width || 'auto', // Default width if not provided
          sortable: col?.sortable || false // Default sortable to false
        };
      });

      // Step 4: Log the mapped array
    }
    if (!Array.isArray(savedColumns)) {
      console.error("savedColumns is not an array:", savedColumns);
      return []; // Return an empty array if savedColumns is invalid
    }

    return savedColumns.map((col) => {
      const matchingColumn = initialCols.find((initialCol) => {
        const colName =
          typeof initialCol.name === "string"
            ? initialCol.name
            : initialCol.name.props?.children;
        return colName === col.name;
      });

      return {
        ...col,
        name: matchingColumn?.name || col.name,
        selector: matchingColumn?.selector || col.selector,
        cell: matchingColumn?.cell || col.cell,
      };
    });
  };

  const getUserTableKey = `/CAD/UserTableColumnsResourse/GetData_UserTableResourse/${loginPinID}`;
  const { data: getDataUserTableResourse, isSuccess: isFetchUserTable } = useQuery(
    [getUserTableKey, {
      UserID: loginPinID,
    }],
    MonitorServices.getDataUserTableResourse,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!loginPinID,
    }
  );

  useEffect(() => {
    const tableCategory = isResourceStatusTab
      ? "ResourceStatusColumns"
      : isCADMap
        ? "MapResourceStatusColumns"
        : "initialCols";

    const selectedColumns = isResourceStatusTab
      ? ResourceStatusColumns
      : isCADMap
        ? MapResourceStatusColumns
        : initialCols;
    const lastCategory = localStorage.getItem("tableCategory_RT");
    const savedColumns = localStorage.getItem("tableColumns_RT");
    if (tableCategory !== lastCategory) {
      localStorage.setItem("tableColumns_RT", "");
      localStorage.setItem("tableCategory_RT", tableCategory);
      saveColumnsToLocalStorage(selectedColumns);
    }
    if (savedColumns && savedColumns !== "") {
      const restoredColumns = restoreColumns(JSON.parse(savedColumns), selectedColumns);
      setColumns(restoredColumns);
    } else {
      saveColumnsToLocalStorage(selectedColumns);
      setColumns(selectedColumns);
    }
  }, [isResourceStatusTab, isCADMap]);

  useEffect(() => {
    const savedColumns = localStorage.getItem("tableColumns_RT");
    if (savedColumns && savedColumns !== "") {
      const restoredColumns = restoreColumns(JSON.parse(savedColumns), columns);
      setColumns(restoredColumns);
    }
  }, [resourceStatusColorData]);

  const sanitizeColumns = (columns) => {
    return columns.map((col) => ({
      name: typeof col.name === "string" ? col.name : col.name.props?.children || col.name,
      selector: col.selector,
      sortable: col.sortable,
      width: col.width,
    }));
  };

  useEffect(() => {
    if (getDataUserTableResourse && isFetchUserTable) {
      const descriptionData = JSON.parse(getDataUserTableResourse?.data?.data)
      setColumns(
        restoreColumns(descriptionData));
    } else {
      setColumns(initialCols);
    }
  }, [getDataUserTableResourse, isFetchUserTable]);

  const saveColumnsToLocalStorage = (columns) => {
    const sanitizedColumns = sanitizeColumns(columns);
    localStorage.setItem("tableColumns_RT", JSON.stringify(sanitizedColumns));
  };

  const columnElements = columns?.map((column, index) => ({
    ...column,
    name: (
      <div
        className={selectedColumnIndex === index ? "selected-column" : ""}
        draggable
        style={{
          cursor: "move",
          opacity: draggingColumnIndex === index ? 0.5 : 1,
        }}
      >
        {typeof column.name === "string" ? column.name : column.name.props?.children}
      </div>
    ),
  }))

  const handleColumnReorder = async (e) => {
    const test = columns?.map((column, index) => ({
      ...column,
      name: e.columns[index],
    }))
    const sanitizedColumns = sanitizeColumns1(test);
    const data = {
      Description: JSON.stringify(sanitizedColumns),
      UserID: loginPinID,
      AgencyID: loginAgencyID,
      CreatedByUserFK: loginPinID
    }
    const response = await MonitorServices.insertUserTableResourse(data);
  };

  const createdClasses = new Set();
  const createDynamicClass = (color) => {
    const className = `color-${color?.replace('#', '')}`;
    if (!createdClasses.has(className)) {
      const style = document.createElement('style');
      style.innerHTML = `
          .${className} {
              background-color: ${color} !important;
              color: black !important;
              padding: 5px 8px;
              font-size: 12px;
              border-radius: 4px;
              // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          }
      `;
      document.head.appendChild(style);
      createdClasses.add(className);
    }
    return className;
  };

  const rowClassName = (data) => {
    const color = getStatusColors(data?.PriorityCode)?.backgroundColor;
    const dynamicClass = createDynamicClass(color);
    return dynamicClass;
  };

  const onRowsChange = (e) => {
    setRows(parseInt(e.target.value, 10));
    setFirst(0);
  };

  const paginatorTemplate = {
    layout: ' FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
    RowsPerPageDropdown: () => (
      <div className="custom-rows-per-page">
       
        <select value={rows} onChange={onRowsChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </select>
      </div>
    ),
    CurrentPageReport: (options) => (
      <span style={{ marginRight: '8px', fontSize: '13px' }}>
    Showing {options.first} to {options.last} of {options.totalRecords}
  </span>
    )
  };

  return (
    <>
      <div className='table-container CAD-table'>
        <div className="card">
          <DataTable
            value={resources}
            onColReorder={(e) => handleColumnReorder(e)}
            reorderableColumns
            size="small"
            selectionMode="single"
            selection={editValue}
            onSelectionChange={(e) => onIncidentRowClick(e?.value)}
            dataKey="ResourceID"
            sortMode="multiple"
            stripedRows
            columnResizeMode="expand"
            resizableColumns
            tableStyle={{ minWidth: '10rem' }}
            rowClassName={rowClassName}
            className="small-table" // Apply the custom class
            paginator 
            rows={rows}
            first={first}
            onPage={(e) => setFirst(e.first)}
            paginatorTemplate={paginatorTemplate}
          // scrollable scrollHeight="400px" 
          >

            {columnElements.map((item) => {
              return <Column
                key={item?.name.props?.children}
                sortable
                field={item?.selector}
                header={item?.name.props?.children}
                style={{ width: '25%' }}
              />;
            })}
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default ResourcesTableSection;
