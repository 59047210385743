import React, { useEffect, useState } from 'react';
import FormTitleSection from '../../Common/FormTitleSection'
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import { coloredStyle_Select, colorLessStyle_Select } from '../../Utility/CustomStylesForReact';
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import { useQuery } from 'react-query';
import { dropDownDataModel, isEmpty, isEmptyObject, compareStrings } from '../../../CADUtils/functions/common';
import { toastifyError, toastifySuccess } from '../../../Components/Common/AlertMsg';
import { useSelector } from 'react-redux';
import CADConfirmModal from '../../Common/CADConfirmModal';
import { SearchFilter } from '../../Common/SearchFilter';

const ResourceSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [resourceId, setResourceId] = useState("")
  const [loginPinID, setLoginPinID] = useState(1);
  const [agencyCodeDropDown, setAgencyCodeDropDown] = useState([])
  const [primaryOfficerDropDown, setPrimaryOfficerDropDown] = useState([])
  const [shiftDropDown, setShiftDropDown] = useState([])
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [zoneDropDown, setZoneDropDown] = useState([])
  const [loginAgencyID, setLoginAgencyID] = useState();
  const [stationCodeDropDown, setStationCodeDropDown] = useState([])
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [confirmType, setConfirmType] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [isActive, setIsActive] = useState('');

  const [
    resourceState,
    setResourceState,
    handleResourceState,
    clearResourceState,
  ] = useObjState({
    agencyCode: "",
    agencyType: "",
    agencyType2: "",
    resource: "",
    resourceType: "",
    station: "",
    primaryOfficer: "",
    primaryOfficer2: "",
    primaryOfficer3: "",
    primaryOfficer4: "",
    primaryOfficer5: "",
    primaryOfficer6: "",
    primaryOfficer7: "",
    primaryOfficer8: "",
    hours24Resource: false,
    carResource: false,
    zone: "",
    dutyStatus: "offduty",
    shift: "",
  });

  const [
    errorResourceState,
    _setErrorResourceState,
    handleErrorResourceState,
    clearStateResourceState,
  ] = useObjState({
    agencyCode: false,
    resource: false,
    resourceType: false,
    station: false,
    primaryOfficer: false,
    zone: false,
    shift: false,
  });

  const getResourcesKey = `/CAD/MasterResource/GetResource/${parseInt(pageStatus)}`;
  const { data: getResourcesData, isSuccess, refetch, isError: isNoData } = useQuery(
    [getResourcesKey, { IsActive: parseInt(pageStatus), AgencyID: loginAgencyID },],
    MasterTableListServices.getResources,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!loginAgencyID,
    }
  );
  useEffect(() => {
    if (isSuccess && getResourcesData) {
      const data = JSON.parse(getResourcesData?.data?.data || [])?.Table
      setFilterListData(data)
      setListData(data)
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [getResourcesData, isSuccess])

  const shiftDataKey = `/CAD/MasterResourceShift/GetData_Shift`;
  const { data: shiftData, isSuccess: isFetchShiftData } = useQuery(
    [shiftDataKey, { AgencyID: loginAgencyID },],
    MasterTableListServices.getShift,
    {
      refetchOnWindowFocus: false,
      enabled: !!loginAgencyID
    }
  );

  useEffect(() => {
    if (shiftData && isFetchShiftData) {
      const data = JSON.parse(shiftData?.data?.data);
      setShiftDropDown(data?.Table || [])
    }
  }, [shiftData, isFetchShiftData])

  const stationCodeKey = `/CAD/MasterStationCode/StationCode`;
  const { data: stationCodeData, isSuccess: isFetchStationCode } = useQuery(
    [stationCodeKey, {
      Action: "GET",
      isActive: 1
    },],
    MasterTableListServices.getStationCode,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (stationCodeData && isFetchStationCode) {
      const data = JSON.parse(stationCodeData?.data?.data);
      setStationCodeDropDown(data?.Table || [])
    }
  }, [stationCodeData, isFetchStationCode])

  useEffect(() => {
    if (shiftData && isFetchShiftData) {
      const data = JSON.parse(shiftData?.data?.data);
      setShiftDropDown(data?.Table)
    }
  }, [shiftData, isFetchShiftData])

  const GetResourceTypeKey = `/CAD/MasterResourceType/GetResourceType`;
  const { data: resourceTypeData } = useQuery(
    [GetResourceTypeKey, { Action: "GET", IsActive: 1 },],
    MasterTableListServices.getResourceType,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getZoneKey = `/CAD/GeoPetrolZone/GetData_Zone`;
  const { data: getZoneData, isSuccess: isFetchGeoZoneData } = useQuery(
    [getZoneKey, { IsActive: 1 },],
    MasterTableListServices.getDataZone,
    {
      refetchOnWindowFocus: false,
    }
  );

  const GetPrimaryOfficerKey = `CAD/MasterPrimaryOfficer/PrimaryOfficer`;
  const { data: getPrimaryOfficerData, isSuccess: isFetchPrimaryOfficers } = useQuery(
    [GetPrimaryOfficerKey, { AgencyID: loginAgencyID },],
    MasterTableListServices.getPrimaryOfficer,
    {
      refetchOnWindowFocus: false,
      enabled: !!loginAgencyID
    }
  );

  useEffect(() => {
    if (getPrimaryOfficerData && isFetchPrimaryOfficers) {
      const data = JSON.parse(getPrimaryOfficerData?.data?.data);
      setPrimaryOfficerDropDown(data?.Table || [])
    }
  }, [getPrimaryOfficerData, isFetchPrimaryOfficers])

  const getAgencyCodeKey = `/CAD/MasterAgency/MasterAgencyCode`;
  const { data: getAgencyCodeData, isSuccess: isFetchAgencyCode } = useQuery(
    [getAgencyCodeKey, {},],
    MasterTableListServices.getAgencyCode,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
      setLoginAgencyID(localStoreData?.AgencyID)
    }
  }, [localStoreData]);

  useEffect(() => {
    if (isFetchAgencyCode && getAgencyCodeData) {
      const data = JSON.parse(getAgencyCodeData?.data?.data);
      setAgencyCodeDropDown(data?.Table || [])
    }
  }, [isFetchAgencyCode, getAgencyCodeData]);

  const getByIdResourcesKey = `/CAD/CallTackerResource/GetData_Resources/${resourceId}`;
  const { data: getByIdResourcesData, isSuccess: isFetchGetByIdResource } = useQuery(
    [getByIdResourcesKey, { ResourceID: resourceId },],
    MasterTableListServices.getResources,
    {
      refetchOnWindowFocus: false,
      enabled: !!resourceId
    }
  );

  async function handelActiveInactive() {
    const data = {
      ResourceID: activeInactiveData?.ResourceID,
      DeletedByUserFK: loginPinID,
      IsActive: isActive,
    }
    const response = await MasterTableListServices.changeStatusMasterResource(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      refetch();
    }
    setShowModal(false);
  }

  useEffect(() => {
    if (getByIdResourcesData && isFetchGetByIdResource) {
      const data = JSON.parse(getByIdResourcesData?.data?.data)?.Table[0]
      const officerIDsArray = data?.OfficerIDs ? data?.OfficerIDs.split(',').map(Number) : [];
      setResourceState({
        ResourceID: data?.ResourceID,
        agencyCode: agencyCodeDropDown?.find((i) => i?.AgencyID === data?.AgencyID) || "",
        agencyType: "",
        agencyType2: "",
        resource: data?.ResourceNumber || "",
        resourceType: resourceTypeData?.data?.data?.Table?.find((i) => i?.ResourceTypeID === data?.ResourceTypeID) || "",
        station: stationCodeDropDown?.find((i) => i?.stationID === data?.StationID) || "",
        primaryOfficer: primaryOfficerDropDown?.find((i) => i?.PINID === officerIDsArray?.[0]) || "",
        primaryOfficer2: primaryOfficerDropDown?.find((i) => i?.PINID === officerIDsArray?.[1]) || "",
        primaryOfficer3: primaryOfficerDropDown?.find((i) => i?.PINID === officerIDsArray?.[2]) || "",
        primaryOfficer4: primaryOfficerDropDown?.find((i) => i?.PINID === officerIDsArray?.[3]) || "",
        primaryOfficer5: primaryOfficerDropDown?.find((i) => i?.PINID === officerIDsArray?.[4]) || "",
        primaryOfficer6: primaryOfficerDropDown?.find((i) => i?.PINID === officerIDsArray?.[5]) || "",
        primaryOfficer7: primaryOfficerDropDown?.find((i) => i?.PINID === officerIDsArray?.[6]) || "",
        primaryOfficer8: primaryOfficerDropDown?.find((i) => i?.PINID === officerIDsArray?.[7]) || "",
        hours24Resource: data?.Is24HourResource || false,
        carResource: data?.IsCarResource || false,
        zone: zoneDropDown?.find((i) => i?.value === data?.ZoneID) || "",
        dutyStatus: data?.OnOffDuty ? "onduty" : "offduty",
        shift: shiftDropDown?.find((i) => i?.ShiftId === data?.ShiftID) || "",
      })
    }
  }, [agencyCodeDropDown, getByIdResourcesData, primaryOfficerDropDown, isFetchGetByIdResource, resourceTypeData, shiftDropDown, stationCodeDropDown, zoneDropDown])

  useEffect(() => {
    if (isFetchGeoZoneData && getZoneData) {
      const data = JSON.parse(getZoneData?.data?.data)?.Table || [];
      setZoneDropDown(dropDownDataModel(data, "ZoneID", "ZoneDescription"));
    }
  }, [isFetchGeoZoneData, getZoneData]);

  const columns = [
    {
      name: 'Resource #',
      selector: row => row?.ResourceNumber,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.ResourceNumber, rowB.ResourceNumber),
      style: {
        position: "static",
      },
    },
    {
      name: 'Resource Type',
      selector: row => resourceTypeData?.data?.data?.Table?.find((i) => i?.ResourceTypeID === row?.ResourceTypeID)?.ResourceTypeCode,
      sortable: true,
      width: "30%",
      style: {
        position: "static",
      },
    },
    {
      name: 'Station Code',
      selector: row =>
        stationCodeDropDown?.find((i) => i?.stationID === row?.StationID)?.stationCode,
      sortable: true,
      style: {
        position: "static",
      },
      style: {
        position: "static",
      },
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.IsActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive") }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },

  ];

  const validateHandel = () => {
    let isError = false;
    const keys = Object.keys(errorResourceState);
    keys.map((field) => {
      if (
        field === "agencyCode" &&
        isEmptyObject(resourceState[field])) {
        handleErrorResourceState(field, true);
        isError = true;
      } else if (field === "resource" && isEmpty(resourceState[field])) {
        handleErrorResourceState(field, true);
        isError = true;
      } else if (field === "resourceType" && isEmptyObject(resourceState[field])) {
        handleErrorResourceState(field, true);
        isError = true;
      } else if (field === "station" && isEmptyObject(resourceState[field])) {
        handleErrorResourceState(field, true);
        isError = true;
      } else if (field === "primaryOfficer" && isEmptyObject(resourceState?.primaryOfficer)) {
        handleErrorResourceState(field, true);
        isError = true;
      } else if (field === "zone" && isEmptyObject(resourceState[field])) {
        handleErrorResourceState(field, true);
        isError = true;
      } else if (field === "shift" && isEmptyObject(resourceState[field]) && resourceState?.dutyStatus === "onduty" && !resourceState?.hours24Resource) {
        handleErrorResourceState(field, true);
        isError = true;
      } else {
        handleErrorResourceState(field, false);
      }
    });
    return !isError;
  };

  function handelCancel() {
    clearStateResourceState();
    clearResourceState();
    setResourceId("")
  }

  async function handleSave() {
    if (!validateHandel()) return

    const result = listData?.find(item => {
      if (item.ResourceNumber) {
        const code = resourceState?.resource?.toLowerCase();
        return code && item.ResourceNumber.toLowerCase() === code;
      }
      return false;
    });
    if (result && !resourceState?.ResourceID) {
      if (result) {
        toastifyError('Code Already Exists');
      }
    } else {
      const officerIDs = [
        resourceState?.primaryOfficer?.PINID,
        resourceState?.primaryOfficer2?.PINID,
        resourceState?.primaryOfficer3?.PINID,
        resourceState?.primaryOfficer4?.PINID,
        resourceState?.primaryOfficer5?.PINID,
        resourceState?.primaryOfficer6?.PINID,
        resourceState?.primaryOfficer7?.PINID,
        resourceState?.primaryOfficer8?.PINID
      ].filter(Boolean).join(',');
      const commonData = {
        AgencyID: resourceState?.agencyCode?.AgencyID || "",
        ResourceNumber: resourceState?.resource,
        ResourceTypeID: resourceState?.resourceType?.ResourceTypeID || "",
        StationID: resourceState?.station?.stationID || "",
        OfficerIDs: officerIDs,
        Is24HourResource: resourceState?.hours24Resource ? 1 : 0,
        IsCarResource: resourceState?.carResource ? 1 : 0,
        ResourceZone: resourceState?.zone?.value || "",
        OnOffDuty: resourceState?.hours24Resource ? "" : resourceState?.dutyStatus === "onduty" ? 1 : 0,
        ShiftID: resourceState?.hours24Resource ? "" : resourceState?.shift?.ShiftId || "",
        ZoneID: resourceState?.zone?.value || ""
      };
      const data = resourceState?.ResourceID
        ? {
          ...commonData,
          ResourceID: resourceState?.ResourceID,
          ModifiedByUserFK: loginPinID
        }
        : {
          ...commonData,
          CreatedByUserFK: loginPinID
        };
      const response = await MasterTableListServices.insertResource(data);
      if (response?.status === 200) {
        const parsedData = JSON.parse(response?.data?.data);
        const IsAlreadyAssigned = parsedData?.Table[0]?.IsAlreadyAssigned;
        IsAlreadyAssigned ? toastifySuccess("Resource is already assigned to incident.") :
          resourceState?.ResourceID
            ? toastifySuccess("Data Updated Successfully")
            : toastifySuccess("Data Saved Successfully");
        clearResourceState();
        refetch();
      }
      handelCancel()
    }
  }

  const getFilteredOptions = (selectedKey) => {
    const selectedOfficers = [
      resourceState?.primaryOfficer,
      resourceState?.primaryOfficer2,
      resourceState?.primaryOfficer3,
      resourceState?.primaryOfficer4,
    ].filter(Boolean);

    return primaryOfficerDropDown.filter(
      (officer) => !selectedOfficers.some((selected) => selected?.PINID === officer?.PINID) || selectedKey?.PINID === officer?.PINID
    );
  };

  const handleSpecialKeyDown = (e) => {
    const isAlphanumeric = e.key.length === 1 && e.key.match(/[a-zA-Z0-9]/);
    const isBackspace = e.key === "Backspace";

    if (!isAlphanumeric && !isBackspace) {
      e.preventDefault();
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.ResourceID === resourceId,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#001f3fbd',
          color: 'white',
        },
      },
    },
  ];

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency Code{errorResourceState.agencyCode && isEmptyObject(resourceState.agencyCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Agency Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          options={agencyCodeDropDown}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          isClearable
                          value={resourceState?.agencyCode}
                          onChange={(e) => { handleResourceState("agencyCode", e) }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Agency Type
                        </label>
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input highlighter-input-readonly"
                          placeholder='Placeholder'
                          value={resourceState?.agencyType}
                          readOnly
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input highlighter-input-readonly"
                          placeholder='Auto fetch'
                          value={resourceState?.agencyType2}
                          readOnly
                        />
                      </div>
                    </div>
                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Resource Type{errorResourceState.resourceType && isEmptyObject(resourceState.resourceType) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Resource Type"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          isClearable
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          options={resourceTypeData?.data?.data?.Table}
                          value={resourceState?.resourceType}
                          getOptionLabel={(v) => v?.ResourceTypeCode + " | " + v?.description}
                          getOptionValue={(v) => v?.ResourceTypeID}
                          formatOptionLabel={(option, { context }) => {
                            return context === 'menu'
                              ? `${option?.ResourceTypeCode} | ${option?.description}`
                              : option?.ResourceTypeCode;
                          }}
                          onChange={(e) => { handleResourceState("resourceType", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Resource #{errorResourceState.resource && isEmpty(resourceState.resource) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Resource"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Resource'
                          maxLength={7}
                          onKeyDown={handleSpecialKeyDown}
                          value={resourceState?.resource}
                          onChange={(e) => { handleResourceState("resource", e.target.value) }}
                        />
                      </div>

                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Station Code{errorResourceState.station && isEmptyObject(resourceState.station) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Station"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          isClearable
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          options={stationCodeDropDown}
                          getOptionLabel={(v) => v?.stationCode + " | " + v?.description}
                          getOptionValue={(v) => v?.stationID}
                          formatOptionLabel={(option, { context }) => {
                            return context === 'menu'
                              ? `${option?.stationCode} | ${option?.description}`
                              : option?.stationCode;
                          }}
                          value={resourceState?.station}
                          onChange={(e) => { handleResourceState("station", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                    </div>
                    {/* line 3 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Primary Officer{errorResourceState.primaryOfficer && isEmptyObject(resourceState.primaryOfficer) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Primary Officers"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <Select
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          options={getFilteredOptions(resourceState?.primaryOfficer)}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName + " | " + v?.PIN}
                          getOptionValue={(v) => v?.PINID}
                          value={resourceState?.primaryOfficer}
                          isClearable
                          onChange={(e) => { handleResourceState("primaryOfficer", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={getFilteredOptions(resourceState?.primaryOfficer2)}
                          getOptionLabel={(v) => v?.FirstName + " " + v?.LastName + " | " + v?.PIN}
                          getOptionValue={(v) => v?.PINID}
                          isClearable
                          value={resourceState?.primaryOfficer2}
                          onChange={(e) => { handleResourceState("primaryOfficer2", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                    </div>
                    {/* line 5 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        {/* Empty Space */}
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-start" style={{ gap: '3.5px' }}>
                        <input type="checkbox" className='tab-form-label' checked={resourceState?.hours24Resource} onChange={(e) => { handleResourceState("hours24Resource", e.target.checked) }} /><span className='tab-form-label'>24 Hours Resource</span>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-start" style={{ gap: '3.5px' }}>
                        <input type="checkbox" className='tab-form-label' checked={resourceState?.carResource} onChange={(e) => { handleResourceState("carResource", e.target.checked) }} /><span className='tab-form-label'>Car Resource</span>
                      </div>
                    </div>
                    {/* line 6 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Zone Code{errorResourceState.zone && isEmptyObject(resourceState.zone) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Zone"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          styles={coloredStyle_Select}
                          placeholder="Select"
                          options={zoneDropDown}
                          isClearable
                          value={resourceState?.zone}
                          onChange={(e) => { handleResourceState("zone", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                    </div>

                    <FormTitleSection title="On / Off Duty Status" />

                    {/* line 7 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                      </div>
                      <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                        <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                          <input type="radio" id="offduty" value="offduty" checked={resourceState?.dutyStatus === 'offduty'} disabled={resourceState?.hours24Resource} onChange={(e) => { handleResourceState("dutyStatus", e.target.value); handleResourceState("shift", "") }} />
                          <label for="offduty" className='tab-form-label' style={{ margin: '0', }}>Off Duty</label>
                        </div>
                      </div>
                      <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                        <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                          <input type="radio" id="onduty" value="onduty" checked={resourceState?.dutyStatus === 'onduty'} disabled={resourceState?.hours24Resource} onChange={(e) => {
                            handleResourceState("dutyStatus", e.target.value)
                          }} />
                          <label for="onduty" className='tab-form-label' style={{ margin: '0', }}>On Duty</label>
                        </div>
                      </div>
                      <div className="d-flex align-self-center justify-content-end" style={{ width: '50px' }}>
                        <label for="" className="tab-form-label text-nowrap">
                          Shift Code{errorResourceState.shift && isEmptyObject(resourceState.shift) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Shift Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <Select
                          isClearable
                          styles={resourceState?.dutyStatus === "offduty" || resourceState?.hours24Resource ? colorLessStyle_Select : coloredStyle_Select}
                          placeholder="Select"
                          getOptionLabel={(v) => v?.ShiftCode + " | " + v?.ShiftDescription}
                          getOptionValue={(v) => v?.ShiftId}
                          formatOptionLabel={(option, { context }) => {
                            return context === 'menu'
                              ? `${option?.ShiftCode} | ${option?.ShiftDescription}`
                              : option?.ShiftCode;
                          }}
                          options={shiftDropDown}
                          isDisabled={resourceState?.dutyStatus === "offduty" || resourceState?.hours24Resource}
                          value={resourceState?.shift}
                          onChange={(e) => { handleResourceState("shift", e) }}
                          onInputChange={(inputValue, actionMeta) => {
                            if (inputValue.length > 12) {
                              return inputValue.slice(0, 12);
                            }
                            return inputValue;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder='Search By Resource...'
                value={searchValue1}
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = SearchFilter(listData, e.target.value, searchValue2, filterTypeIdOption, 'ResourceNumber', 'ResourceTypeCode')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end">
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder='Search By Resource Type...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'ResourceNumber', 'ResourceTypeCode')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end">
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {isSuccess &&
            <div className="table-responsive">
              <DataTable
                dense
                columns={columns}
                data={filterListData}
                customStyles={tableCustomStyles}
                conditionalRowStyles={conditionalRowStyles}
                pagination
                responsive
                noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
                striped
                fixedHeaderScrollHeight="360px"
                highlightOnHover
                fixedHeader
                onRowClicked={(row) => {
                  setResourceId(row?.ResourceID);
                }}
              />
            </div>}

          {parseInt(pageStatus) === 1 && <div className="utilities-tab-content-button-container" >
            <button type="button" onClick={() => handelCancel()} className="btn btn-sm btn-success">New</button>
            <button type="button" onClick={() => { handleSave() }} className="btn btn-sm btn-success">{!!resourceState?.ResourceID ? "Update" : "Save"}</button>
          </div>}
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  );
};

export default ResourceSection;