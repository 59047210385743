import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AgencyContext } from './Context/Agency/Index';
import Header from './CADComponents/Header';

const CADAuth = (props) => {

  const { cmp, listManagementSideBar, agencySideBar, personnelSideBar, progressData, progressStatus, hideHeader, isCAD = false } = props
  const Com = cmp;
  const [isIncidentDispatch, setIsIncidentDispatch] = useState(false)
  const navigate = useNavigate()

  const { setAuthSession, setLogByOtp, setIsLogout } = useContext(AgencyContext);

  const IsLoginSession = sessionStorage.getItem('is-login') ? sessionStorage.getItem('is-login') : false;

  const currentLocation = window.location.pathname + window.location.search + window.location.hash;


  useEffect(() => {
    if (IsLoginSession && window.location.href === window.location.origin + "/dashboard-page") {
      preventBack();
    }
  }, [window.location.pathname]);

  function preventBack() {
    window.onpopstate = function (event) {
      if (event.state && event.state.url === currentLocation || currentLocation === window.location.pathname) {
        window.history.go(1);
      }
    };
    window.history.pushState(null, null, currentLocation);
  }

  useEffect(() => {
    if (IsLoginSession === 'true' || IsLoginSession === true) {

    } else {
      setIsLogout(false);
      setLogByOtp(false)
      setAuthSession('');
      navigate('/');
    }
  }, [])

  useEffect(() => {
    if (IsLoginSession === 'true' || IsLoginSession === true) {
    } else {
      setIsLogout(false);
      setLogByOtp(false)
      setAuthSession('');
      navigate('/');
    }
  }, [IsLoginSession])

  const timerRef = useRef(null);
  const timeout = 1200000;

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(logout, timeout);
  };

  const logout = () => {
    window.location.href = '/'
    localStorage.clear()
  };

  useEffect(() => {
    resetTimer();
    const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart', 'load', 'click', 'resize'];
    const handleActivity = () => {
      resetTimer();
    };

    events.forEach(event => {
      window.addEventListener(event, handleActivity);
    });

    return () => {
      clearTimeout(timerRef.current);
      events.forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);

  return (
    <>
      {/* Sidebar */}
      {
        !IsLoginSession ? navigate('/')
          : <>
            <div className="cad-css">
              {!hideHeader && <Header {...{ listManagementSideBar, agencySideBar, personnelSideBar, isIncidentDispatch, setIsIncidentDispatch }} />}

              <Com {...{ progressData, progressStatus, isIncidentDispatch, isCAD }} />
            </div>
          </>
      }
    </>
  )
}

export default CADAuth
