import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from 'react-redux';
import Select from "react-select";
import CallTakerServices from "../../CADServices/APIs/callTaker";
import {
  dropDownDataModel,
  handleNumberTextKeyDown,
  handleTextKeyDown,
  isEmpty,
  isEmptyObject,
} from "../../CADUtils/functions/common";
import GeoServices from "../../CADServices/APIs/geo";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import Location from "../Common/Location";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import NameSearchModal from "../NameSearch/NameSearchModal";
import VehicleSearchModal from "../VehicleSearch/VehicleSearchModal";
import ContactInfoModal from "../ContactInfoModal";
import LocationInformationModal from "../LocationInformationModal";
import useObjState from "../../CADHook/useObjState";
import ModalConfirm from "../Common/ModalConfirm";
import { IncidentContext } from "../../CADContext/Incident";
import { useDispatch } from "react-redux";
import { get_PlateType_Drp_Data, get_State_Drp_Data } from "../../redux/actions/DropDownsData";
import CloseCallModal from "../CloseCallModal";
import FlagTableModal from "../FlagTableModal";
import FlagModal from "../FlagMaster/FlagModal";

const CallTakerModal = (props) => {
  const { openCallTakerModal, setCallTakerModal, isIncidentDispatch, setIsIncidentDispatch, setIncNo = () => { },
  } = props;
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBBfoUsiF8ZX5iopPppB9x5XRriRaALhiY",
  });
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const { incidentRefetch, resourceRefetch, resourceData } = useContext(IncidentContext);
  //#region //! useState
  const stateList = useSelector((state) => state.DropDown.stateDrpData);
  const plateTypeIdDrp = useSelector((state) => state.DropDown.vehiclePlateIdDrpData)

  const [loginAgencyID, setLoginAgencyID] = useState("");
  const [loginPinID, setLoginPinID] = useState(1);
  const [isGoogleLocation, setIsGoogleLocation] = useState(true)
  const [receiveSourceDropDown, setReceiveSourceDropDown] = useState([]);
  const [prioritiesDropDown, setPrioritiesDropDown] = useState([]);
  const [reasonCodeDropDown, setReasonCodeDropDown] = useState([]);
  const [vehiclePlateTypeDropDown, setVehiclePlateTypeDropDown] = useState([]);
  const [resourceDropDown, setResourceDropDown] = useState([]);
  const [tagYearDropDown, setTagYearDropDown] = useState([]);
  const [stateDropDown, setStateDropDown] = useState([]);
  const [premiseDropDown, setPremiseDropDown] = useState([]);
  const [cfsDropDown, setCFSDropDown] = useState([]);
  const [cfsDescDropDown, setCFSDescDropDown] = useState([]);
  const [flagDropDown, setFlagDropDown] = useState([]);
  const [locationStatus, setLocationStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [geoLocationID, setGeoLocationID] = useState();
  const [onSelectLocation, setOnSelectLocation] = useState(true);
  const [geoZoneDropDown, setGeoZoneDropDown] = useState([]);
  const [emsZoneDropDown, setEmsZoneDropDown] = useState([]);
  const [fireZoneDropDown, setFireZoneDropDown] = useState([]);
  const [otherZoneDropDown, setOtherZoneDropDown] = useState([]);
  const [locationData, setLocationData] = useState();
  const [nameId, setNameId] = useState("");
  const [vehicleId, setVehicleID] = useState("");
  const [incidentId, setIncidentId] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [activeIndexLocation, setActiveIndexLocation] = useState([[], []]);
  const [openContactInfoModal, setOpenContactInfoModal] = useState(false);
  const [openLocationInformationModal, setOpenLocationInformationModal] = useState(false);
  const [openCloseCallModal, setOpenCloseCallModal] = useState(false);
  const [isSelectLocation, setIsSelectLocation] = useState(false);
  const [CFSID, setCFSID] = useState(null);
  const [selectedButton, setSelectedButton] = useState([]);
  const [zoom, setZoom] = useState(17);
  const [isOpenSearchNameModel, setIsOpenSearchNameModel] = useState(false);
  const [isOpenVehicleSearchModel, setIsOpenVehicleSearchModel] = useState(false);
  const [nameData, setNameData] = useState([]);
  const [isChangeFields, setIsChangeFields] = useState(false);
  const [openFlagTableModal, setOpenFlagTableModal] = useState(false);
  const [openAddFlagModal, setOpenAddFlagModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(false);

  const [flagBudgeList, setFlagBudgeList] = useState([]);
  const [flagName, setFlagName] = useState("");
  const [selectedFlagData, setSelectedFlagData] = useState({});
  const handleMapLoad = (map) => {
    mapRef.current = map;
    setTimeout(() => {
      window.google.maps.event.trigger(map, "resize");
    }, 200);

  };

  const [nameCallTaker, setNameCallTaker, handleNameCallTaker, clearState] =
    useObjState({
      FirstName: "",
      LastName: "",
      NameReasonCodeID: [],
    });

  const [
    errorCallTaker,
    _setErrorCallTaker,
    handleErrorCallTaker,
    clearStateCallTaker,
  ] = useObjState({
    location: false,
    ReceiveSourceID: false,
    CallforServiceID: false,
    CFSLPriority: false,
    patrolZone: false,
    emsZone: false,
    fireZone: false,
    otherZone: false,
    Resource1: false,
  });

  const [
    errorNameCallTaker,
    _setErrorNameCallTaker,
    handleErrorNameCallTaker,
    clearStateNameCallTaker,
  ] = useObjState({
    NameReasonCodeID: false,
  });


  const initialFormValues = {
    PremiseNo: "",
    stDirection: "",
    Street: "",
    stDirection2: "",
    ApartmentNo: "",
    commonPlaceName: "",
    premiseType: null,
    City: "",
    ZipCode: "",
    mileMarker: "",
    coordinateX: "",
    coordinateY: "",
    AltStreet: "",
    intersection1: "",
    intersection2: "",
    verify: true,
    patrolZone: null,
    emsZone: null,
    fireZone: null,
    otherZone: null,
    currentFlag: null,
    location: "",
    IsVerify: true,
    isStreet: false,
    isCity: false,
    isPremiseNo: false,
    isZipCode: false,
    isMileMarker: false,
    isCommonPlaceName: false,
    isStDirection: false,
    isStDirection2: false,
    isIntersection1: false,
    isIntersection2: false,
    isAltStreet: false,
    isApartmentNo: false,
    isCoordinateX: false,
    isCoordinateY: false,
  };

  const [geoFormValues, setGEOFormValues] = useState(initialFormValues);
  const [isDispatchLoading, setIsDispatchLoading] = useState(false)
  const [isRouteLoading, setIsRouteLoading] = useState(false)

  const initialValueContact = {
    LastName: "",
    MiddleName: "",
    FirstName: "",
    PhoneType: {},
    PhoneNumber: "",
  };
  const [contactInformation, setContactInformation] =
    useState(initialValueContact);


  const incidentInitialValues = {
    location: "",
    ApartmentNo: "",
    ReceiveSourceID: "",
    CallforServiceID: "",
    CFSLDetails: "",
    CFSLPriority: "",
    CFSL: 1,
    CFSF: 0,
    CFSE: 0,
    OTHER: 0,
    Comments: "",
    Resource1: "",
    Resource2: "",
    Resource3: "",
    Resource4: "",
    Resource5: "",
    Resource6: "",
    Resource7: "",
    Resource8: "",
  };

  const [incidentFormValues, setIncidentFormValues] = useState(
    incidentInitialValues
  );

  const [vehicleData, setVehicleData] = useState([]);
  const [
    vehicleCallTaker,
    setVehicleCallTaker,
    handleVehicleCallTaker,
    clearVehicleState,
  ] = useObjState({
    Plate: "",
    StateCode: null,
    PlateTypeCode: null,
    TagYear: null,
  });

  const isVehicleCallTakerData = Object.values(vehicleCallTaker).some(
    (value) => value !== "" && value !== null && value !== undefined
  );

  const isNameCallTakerData = Object.values(nameCallTaker).some(
    (value) =>
      value !== "" &&
      value !== null &&
      value !== undefined &&
      !(Array.isArray(value) && value.length === 0)
  );

  const locations = [
    {
      lng: parseFloat(geoFormValues?.coordinateX) || -98.5795,
      lat: parseFloat(geoFormValues?.coordinateY) || 39.8283,
      status: "open",
      name: geoFormValues?.location,
    },
  ];

  const mapCenter = {
    lng: parseFloat(geoFormValues?.coordinateX) || -98.5795,
    lat: parseFloat(geoFormValues?.coordinateY) || 39.8283,
  };

  //#region //! API

  const receiveSourceKey = `/CAD/CallTakerReceiveSource/GetData_ReceiveSource`;
  const { data: receiveSourceData, isSuccess: isFetchReceiveSourceData } =
    useQuery(
      [receiveSourceKey, { Action: "GetData_ReceiveSource" }],
      CallTakerServices.getReceiveSource,
      {
        refetchOnWindowFocus: false,
        enabled: !!loginAgencyID && openCallTakerModal,
      }
    );
  const CFSCodeKey = `/CAD/MasterCallforServiceCode/InsertCallforServiceCode`;
  const { data: cfsData, isSuccess: isFetchCFSData } = useQuery(
    [
      CFSCodeKey,
      {
        Action: "GET",
        IsActive: 1
      }
    ],
    MasterTableListServices.getCFS,
    {
      refetchOnWindowFocus: false,
      enabled: openCallTakerModal
    }
  );
  const getNameReasonCodeKey = `/CAD/DispatcherNameReasonCode/GetDataDropDown_NameReasonCode`;
  const payloadReasonCode = { CategoryID: 1 };
  const { data: nameReasonCodeData, isSuccess: isFetchNameReasonCodeData } =
    useQuery(
      [getNameReasonCodeKey, { payloadReasonCode }],
      CallTakerServices.getNameReasonCode,
      {
        refetchOnWindowFocus: false,
        enabled: openCallTakerModal
      }
    );

  const vehiclePlateTypePayload = {
    PINID: 0,
    IsActive: 1,
    IsSuperAdmin: 1,
    AgencyID: loginAgencyID,
  };

  const vehiclePlateTypeKey = `/CAD/CallTakerVehiclePlateType/GetData_PropertyVehiclePlateType`;
  const { data: vehiclePlateTypeData, isSuccess: isFetchVehiclePlateTypeData } =
    useQuery(
      [
        vehiclePlateTypeKey,
        {
          vehiclePlateTypePayload,
        },
      ],
      CallTakerServices.getPropertyVehiclePlateType,
      {
        refetchOnWindowFocus: false,
        enabled: !!loginAgencyID && openCallTakerModal
      }
    );

  const tagYearKey = `/CAD/CallTakerVehiclePlateType/GetData_TagYear`;
  const { data: tagYearData, isSuccess: isFetchTagYearData } = useQuery(
    [tagYearKey],
    CallTakerServices.getTagYear,
    {
      refetchOnWindowFocus: false,
      enabled: openCallTakerModal
    }
  );

  const stateKey = `/CAD/CallTakerState/GetData_State`;
  const { data: stateData, isSuccess: isFetchStateData } = useQuery(
    [stateKey],
    CallTakerServices.getState,
    {
      refetchOnWindowFocus: false,
      enabled: openCallTakerModal
    }
  );

  const getPriorityKey = `/CAD/MasterPriority/GetMasterPriority`;
  const { data: priorityData, isSuccess: isFetchPriorityData, refetch: priorityDataRefetch, isError: isNoData } = useQuery(
    [getPriorityKey, {
      IsActive: 1,
      AgencyID: loginAgencyID,
    }],
    MasterTableListServices.getMasterPriority,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!loginAgencyID && openCallTakerModal,
    }
  );

  useEffect(() => {
    if (isFetchPriorityData && priorityData) {
      const data = JSON.parse(priorityData?.data?.data);
      setPrioritiesDropDown(data?.Table);
      // setPrioritiesDropDown(
      //   dropDownDataModel(data?.Table, "PriorityID", "PriorityCode")
      // );
    }
  }, [isFetchPriorityData, priorityData]);

  const getPremiseKey = `/CAD/GeoPremiseType/GetData_Premise`;
  const { data: premiseData, isSuccess: isFetchPremiseData } = useQuery(
    [getPremiseKey, {}],
    GeoServices.getPremise,
    {
      refetchOnWindowFocus: false,
      enabled: openCallTakerModal
    }
  );

  const getFlagKey = `/CAD/GeoFlage/GetData_Flag`;
  const { data: flagData, isSuccess: isFetchFlagData } = useQuery(
    [getFlagKey, {}],
    GeoServices.getFlag,
    {
      refetchOnWindowFocus: false,
      enabled: openCallTakerModal
    }
  );

  const payload = { GeoLocationID: geoLocationID };
  const getContactKey = `/CAD/GeoLocationContactDetail/GetData_GeoContact/${geoLocationID}`;
  const {
    data: contactData,
    isSuccess: isFetchContactData,
  } = useQuery([getContactKey, { payload }], GeoServices.getContactData, {
    refetchOnWindowFocus: false,
    enabled: !!geoLocationID,
  });

  const geoZoneKey = `/CAD/GeoPetrolZone/GetData_Zone`;
  const { data: geoZoneData, isSuccess: isFetchGeoZoneData } = useQuery(
    [geoZoneKey, { IsActive: 1 }],
    GeoServices.getGeoZone,
    {
      refetchOnWindowFocus: false,
    }
  );
  const flagPayload = {
    FlagFromId: geoLocationID,
    FlagFrom: "GEO",
    AgencyID: loginAgencyID,
    Action: "Get_All_On_CallTaker"
  }

  const getFlagListKey = `/CAD/Flag/GetFlag`;
  const { data: getFlagList, refetch: getFlagListRefetch, isSuccess: isFetchGetFlagList } = useQuery(
    [getFlagListKey, { flagPayload }],
    CallTakerServices.getFlag,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: openCallTakerModal && !!geoLocationID,
    }
  );

  useEffect(() => {
    if (geoFormValues?.location && isSelectLocation && isFetchGetFlagList && getFlagList) {
      const data = JSON.parse(getFlagList?.data?.data) || [];
      setFlagBudgeList(data);
    } else {
      setFlagBudgeList([]);
      setSelectedFlagData({})
    }
  }, [isFetchGetFlagList, getFlagList, geoLocationID]);

  //#endregion

  useEffect(() => {
    if (openCallTakerModal && isLoaded) {
      setTimeout(() => {
        setIsMapVisible(true);
        if (mapRef.current) {
          window.google.maps.event.trigger(mapRef.current, "resize");
        }
      }, 300);
    } else {
      setIsMapVisible(false);
    }
  }, [openCallTakerModal, isLoaded]);

  useEffect(() => {
    if (isFetchGeoZoneData && geoZoneData) {
      const data = JSON.parse(geoZoneData?.data?.data)?.Table || [];
      setGeoZoneDropDown(
        dropDownDataModel(data, "ZoneID", "ZoneDescription")
      );
    }
  }, [isFetchGeoZoneData, geoZoneData]);

  useEffect(() => {
    if (loginAgencyID) {
      if (stateList?.length === 0) dispatch(get_State_Drp_Data())
      if (plateTypeIdDrp?.length === 0) dispatch(get_PlateType_Drp_Data())
    }
  }, [loginAgencyID])

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
      setLoginAgencyID(localStoreData?.AgencyID);
    }
  }, [localStoreData]);

  useEffect(() => {
    if (isFetchReceiveSourceData && receiveSourceData) {
      const data = JSON.parse(receiveSourceData?.data?.data);
      setReceiveSourceDropDown(data?.Table || []);
    }
  }, [isFetchReceiveSourceData, receiveSourceData]);

  useEffect(() => {
    if (isFetchNameReasonCodeData && nameReasonCodeData) {
      const data = JSON.parse(nameReasonCodeData?.data?.data);
      setReasonCodeDropDown(
        dropDownDataModel(data?.Table, "NameReasonCodeID", "Description")
      );
    }
  }, [isFetchNameReasonCodeData, nameReasonCodeData]);

  useEffect(() => {
    if (isFetchCFSData && cfsData) {
      const data = JSON.parse(cfsData?.data?.data)?.Table;
      const dropDownDataModel1 = (data, value, label) => {
        const result = data?.map((item) => ({
          value: item[value],
          label: `${item[value]} | ${item[label]}`,
          name: item[label],
        }));
        return result;
      };
      setCFSDropDown(data);
      setCFSDescDropDown(data);
    }
  }, [isFetchCFSData, cfsData]);

  useEffect(() => {
    if (isFetchVehiclePlateTypeData && vehiclePlateTypeData) {
      const data = JSON.parse(vehiclePlateTypeData?.data?.data);
      setVehiclePlateTypeDropDown(
        dropDownDataModel(data?.Table, "PlateTypeCode", "Description")
      );
    }
  }, [isFetchVehiclePlateTypeData, vehiclePlateTypeData]);

  useEffect(() => {
    if (resourceData?.length > 0) {
      const avStatusData = resourceData.filter(item => item.Status === "AV");
      setResourceDropDown(avStatusData);
    }
  }, [openCallTakerModal, resourceData]);

  useEffect(() => {
    if (isFetchPremiseData && premiseData) {
      const data = JSON.parse(premiseData?.data?.data);
      setPremiseDropDown(dropDownDataModel(data?.Table, "ID", "PremiseType"));
    }
  }, [isFetchPremiseData, premiseData]);

  useEffect(() => {
    if (isFetchTagYearData && tagYearData) {
      const data = JSON.parse(tagYearData?.data?.data);
      setTagYearDropDown(dropDownDataModel(data?.Table, "TagYear", "TagYear"));
    }
  }, [isFetchTagYearData, tagYearData]);

  useEffect(() => {
    if (isFetchStateData && stateData) {
      const data = JSON.parse(stateData?.data?.data);
      setStateDropDown(dropDownDataModel(data?.Table, "StateID", "StateName"));
    }
  }, [isFetchStateData, stateData]);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await GeoServices.getLocationData({
          Location: geoFormValues?.location,
        });
        const data = JSON.parse(response?.data?.data)?.Table || [];
        setLocationData(data);

      } catch (error) {
        console.error("Error fetching location data:", error);
        setLocationData([]);
      }
    };

    if (geoFormValues?.location) {
      fetchLocationData();
    }
  }, [geoFormValues?.location, isSelectLocation]);

  useEffect(() => {
    if (isFetchFlagData && flagData) {
      const data = JSON.parse(flagData?.data?.data);
      setFlagDropDown(dropDownDataModel(data?.Table, "ID", "CurrentFlag"));
    }
  }, [isFetchFlagData, flagData]);

  useEffect(() => {
    if (isFetchContactData && contactData) {
      if (contactData?.data?.Data?.length === 0) {
        return;
      } else {
        try {
          const parsedData = JSON.parse(contactData?.data?.data);
          const contactInfo = parsedData?.Table;

          contactInfo.forEach((item) => {
            if (typeof item.PhoneNo === "string") {
              try {
                item.PhoneNo = JSON.parse(item.PhoneNo);
              } catch (error) {
                console.error("Error parsing PhoneNo:", error);
              }
            }
          });

          setContactList(contactInfo);
        } catch (error) {
          console.error("Error parsing contact data:", error);
        }
      }
    }
  }, [isFetchContactData, contactData]);

  const validateForm = () => {
    let isError = false;
    const keys = Object.keys(errorNameCallTaker);
    keys.map((field) => {
      if (
        field === "NameReasonCodeID" && (!isEmpty(nameCallTaker?.FirstName) || !isEmpty(nameCallTaker?.LastName)) &&
        (nameCallTaker[field]?.length === 0)) {
        handleErrorNameCallTaker(field, true);
        isError = true;
      } else {
        handleErrorNameCallTaker(field, false);
      }
    });
    return !isError;
  };

  function handleClear() {
    setGeoLocationID();
    clearStateCallTaker();
    clearStateNameCallTaker();
    setGEOFormValues(initialFormValues);
    setIsSelectLocation(false);
    setIsDispatchLoading(false);
    setIsRouteLoading(false)
    clearState();
    setIsChangeFields(false);
  }

  const onCloseLocation = () => {
    setCallTakerModal(false);
    setIsDispatchLoading(false);
    setIsRouteLoading(false)
    clearVehicleState();
    setGeoLocationID("");
    clearStateCallTaker();
    clearStateNameCallTaker();
    setContactList([]);
    setContactInformation(initialValueContact);
    handleClear();
    setVehicleID("");
    clearState();
    setNameId("");
    setIncidentId([]);
    setIncidentFormValues(incidentInitialValues);
    setGEOFormValues(initialFormValues);
    setActiveIndexLocation([[], []]);
    setIsSelectLocation(false);
    setIsChangeFields(false);
  };

  const handleInputChangeIncident = (e) => {
    const { name, value } = e.target;
    setIncidentFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setIsChangeFields(true);
  };

  const handleSelectChangeIncident = (selectedOption, { name }) => {
    // if (name === "Resource1" && selectedOption?.length > 8) {
    //   return;
    // }
    setIncidentFormValues((prevState) => ({
      ...prevState,
      [name]: selectedOption,
    }));
    setIsChangeFields(true);
  };

  const handleCheckboxChangeIncident = (key) => (e) => {
    setIncidentFormValues((prevValues) => ({
      ...prevValues,
      [key]: e.target.checked ? 1 : 0,
    }));
  };

  const handleSelectChangeIncidentCFS = (selectedOption, { name }) => {
    setIsChangeFields(true);

    const updatedValues = { ...incidentFormValues };

    if (selectedOption === null) {
      if (name === "CallforServiceID") {
        updatedValues.CallforServiceID = null;
        updatedValues.CFSLDetails = null;
      }

      if (name === "CFSLDetails") {
        updatedValues.CFSLDetails = null;
        updatedValues.CallforServiceID = null;
      }

      setIncidentFormValues(() => ({
        ...updatedValues,
        CFSL: 0,
        CFSF: 0,
        CFSE: 0,
        OTHER: 0,
        CFSLPriority: ""
      }));
      return;
    }

    setCFSID(selectedOption?.CallforServiceID);
    const selectedData = JSON.parse(cfsData?.data?.data)?.Table.find(
      (item) => item?.CallforServiceID === selectedOption?.CallforServiceID
    );

    if (name === "CallforServiceID") {
      updatedValues.CallforServiceID = selectedOption;
      const selectedDescription = cfsDescDropDown.find(
        (item) => item?.CallforServiceID === selectedOption?.CallforServiceID
      );
      updatedValues.CFSLDetails = selectedDescription;
    }

    if (name === "CFSLDetails") {
      updatedValues.CFSLDetails = selectedOption;
      const selectedDescription = cfsDropDown.find(
        (item) => item?.CallforServiceID === selectedOption?.CallforServiceID
      );
      updatedValues.CallforServiceID = selectedDescription;
    }

    if (selectedData) {
      setIncidentFormValues(() => ({
        ...updatedValues,
        CFSL: 1,
        CFSF: selectedData?.FIRE ? 1 : 0,
        CFSE: selectedData?.EMERGENCYMEDICALSERVICE ? 1 : 0,
        OTHER: selectedData?.OTHER ? 1 : 0,
        // CFSLPriority: prioritiesDropDown?.find(item => item.PriorityID === parseInt(selectedOption?.Prioritycode))
        CFSLPriority: prioritiesDropDown?.find(item => item.PriorityID === parseInt(selectedOption?.PriorityID))
      }));
    }
  };


  const handleSelectIncidentName = (selectedOption, { name }) => {
    const data = selectedOption.map(item => item?.value)
    handleNameCallTaker("NameReasonCodeID", data)
    setIsChangeFields(true);
  };

  const handleSelectIncidentVehicle = (selectedOption, { name }) => {
    handleVehicleCallTaker(name, selectedOption?.value)
    setIsChangeFields(true);
  };

  //#region  handler
  //
  const getColorByStatus = (status) => {
    switch (status) {
      case "open":
        return "green";
      case "closed":
        return "red";
      case "pending":
        return "yellow";
      default:
        return "blue";
    }
  };

  const validateGeoFormValues = (isRoute) => {
    let isError = false;
    const keys = Object.keys(errorCallTaker);
    keys.map((field) => {
      if (
        field === "location" &&
        isEmpty(geoFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "ReceiveSourceID" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "CallforServiceID" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      }
      else if (!isRoute && field === "Resource1" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      }
      else if (field === "CFSLPriority" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else {
        handleErrorCallTaker(field, false);
      }
    });
    return !isError;
  };

  const customStylesWithOutColorArrow = {
    control: base => ({
      ...base,
      // height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? null : "rotate(180deg)"
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  const colourStylesEC = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      minHeight: 35,
      height: 20,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  const addName = async () => {
    if (!validateForm()) return;

    setNameData((prevData) => [...prevData, nameCallTaker]);
    // const response = await CallTakerServices.updateName(nameCallTaker);
    clearState();
  };

  const addVehicle = () => {
    setVehicleData((prevData) => [...prevData, vehicleCallTaker]);
    clearVehicleState();
  };
  //#endregion

  const getResourceValues = () => {
    return incidentFormValues?.Resource1 ? incidentFormValues?.Resource1?.map(item => item.ResourceID).join(',') : "";
  };

  const createPayload = (resourceValues, GeoLocationID) => {
    const transformedData = nameData?.length > 0 ? nameData.map(item => ({
      "LastName": item.LastName,
      "FirstName": item.FirstName,
      "ReasonCode": item.NameReasonCodeID.join(",")
    })) : nameCallTaker.LastName || nameCallTaker.FirstName ? [{
      "LastName": nameCallTaker.LastName,
      "FirstName": nameCallTaker.FirstName,
      "ReasonCode": nameCallTaker.NameReasonCodeID.join(",")
    }] : "";
    const transformedVehicleData = vehicleData?.length > 0 ? vehicleData.map(item => ({
      "VehicleNo": item.Plate,
      "PlateTypeID": item.PlateTypeCode,
      "PlateID": item.StateCode,
      "ManufactureYear": item.TagYear
    })) : vehicleCallTaker.Plate || vehicleCallTaker.PlateTypeCode || vehicleCallTaker.StateCode || vehicleCallTaker.TagYear ? [{
      "VehicleNo": vehicleCallTaker.Plate,
      "PlateTypeID": vehicleCallTaker.PlateTypeCode,
      "PlateID": vehicleCallTaker.StateCode,
      "ManufactureYear": vehicleCallTaker.TagYear
    }] : "";

    const payload = {
      AgencyID: loginAgencyID || "",
      CADCFSCodeID: incidentFormValues?.CallforServiceID?.CallforServiceID || 0,
      LocationID: GeoLocationID ? GeoLocationID : geoFormValues?.Id ? geoFormValues?.Id : "",
      LocationDate: new Date().toISOString(), // Replace with actual location date if available
      DirectionPrefix: geoFormValues?.stDirection || null,
      Street: geoFormValues?.Street || "",
      DirectionSufix: geoFormValues?.stDirection2 || "",
      City: geoFormValues?.City || "",
      ZipCode: geoFormValues?.ZipCode || "",
      PremiseNo: geoFormValues?.PremiseNo || "",
      ApartmentNo: incidentFormValues?.ApartmentNo || "",
      CommonPlace: geoFormValues?.commonPlaceName || "",
      PremiseType: geoFormValues?.premiseType?.value || "",
      MileMaker: geoFormValues?.mileMarker || "",
      AltStreet: geoFormValues?.AltStreet || "",
      InterDirectionPrefix: geoFormValues?.intersection1 || "",
      InterDirectionSufix: geoFormValues?.intersection2 || "",
      CreatedByUserFK: loginPinID || "",
      CFSL: incidentFormValues?.CFSL || 0,
      CFSDetails: incidentFormValues?.CFSLDetails?.CallforServiceID || 0,
      CFSF: incidentFormValues?.CFSF || 0,
      Comments: incidentFormValues?.Comments || "",
      CFSE: incidentFormValues?.CFSE || 0,
      OTHER: incidentFormValues?.OTHER || 0,
      ResourceIDs: resourceValues || "",
      PriorityID: incidentFormValues?.CFSLPriority?.PriorityID || "",
      Location: geoFormValues?.location || "",
      ReceiveSourceID: incidentFormValues?.ReceiveSourceID?.ReceiveSourceID || 0,
      Latitude: geoFormValues?.coordinateY || "",
      Longitude: geoFormValues?.coordinateX || "",
      ChildNameJson: transformedData?.length > 0 ? JSON.stringify(transformedData) : "",
      ChildVehicleJson: transformedVehicleData?.length > 0 ? JSON.stringify(transformedVehicleData) : ""
    };
    return payload;
  };


  const createLocationPayload = () => {
    const {
      Street = "", stDirection = "", stDirection2 = "", City = "",
      ZipCode = "", PremiseNo = "", ApartmentNo = "", commonPlaceName = "",
      premiseType = {}, coordinateX = "", coordinateY = "", mileMarker = "",
      AltStreet = "", intersection1 = "", intersection2 = "", patrolZone = {},
      emsZone = {}, fireZone = {}, otherZone = {}, IsVerify = "", location = "",
      currentFlag = []
    } = geoFormValues || {};

    return {
      ...(geoLocationID && { ID: geoLocationID }),
      Street, "DirectionPrefix": stDirection, "DirectionSufix": stDirection2,
      City, ZipCode, PremiseNo, ApartmentNo, "CommonPlace": commonPlaceName,
      "PremiseType": premiseType?.label || "", "Latitude": coordinateX, "Longitude": coordinateY,
      "MileMaker": mileMarker, AltStreet, "InterDirectionPrefix": intersection1,
      "InterDirectionSufix": intersection2, "PatrolZone": patrolZone?.label, "EMSZone": emsZone?.label,
      "FireZone": fireZone?.label, "OtherZone": otherZone?.label, IsVerified: IsVerify,
      location, "CurrentFlage": currentFlag?.map(item => item?.label).join(", "),
      "GeoLocationContactsJson": JSON.stringify({ Contacts: contactList || [] }),
      "CreatedByUserFK": loginPinID
    };
  };

  const updateSelectedButton = () => {
    setSelectedButton(prevSelected =>
      prevSelected.includes(2)
        ? prevSelected.filter(item => item !== 2)
        : [...prevSelected, 2]
    );
    onCloseLocation()
  };

  const insertIncident = async (formData) => {
    try {
      const response = await CallTakerServices.insertIncident(formData);
      const data = JSON.parse(response?.data?.data)
      setIncNo(data?.Table1?.[0]?.IncidentID)
      if (data?.Table?.length > 0) {
        setIsIncidentDispatch(!isIncidentDispatch);
        onCloseLocation();
        updateSelectedButton();

      } else {
        console.error("Failed to insert incident data");
      }
    } catch (error) {
      console.error("Failed to insert incident data", error);
    }
  };

  const handleOnSave = async (isRoute) => {
    if (!validateForm()) return;
    if (!validateGeoFormValues(isRoute)) return;
    setIsDispatchLoading(!isRoute);
    setIsRouteLoading(isRoute);
    const resourceValues = getResourceValues();
    let formData;
    if (isGoogleLocation && geoLocationID) {
      formData = createPayload(resourceValues, geoLocationID)
    }
    else {
      formData = createPayload(resourceValues)
    }

    if (isGoogleLocation && !geoLocationID) {
      const locationPayload = createLocationPayload();
      const response = await GeoServices.insertLocation(locationPayload);
      if (response?.data?.success) {
        if (!geoLocationID) {
          const data = JSON.parse(response?.data?.data);
          const newGeoLocationID = data?.Table[0]?.GeoLocationID;
          setGeoLocationID(newGeoLocationID);
          formData = createPayload(resourceValues, newGeoLocationID);
        }
        setIsChangeFields(false);
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await insertIncident(formData);
    } else {
      await insertIncident(formData);
    }
    incidentRefetch();
    resourceRefetch();
    setNameData([]);
    setVehicleData([]);
    onCloseLocation();
    setIsDispatchLoading(false);
    setIsRouteLoading(false)
  };

  const onNameSearch = async () => {
    setIsOpenSearchNameModel(true)
  }
  function handleCloseConfirm() {
    setShowConfirmModal(false);
    onCloseLocation();
    setIsChangeFields(false);
    setNameData([]);
    setVehicleData([]);
  }

  const validateCloseCall = () => {
    let isError = false;
    const keys = Object.keys(errorCallTaker);
    keys.map((field) => {
      if (
        field === "location" &&
        (isEmpty(geoFormValues[field]) || geoFormValues[field] === null)) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "CallforServiceID" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "CFSLPriority" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "ReceiveSourceID" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else {
        handleErrorCallTaker(field, false);
      }
    });
    return !isError;
  };

  function handleOpenCloseCall() {
    if (!validateCloseCall()) return;
    setSelectedButton(prevSelected =>
      prevSelected.includes(6)
        ? prevSelected.filter(item => item !== 6)
        : [...prevSelected, 6]
    );
    setOpenCloseCallModal(true);
  }

  return (
    <>
      {openCallTakerModal ? (
        <dialog
          className="modal fade"
          style={{
            background: "rgba(0,0,0, 0.5)",
            zIndex: "200",
            overflow: "hidden",
          }}
          id="CallTakerModal"
          tabIndex="-1"
          aria-hidden="true"
          data-backdrop="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div
              className="modal-content modal-content-cad"
              style={{
                maxHeight: "calc(100vh - 100px)",
                overflowY: "auto",
              }}
            >
              <div className="modal-body">
                {/* Modal Title */}
                <div className="row">
                  <div className="col-12 p-0 pb-2">
                    <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                      <p
                        className="p-0 m-0 font-weight-medium"
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          letterSpacing: 0.5,
                        }}
                      >
                        Call Taker
                      </p>
                    </div>
                  </div>
                </div>
                <div className="m-1">
                  <fieldset
                    style={{ border: "1px solid gray" }}
                    className="tab-form-container"
                  >
                    {/* Incident */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Incident</legend>
                      <div className="d-flex">
                        <div
                          className="col-8"
                          style={{ display: "grid", gap: "5px" }}
                        >
                          {/* Row 1 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label htmlFor="" className="tab-form-label" style={{ textAlign: "end", marginRight: "4px" }}>
                                Location {errorCallTaker.location && (isEmpty(geoFormValues?.location) || geoFormValues?.location === null) && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Location"}</p>
                                )}
                              </label>
                            </div>
                            <div className="col-8 w-100 inner-input-fullw">
                              <Location
                                {...{
                                  value: geoFormValues,
                                  setValue: setGEOFormValues,
                                  locationStatus,
                                  setLocationStatus,
                                  updateStatus,
                                  setOnSelectLocation,
                                  locationData,
                                  setContactList: setContactList,
                                  setGeoLocationID,
                                  setContactInformation,
                                  flagDropDown,
                                  premiseDropDown,
                                  setZoom,
                                  setIsSelectLocation,
                                  setIsChangeFields,
                                  otherZoneDropDown,
                                  emsZoneDropDown,
                                  fireZoneDropDown,
                                  geoZoneDropDown,
                                  setIsGoogleLocation
                                }}
                                col="location"
                                locationID="NameLocationID"
                                check={true}
                                verify={geoFormValues.IsVerify}
                                page="Name"
                                isGEO
                              />
                            </div>
                            <div className="col-3 d-flex tab-form-row-gap">
                              <div className=" d-flex align-self-center justify-content-end">
                                <label
                                  for=""
                                  className="tab-form-label text-nowrap"
                                >
                                  Apt/Suite#
                                </label>
                              </div>
                              <input
                                type="number"
                                className="form-control  py-1 new-input"
                                name="ApartmentNo"
                                maxLength={4}
                                value={incidentFormValues.ApartmentNo}
                                onChange={handleInputChangeIncident}
                              />
                            </div>
                          </div>

                          {/* Row 2 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label htmlFor="" className="new-label" style={{ textAlign: "end", marginRight: "5px" }}>
                                CFS {errorCallTaker.CallforServiceID && isEmptyObject(incidentFormValues.CallforServiceID) && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select CFS"}</p>
                                )}
                              </label>
                            </div>
                            <div className="col-2 d-flex tab-form-row-gap">
                              <Select
                                options={cfsDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CallforServiceID"
                                getOptionLabel={(v) => v?.CFSCODE}
                                getOptionValue={(v) => v?.CallforServiceID}
                                isClearable
                                value={incidentFormValues.CallforServiceID}
                                onChange={handleSelectChangeIncidentCFS}
                                filterOption={(option, inputValue) =>
                                  option.data.CFSCODE.toLowerCase().startsWith(inputValue.toLowerCase())
                                }
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                              />
                            </div>
                            <div className="col-6 d-flex tab-form-row-gap">
                              <Select
                                options={cfsDescDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CFSLDetails"
                                getOptionLabel={(v) => v?.CFSCodeDescription}
                                getOptionValue={(v) => v?.CallforServiceID}
                                isClearable
                                // formatOptionLabel={(option, { context }) => {
                                //   return context === 'menu' ? option.CFSCODE + " | " + option.CFSCodeDescription : option?.CFSCodeDescription
                                // }}
                                value={incidentFormValues.CFSLDetails}
                                onChange={handleSelectChangeIncidentCFS}
                                filterOption={(option, inputValue) =>
                                  option.data.CFSCodeDescription.toLowerCase().startsWith(inputValue.toLowerCase())
                                }
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                              />
                            </div>{" "}
                            <div className="col-3 d-flex tab-form-row-gap">
                              <div className=" d-flex align-self-center justify-content-end">
                                <label className="tab-form-label text-nowrap" style={{ textAlign: "end" }}>
                                  Priority {errorCallTaker.CFSLPriority && isEmptyObject(incidentFormValues.CFSLPriority) && (
                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Priority"}</p>
                                  )}
                                </label>
                              </div>
                              <Select
                                isClearable
                                options={prioritiesDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CFSLPriority"
                                value={incidentFormValues.CFSLPriority}
                                getOptionLabel={(v) => `${v?.PriorityCode} | ${v?.Description}`}
                                getOptionValue={(v) => v?.PriorityCode}
                                formatOptionLabel={(option, { context }) => {
                                  return context === 'menu'
                                    ? `${option?.PriorityCode} | ${option?.Description}`
                                    : option?.PriorityCode;
                                }}
                                onChange={handleSelectChangeIncident}
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                              />
                            </div>
                          </div>

                          {/* Row 3 */}
                          <div className="tab-form-row d-flex justify-content-center">
                            <div className="col-6 d-flex justify-content-between offset-1" style={{ gap: "5px" }}>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSL} disabled onChange={handleCheckboxChangeIncident("CFSL")} />
                                <span className="new-label text-nowrap">L - Law</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSF} onChange={handleCheckboxChangeIncident("CFSF")} />
                                <span className="new-label text-nowrap">F - Fire</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSE} onChange={handleCheckboxChangeIncident("CFSE")} />
                                <span className="new-label text-nowrap">E - Emergency Medical Service</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.OTHER} onChange={handleCheckboxChangeIncident("OTHER")} />
                                <span className="new-label text-nowrap">O - Other</span>
                              </div>
                            </div>
                            <div className="col-4 d-flex tab-form-row-gap offset-1">
                              <label
                                htmlFor=""
                                className="new-label text-nowrap" style={{ textAlign: "end", paddingTop: "8px" }}
                              >
                                Receive Source{errorCallTaker.ReceiveSourceID && isEmptyObject(incidentFormValues.ReceiveSourceID) && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Source"}</p>
                                )}
                              </label>
                              <Select
                                isClearable
                                options={receiveSourceDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="ReceiveSourceID"
                                isSearchable
                                getOptionLabel={(v) => v?.ReceiveSourceCode}
                                getOptionValue={(v) => v?.ReceiveSourceID}
                                value={incidentFormValues.ReceiveSourceID}
                                onChange={handleSelectChangeIncident}
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                              />
                            </div>
                          </div>

                          {/* Row 5 */}
                          <div className="tab-form-row d-flex align-items-center">
                            <div className="col-1 d-flex justify-content-end">
                              <label htmlFor="" className="new-label" style={{ textAlign: "end", paddingTop: "2px", marginRight: "4px" }}>
                                Zone
                              </label>
                            </div>
                            <div className="col-11 tab-form-row-gap d-flex w-100">
                              {geoFormValues?.patrolZone?.label && <>
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#E0DEDE",
                                    color: "#000",
                                    border: "none",
                                    minWidth: "max-content"
                                  }}
                                  className="btn btn-sm btn-CADprimary1"
                                >
                                  {"Law " + geoFormValues?.patrolZone?.label}
                                </button></>}
                              {geoFormValues?.fireZone?.label && <>
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#E0DEDE",
                                    color: "#000",
                                    border: "none",
                                    minWidth: "max-content"
                                  }}
                                  className="btn btn-sm btn-CADprimary1"
                                >
                                  {"Fire " + geoFormValues?.fireZone?.label}
                                </button></>}
                              {geoFormValues?.emsZone?.label && <>
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#E0DEDE",
                                    color: "#000",
                                    border: "none",
                                    minWidth: "max-content"
                                  }}
                                  className="btn btn-sm btn-CADprimary1"
                                >
                                  {"EMS " + geoFormValues?.emsZone?.label}
                                </button></>} {geoFormValues?.otherZone?.label && <>
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "#E0DEDE",
                                      color: "#000",
                                      border: "none",
                                      minWidth: "max-content"
                                    }}
                                    className="btn btn-sm btn-CADprimary1"
                                  >
                                    {"Other " + geoFormValues?.otherZone?.label}
                                  </button></>}
                            </div>
                          </div>
                          {/* Row 4 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex justify-content-end">
                              <label for="" className="tab-form-label text-nowrap" style={{ marginRight: "3px" }}>
                                Comments
                              </label>
                            </div>
                            <div className="col-11 d-flex tab-form-row-gap">
                              <textarea
                                type="text"
                                rows="1"
                                className="form-control  py-1 new-input"
                                style={{ height: "auto", overflowY: "scroll" }}
                                placeholder="Comment"
                                name="Comments"
                                maxLength={40}
                                value={incidentFormValues.Comments}
                                onChange={(e) => {
                                  handleInputChangeIncident(e)
                                  e.target.style.height = "auto";
                                  const maxHeight = 1 * parseFloat(getComputedStyle(e.target).lineHeight);
                                  e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;
                                }}
                              />
                            </div>
                          </div>

                          {/* Row 7 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label className="tab-form-label text-nowrap" style={{ textAlign: "end", marginRight: "4px" }}>Flags</label>
                            </div>
                            <div className="col-11 d-flex tab-form-row-gap" style={{
                              display: "flex",
                              gap: "8px",
                              flexWrap: "wrap",
                            }}>

                              {/* 24 Hr and Premise History Button */}
                              {flagBudgeList?.Table?.map((item, index) => {
                                const buttons = [];

                                if (item?.Is24HourFlag === 1) {
                                  buttons.push(
                                    <button
                                      key={`24Hour-${index}`}
                                      type="button"
                                      style={{
                                        backgroundColor: "#FF0000",
                                        color: "#ffff",
                                        border: "none",
                                        whiteSpace: "nowrap",
                                      }}
                                      data-toggle="modal"
                                      data-target="#FlagTableModal"
                                      onClick={() => { setOpenFlagTableModal(true); setFlagName("Is24HourFlag"); }}
                                      className="btn btn-sm btn-CADprimary1"
                                    >
                                      24 Hr
                                    </button>
                                  );
                                }

                                if (item?.PremiseFlag === 1) {
                                  buttons.push(
                                    <button
                                      key={`Premise-${index}`}
                                      type="button"
                                      style={{
                                        backgroundColor: "#28a745",
                                        color: "#ffff",
                                        border: "none",
                                        whiteSpace: "nowrap",
                                      }}
                                      data-toggle="modal"
                                      data-target="#FlagTableModal"
                                      onClick={() => { setOpenFlagTableModal(true); setFlagName("PremiseFlag") }}
                                      className="btn btn-sm btn-CADprimary1"
                                    >
                                      Premise History
                                    </button>
                                  );
                                }
                                return <React.Fragment key={`item-${index}`}>{buttons}</React.Fragment>;
                              })}
                              {/* Flag List */}
                              {flagBudgeList?.Table1?.map((item, index) => {
                                return (
                                  <button
                                    key={index}
                                    type="button"
                                    style={{
                                      backgroundColor: item?.PriorityColor || "#0000",
                                      color: "#ffff",
                                      border: "none",
                                      whiteSpace: "nowrap",
                                    }}
                                    data-toggle="modal"
                                    data-target="#addFlagModal"
                                    onClick={() => { setOpenAddFlagModal(true); setSelectedFlagData(item); }}
                                    className="btn btn-sm btn-CADprimary1"
                                  >
                                    {item?.FlagType}
                                  </button>
                                );
                              })}
                              {/* Add flag button */}
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  marginLeft: "8px", // Add spacing to separate from other elements
                                  whiteSpace: "nowrap"
                                }}
                              >
                                <span
                                  data-toggle={isSelectLocation ? "modal" : undefined}
                                  data-target={isSelectLocation ? "#addFlagModal" : undefined}
                                  onClick={
                                    isSelectLocation
                                      ? () => {
                                        setOpenAddFlagModal(true);
                                        setSelectedFlagData({});
                                      }
                                      : undefined
                                  }
                                  className={`pt-1 ${!isSelectLocation ? "disabled" : ""}`}
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    cursor: isSelectLocation ? "pointer" : "not-allowed",
                                    color: isSelectLocation ? "blue" : "gray",
                                  }}
                                >
                                  Add Flag
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          {isMapVisible && isLoaded ? (
                            <GoogleMap
                              mapContainerStyle={{
                                height: "275px",
                                width: "100%",
                              }}
                              zoom={zoom}
                              center={mapCenter}
                              onLoad={handleMapLoad}
                              onZoomChanged={() => {
                                if (mapRef.current) {
                                  setZoom(mapRef.current.getZoom());
                                }
                              }}
                            >
                              {locations?.map((location, index) => (
                                <Marker
                                  key={index}
                                  position={{
                                    lat: location.lat,
                                    lng: location.lng,
                                  }}
                                  icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 10,
                                    fillColor: getColorByStatus(
                                      location.status
                                    ),
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                  }}
                                  title={location.name}
                                  onClick={() =>
                                    console.warn(`Location: ${location.name}`)
                                  }
                                />
                              ))}
                            </GoogleMap>
                          ) : (
                            <p>Loading Map...</p>
                          )}
                        </div>
                      </div>
                    </fieldset>

                    {/* Resources */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Resources</legend>
                      <div className="tab-form-row">
                        <div
                          className="col-12 d-flex align-items-center justify-content-start select-container mr-1"
                          style={{ gap: "4.5px" }}
                        >
                          <div>
                            <label className="tab-form-label text-nowrap" style={{ textAlign: "end", marginRight: "4px" }}> Resources # {errorCallTaker.Resource1 && isEmptyObject(incidentFormValues.Resource1) && (
                              <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select  Resources"}</p>
                            )}</label>
                          </div>
                          <div className="w-auto" style={{ minWidth: "250px" }}>
                            <Select
                              isClearable
                              options={resourceDropDown}
                              placeholder="Select..."
                              name="Resource1"
                              value={incidentFormValues?.Resource1}
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              onChange={handleSelectChangeIncident}
                              styles={customStylesWithOutColorArrow}
                              maxMenuHeight={145}
                              onInputChange={(inputValue, actionMeta) => {
                                if (inputValue.length > 12) {
                                  return inputValue.slice(0, 12);
                                }
                                return inputValue;
                              }}
                              isMulti
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    {/* Name */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Name</legend>
                      <div className="tab-form-row">
                        <div className="col-10 d-flex tab-form-row-gap">
                          <div className="d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Last Name
                            </label>
                          </div>
                          <input
                            type="text"
                            value={nameCallTaker.LastName}
                            name="LastName"
                            onChange={(e) => {
                              handleNameCallTaker("LastName", e.target.value); setIsChangeFields(true);
                            }}
                            autoComplete="off"
                            className="form-control  py-1 new-input  ml-2"
                            onKeyDown={handleTextKeyDown}
                          />{" "}
                          <div className="d-flex align-self-center justify-content-end">
                            <label htmlFor="" className="new-label text-nowrap tab-form-label">
                              First Name
                            </label>
                          </div>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control py-1 new-input"
                            name="FirstName"
                            value={nameCallTaker.FirstName}
                            onChange={(e) => { handleNameCallTaker("FirstName", e.target.value); setIsChangeFields(true); }}
                            onKeyDown={handleTextKeyDown}
                          />

                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap">
                              Reason Code {errorNameCallTaker.NameReasonCodeID && nameCallTaker?.NameReasonCodeID?.length === 0 && (
                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select  Reason Code"}</p>
                              )}</label>

                          </div>
                          {/* styles={customStylesWithOutColorArrow} */}
                          <Select
                            isClearable
                            options={reasonCodeDropDown}
                            placeholder="Select..."
                            className="w-100"
                            name="NameReasonCodeID"
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            allowSelectAll={true}
                            value={reasonCodeDropDown.filter(option => nameCallTaker.NameReasonCodeID.includes(option.value))}
                            onChange={handleSelectIncidentName}
                            menuPlacement="top"
                            onInputChange={(inputValue, actionMeta) => {
                              if (inputValue.length > 12) {
                                return inputValue.slice(0, 12);
                              }
                              return inputValue;
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: !isEmpty(nameCallTaker?.FirstName) || !isEmpty(nameCallTaker?.LastName) ? "#fce9bf" : "#fff",
                                height: '40px',
                                minHeight: '40px',
                                overflowY: 'hidden',
                                overflowX: 'hidden'
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                height: 'auto',
                                maxHeight: '40px',
                                overflowY: 'auto',
                                padding: '0 8px',
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transition: "all .2s ease",
                                transform: state.selectProps.menuIsOpen ? null : "rotate(180deg)"
                              }),
                              input: (provided) => ({
                                ...provided,
                                minWidth: '0px',
                                maxWidth: '100%',
                              }),
                            }}
                          />


                        </div>
                        <div
                          className="col-2 d-flex w-100"
                          style={{ gap: "3px" }}
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-border w-25"
                            onClick={addName}
                            disabled={!isNameCallTakerData}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button type="button" disabled={!isNameCallTakerData} data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-CADprimary w-100 d-flex align-items-center justify-content-center"
                            onClick={() => {
                              onNameSearch();
                            }}
                          >
                            <i className="fa fa-search mr-2"></i>
                            <span>Search</span>
                          </button>
                        </div>
                      </div>
                    </fieldset>

                    {/* Vehicle */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Vehicle</legend>
                      <div className="tab-form-row">
                        <div className="col-10 d-flex tab-form-row-gap">
                          <div className="col d-flex align-self-center justify-content-end" style={{ marginLeft: "8px" }}>
                            <label htmlFor="" className="tab-form-label new-label text-nowrap">
                              Plate #
                            </label>
                          </div>
                          <input
                            type="text"
                            name="Plate"
                            autoComplete="off"
                            maxLength={8}
                            value={vehicleCallTaker.Plate}
                            onChange={(e) => {
                              var ele = e.target.value.replace(/[^0-9a-zA-Z]/g, "")
                              const checkNumber = ele.toUpperCase();
                              handleVehicleCallTaker("Plate", checkNumber)
                            }}
                            className="form-control  py-1 new-input ml-2"
                            onKeyDown={handleNumberTextKeyDown}
                            menuPlacement="top"
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              State
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={stateList}
                            placeholder="Select..."
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            name="StateCode"
                            value={vehicleCallTaker.StateCode ? stateList?.find((i) => i?.value === parseInt(vehicleCallTaker.StateCode)) : ""}
                            onChange={handleSelectIncidentVehicle}
                            menuPlacement="top"
                            onInputChange={(inputValue, actionMeta) => {
                              if (inputValue.length > 12) {
                                return inputValue.slice(0, 12);
                              }
                              return inputValue;
                            }}
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Plate Type
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={plateTypeIdDrp}
                            placeholder="Select..."
                            name="PlateTypeCode"
                            value={vehicleCallTaker.PlateTypeCode ? plateTypeIdDrp?.find((i) => i?.value === parseInt(vehicleCallTaker.PlateTypeCode)) : ""}
                            onChange={handleSelectIncidentVehicle}
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            menuPlacement="top"
                            onInputChange={(inputValue, actionMeta) => {
                              if (inputValue.length > 12) {
                                return inputValue.slice(0, 12);
                              }
                              return inputValue;
                            }}
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Tag Year
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={tagYearDropDown}
                            placeholder="Select..."
                            name="TagYear"
                            value={vehicleCallTaker.TagYear ? tagYearDropDown?.find((i) => i.value === parseInt(vehicleCallTaker.TagYear)) : ""}
                            onChange={handleSelectIncidentVehicle}
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            menuPlacement="top"
                            onInputChange={(inputValue, actionMeta) => {
                              if (inputValue.length > 12) {
                                return inputValue.slice(0, 12);
                              }
                              return inputValue;
                            }}
                          />
                        </div>
                        <div
                          className="col-2 d-flex w-100"
                          style={{ gap: "3px" }}
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-border w-25"
                            onClick={addVehicle}
                            disabled={!isVehicleCallTakerData}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button type="button" disabled={!isVehicleCallTakerData} data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-CADprimary w-100 d-flex align-items-center justify-content-center"
                            onClick={() => setIsOpenVehicleSearchModel(true)}
                          >
                            <i className="fa fa-search mr-2"></i>
                            <span>Search</span>
                          </button>
                        </div>
                      </div>
                    </fieldset>

                  </fieldset>
                </div>
              </div>

              {/* Shortcut key and Buttons */}
              <div
                className="tab-form-row d-flex align-content-center justify-content-between px-3 py-2"
                style={{
                  // position: "sticky",
                  // bottom: "0",
                  zIndex: 100,
                  marginBottom: "0",
                }}
              >
                <div className="col-7 d-flex tab-form-row-gap justify-content-start ">
                  <button type="button"
                    className={`btn btn-sm w-25 bg-white btn-border`}
                    disabled={isDispatchLoading}
                    onClick={() => {
                      handleOnSave(false);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Dispatch</span>
                    </div>
                  </button>
                  <button type="button"
                    className={`btn btn-sm w-25 bg-white btn-border`}
                    disabled={isRouteLoading || incidentFormValues?.Resource1.length !== 0}
                    onClick={() => {
                      handleOnSave(true);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Route</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 bg-white ${selectedButton.includes(3) ? 'btn-border-selected' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#LocationInformationModal"
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(3)
                          ? prevSelected.filter(item => item !== 3)
                          : [...prevSelected, 3]
                      );
                      setOpenLocationInformationModal(true);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Location</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 bg-white ${selectedButton.includes(5) ? 'btn-border-selected' : contactList?.length > 0 ? 'btn-border-contact' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#ContactInfoModal"
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(5)
                          ? prevSelected.filter(item => item !== 5)
                          : [...prevSelected, 5]
                      );
                      setOpenContactInfoModal(true);
                    }}
                    disabled={!geoFormValues?.location}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Contact</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 bg-white ${selectedButton.includes(6) ? 'btn-border-selected' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#CloseCallModal"
                    onClick={() => handleOpenCloseCall()}
                    disabled={!isSelectLocation}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Close Call</span>
                    </div>
                  </button>
                  {/* <button type="button"
                    className={`btn btn-sm w-25 bg-white ${selectedButton.includes(6) ? 'btn-border-selected' : 'btn-border'}`}
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(6)
                          ? prevSelected.filter(item => item !== 6)
                          : [...prevSelected, 6]
                      );
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Location Flag</span>
                    </div>
                  </button> */}
                </div>
                <div className="col-5 d-flex justify-content-end tab-form-row-gap">
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => {
                      if (isChangeFields) {
                        setShowConfirmModal(true);
                      } else {
                        onCloseLocation();
                      }
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </dialog >
      ) : (
        <> </>
      )}
      <ModalConfirm showModal={showConfirmModal} setShowModal={setShowConfirmModal} confirmAction="close" handleConfirm={handleCloseConfirm} />
      {isOpenSearchNameModel && <NameSearchModal {...{ isOpenSearchNameModel, setIsOpenSearchNameModel, nameCallTaker, setNameCallTaker }} />}
      {openCloseCallModal && <CloseCallModal {...{ openCloseCallModal, setOpenCloseCallModal, setSelectedButton, getResourceValues, createPayload, isGoogleLocation, createLocationPayload, geoLocationID, setGeoLocationID, insertIncident, setNameData, setVehicleData, onCloseLocation }} />}
      {isOpenVehicleSearchModel && <VehicleSearchModal {...{ isOpenVehicleSearchModel, setIsOpenVehicleSearchModel, vehicleCallTaker, setVehicleCallTaker }} />}
      {openContactInfoModal && <ContactInfoModal {...{ openContactInfoModal, setOpenContactInfoModal, setSelectedButton, contactList, setContactList, isGoogleLocation }} />}
      {openFlagTableModal && <FlagTableModal {...{ openFlagTableModal, setOpenFlagTableModal, geoLocationID, flagName }} />}
      {openAddFlagModal && <FlagModal {...{ openAddFlagModal, setOpenAddFlagModal, isGoogleLocation, createLocationPayload, geoLocationID, setGeoLocationID, insertIncident, getFlagListRefetch, selectedFlagData, setSelectedFlagData }} />}
      <LocationInformationModal {...{ openLocationInformationModal, setOpenLocationInformationModal, setSelectedButton, geoFormValues, setGEOFormValues, isGoogleLocation }} />
    </>
  );
};

export default memo(CallTakerModal);
