import React, { useCallback, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router-dom'
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { Aes256Encrypt, Decrypt_Id_Name, base64ToString, tableCustomStyles, changeArrayFormat, changeArrayFormat_WithFilter, stringToBase64 } from '../../../../Common/Utility';
import { fetchPostData, AddDeleteUpadate, AddDelete_Img } from '../../../../hooks/Api';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import { useDispatch, useSelector } from 'react-redux';
import ChangesModal from '../../../../Common/ChangesModal';
import SelectBox from '../../../../Common/SelectBox';
import Select from "react-select";
import { get_Inc_ReportedDate, get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';
import { get_AgencyOfficer_Data } from '../../../../../redux/actions/DropDownsData';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import ListModal from '../../../Utility/ListManagementModel/ListModal';

const Home = ({ setStatus, DecdocumentID }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
    const incReportedDate = useSelector((state) => state.Agency.incReportedDate);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return { get: (param) => params.get(param) };
    };

    const query = useQuery();
    var IncID = query?.get("IncId");
    var documentID = query?.get("documentId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    var DocSta = query?.get('DocSta');

    let DecEIncID = 0
    // DecdocumentID = 0

    if (!IncID) { DecEIncID = 0; }
    else { DecEIncID = parseInt(base64ToString(IncID)); }
    // if (!documentID) { DecdocumentID = 0; }
    // else { DecdocumentID = parseInt((documentID)); }

    const { get_Incident_Count, setChangesStatus } = useContext(AgencyContext);
    const [clickedRow, setClickedRow] = useState(null);
    // const [DocumentID, setDocumentID] = useState('');
    const [updateStatus, setUpdateStatus] = useState(0)
    const [documentdata, setDocumentdata] = useState();
    const [mainIncidentID, setMainIncidentID] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [documentDrpVal, setDocumentDrpVal] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState([]);
    const [openPage, setOpenPage] = useState('');
    const [incidentReportedDate, setIncidentReportedDate] = useState(null);
    const [multiSelected, setMultiSelected] = useState({ optionSelected: null })
    const [selectedOption, setSelectedOption] = useState("Individual");
    const [userName, setuserName] = useState('');
    const [groupList, setGroupList] = useState([])
    const [EditValue, setEditValue] = useState([])
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);
    const [DocumentID, setDocumentID] = useState('');

    const [value, setValue] = useState({
        'AgencyID': '', 'DocumentID': '', 'DocumentName': '', 'DocumentNotes': '', 'File': '', 'IsActive': '1', 'DocumentTypeId': '', 'CreatedByUserFK': '', 'IncidentId': '', 'ModifiedByUserFK': '', 'DocumentAccessID': '', 'DocumentAccess': '', 'DocumentAccess_Name': '',
    })

    const [errors, setErrors] = useState({
        'DocFileNameError': '', 'DocumentTypeIDError': '', 'File_Not_Selected': '',
    })

    console.log(DecdocumentID)

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setuserName(localStoreData.UserName); setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(parseInt(localStoreData?.PINID)); get_DocumentDropDwn(localStoreData?.AgencyID)
            dispatch(get_ScreenPermissions_Data('I035', localStoreData?.AgencyID, localStoreData?.PINID));
            setDocumentID(DecdocumentID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (DecEIncID || loginPinID) {
            get_Documentdata(DecEIncID, loginPinID); setMainIncidentID(DecEIncID); dispatch(get_AgencyOfficer_Data(localStoreData?.AgencyID, DecEIncID))
            if (!incReportedDate) { dispatch(get_Inc_ReportedDate(IncID)) }
            setValue({ ...value, 'IncidentId': DecEIncID })
        }
    }, [DecEIncID, loginPinID]);

    console.log(DecEIncID)

    const check_Validation_Error = (e) => {
        const DocumentNameErr = RequiredFieldIncident(value.DocumentName);
        const DocumentTypeIDErr = RequiredFieldIncident(value.DocumentTypeId);
        const File_Not_SelectedErr = validate_fileupload(selectedFileName);
        setErrors(prevValues => {
            return {
                ...prevValues,
                ['DocFileNameError']: DocumentNameErr || prevValues['DocFileNameError'], ['DocumentTypeIDError']: DocumentTypeIDErr || prevValues['DocumentTypeIDError'], ['File_Not_Selected']: File_Not_SelectedErr || prevValues['File_Not_Selected'],
            }
        })
    }
    const { DocFileNameError, DocumentTypeIDError, File_Not_Selected } = errors

    useEffect(() => {
        if (DocFileNameError === 'true' && DocumentTypeIDError === 'true' && File_Not_Selected === 'true') { Add_Documents(); }
    }, [DocFileNameError, DocumentTypeIDError, File_Not_Selected])

    const changeHandler = (e) => {
        setStatesChangeStatus(true)
        const files = e.target.files
        setSelectedFile(files)
        const nameArray = []
        for (let name of files) { nameArray?.push(name?.name) }
        setSelectedFileName(nameArray);
    };

    const get_DocumentDropDwn = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('DocumentType/GetDataDropDown_DocumentType', val).then((data) => {
            if (data) {
                setDocumentDrpVal(Comman_changeArrayFormat(data, 'DocumentTypeID', 'Description'));
                console.log(data)
            }
            else { setDocumentDrpVal([]) }
        })
    };

    const Add_Documents = async (id) => {
        const formdata = new FormData();
        const EncFormdata = new FormData();
        const newDoc = [];
        const EncDocs = [];
        // multiple file upload
        for (let i = 0; i < selectedFile.length; i++) {
            formdata.append("File", selectedFile[i]);
            EncFormdata.append("File", selectedFile[i]);
        }
        const { DocumentID, DocumentName, DocumentNotes, File, DocumentTypeId, DocumentAccessID, DocumentAccess, PrimaryOfficerID, ReportedDtTm } = value;
        const documentAccess = selectedOption === "Individual" ? 'Individual' : 'Group';
        const val = {
            'IncidentId': DecEIncID, 'AgencyID': loginAgencyID, 'CreatedByUserFK': loginPinID, 'DocumentName': DocumentName, 'DocumentNotes': DocumentNotes, 'File': File, 'IsActive': '1', 'DocumentTypeId': DocumentTypeId, 'ModifiedByUserFK': '', 'PrimaryOfficerID': PrimaryOfficerID, 'ReportedDtTm': ReportedDtTm, 'DocumentAccessID': DocumentAccessID, 'DocumentAccess': documentAccess,
        };
        const values = JSON.stringify(val);
        const EncPostData = await Aes256Encrypt(JSON.stringify([JSON.stringify(val)]));
        EncDocs.push(EncPostData);
        newDoc.push(values);
        formdata.append("Data", JSON.stringify(newDoc));
        EncFormdata.append("Data", EncDocs);
        AddDelete_Img('IncidentDocumentManagement/Insert_IncidentDocManagement', formdata, EncFormdata)
            .then((res) => {
                if (res.success) {
                    const parsedData = JSON.parse(res.data);
                    const message = parsedData.Table[0].Message;
                    toastifySuccess(message); get_Documentdata(mainIncidentID, loginPinID); setChangesStatus(false); get_Incident_Count(mainIncidentID, loginPinID);

                    reset(); setSelectedFileName([]); setSelectedFile([]); setErrors({ ...errors, 'DocFileNameError': '', 'DocumentTypeIDError': '', 'File_Not_Selected': '', }); setStatesChangeStatus(false); setSelectedOption("Individual")
                } else {
                    console.log("something Wrong");
                }
            }).catch(err => console.log(err));
    }
    const get_Documentdata = (mainIncidentID, loginPinID) => {
        const val = { 'IncidentId': mainIncidentID, 'PINID': loginPinID }
        fetchPostData('IncidentDocumentManagement/GetData_IncidentDocManagement', val).then((res) => {
            if (res) { setDocumentdata(res); }
            else { setDocumentdata([]); }
        })
    }

    const columns = [
        {
            width: '120px',
            name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>Action</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, left: 20 }}>
                    <span onClick={() => window.open(row?.FileAttachment)} className="btn btn-sm bg-green text-white px-1 py-0" >
                        <i className="fa fa-eye"></i>
                    </span>

                </div>
        },
        {
            name: 'Document Name', selector: (row) => row.DocumentName, sortable: true
        },
        {
            name: 'Notes', selector: (row) => row.DocumentNotes, format: (row) => (<>{row?.DocumentNotes ? row?.DocumentNotes.substring(0, 70) : ''}{row?.DocumentNotes?.length > 40 ? '  . . .' : null} </>), sortable: true
        },
        {
            name: 'Document Type', selector: (row) => row.DocumentType_Description, sortable: true
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 5 }}>Delete</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, right: 12 }}>
                    {
                        effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
                            <span onClick={() => { setDocumentID(row?.DocumentID); }} className="btn btn-sm bg-green text-white px-1 py-0 ml-1" data-toggle="modal" data-target="#DeleteModal">
                                <i className="fa fa-trash"></i>
                            </span>
                            : <></>
                            : <span onClick={() => { setDocumentID(row?.DocumentID); }} className="btn btn-sm bg-green text-white px-1 py-0 ml-1" data-toggle="modal" data-target="#DeleteModal">
                                <i className="fa fa-trash"></i>
                            </span>
                    }
                </div>
        }
    ]

    const DeleteDocumentManagement = () => {
        const val = { 'DocumentID': DocumentID, 'IsActive': '0', 'DeletedByUserFK': loginPinID }
        AddDeleteUpadate('IncidentDocumentManagement/Delete_IncidentDocManagement', val).then((res) => {
            if (res) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message); reset(); get_Incident_Count(mainIncidentID, loginPinID); get_Documentdata(mainIncidentID, loginPinID);

            } else console.log("Somthing Wrong");
        })
    }

    const setStatusFalse = () => {
        navigate(`/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&DocSta=${false}&documentId=${('')}`)
        setClickedRow(null); setDocumentID(''); reset(); setSelectedFileName([]); setSelectedFile([]); setUpdateStatus(updateStatus + 1); setChangesStatus(false);
    }
    console.log(DocumentID)
    const handleChange = (e) => {
        setStatesChangeStatus(true); setChangesStatus(true); setValue({ ...value, [e.target.name]: e.target.value })
    }

    const ChangeDropDown = (e, name) => {
        setStatesChangeStatus(true); setChangesStatus(true);
        if (e) { setValue({ ...value, [name]: e.value }) }
        else { setValue({ ...value, [name]: null }) }
    }

    const reset = () => {
        navigate(`/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&DocSta=${false}&documentId=${('')}`)
        setValue({ ...value, 'DocumentName': '', 'File': '', 'DocumentTypeId': '', 'DocumentNotes': '', });
        document.querySelector("input[type='file']").value = "";
        setStatesChangeStatus(true); setDocumentID(''); setErrors({ ...errors, 'DocFileNameError': '', 'File_Not_Selected': '', 'DocumentTypeIDError': '', }); setSelectedFileName(''); setChangesStatus(false); setMultiSelected({ optionSelected: ' ' }); setIncidentReportedDate(''); setSelectedOption("Individual"); setStatus(false);
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") { reset(); }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => { document.removeEventListener("keydown", escFunction, false); };
    }, [escFunction]);

    const conditionalRowStyles = [
        {
            when: row => row.DocumentID === DocumentID, style: { backgroundColor: '#001f3fbd', color: 'white', cursor: 'pointer', },
        },
    ];

    // Custom Style
    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: "#fce9bf", height: 20, minHeight: 33, fontSize: 14, margintop: 2, boxShadow: 0, }),
    };

    const setToReset = () => {
    }

    const handleRadioChange = (e) => { setSelectedOption(e.target.id); };

    useEffect(() => {
        if (loginAgencyID) {
            get_Group_List(loginAgencyID);
        }
    }, [loginAgencyID]);


    const get_Group_List = (loginAgencyID) => {
        const value = { AgencyId: loginAgencyID }
        fetchPostData("Group/GetData_Group", value).then((res) => {
            if (res) {
                setGroupList(changeArrayFormat(res, 'group'))
                if (res[0]?.GroupID) { setValue({ ...value, ['GroupName']: changeArrayFormat_WithFilter(res, 'group', res[0]?.GroupID) }) }
            }
            else { setGroupList() }
        })
    }

    const Agencychange = (multiSelected) => {
        setStatesChangeStatus(true)
        setMultiSelected({ optionSelected: multiSelected });
        const id = []
        const name = []
        if (multiSelected) {
            multiSelected.map((item, i) => { id.push(item.value); name.push(item.label) })
            setValue({ ...value, ['DocumentAccessID']: id.toString(), ['DocumentAccess_Name']: name.toString() })
        }
    }
    // useEffect(() => {
    //     if (DocumentID) {
    //         // GetSingleData(DocumentID);
    //         // setDocumentID(row?.DocumentID);
    //         setDocumentID('')
    //     }
    // }, [DocumentID]);

    const set_Edit_Value = (row) => {
        if (row?.DocumentID) {
            navigate(`/Document-Home?IncId=${IncID}&IncNo=${IncNo}&IncSta=${IncSta}&DocSta=${true}&documentId=${stringToBase64(row?.DocumentID)}`)
            setStatus(true); setStatesChangeStatus(false); setUpdateStatus(updateStatus + 1);
            setDocumentID(row?.DocumentID); GetSingleData(row?.DocumentID)
        }
    }

    const GetSingleData = (DocumentID) => {
        const val = { 'DocumentID': DocumentID }
        fetchPostData('IncidentDocumentManagement/GetSingleData_IncidentDocManagement', val)
            .then((res) => {
                if (res) { setEditValue(res) }
                else { setEditValue() }
            })
    }

    useEffect(() => {
        // if (EditValue?.length > 0) {
        //     const accessIDs = EditValue[0]?.DocumentAccessID?.split(',').map(id => parseInt(id));
        //     setValue({
        //         ...value,
        //         'DocumentID': EditValue[0]?.DocumentID, 'DocumentAccess': EditValue[0]?.DocumentAccess, 'DocumentAccessID': EditValue[0]?.DocumentAccessID,
        //         'DocumentName': EditValue[0]?.DocumentName, 'DocumentNotes': EditValue[0]?.DocumentNotes, 'DocumentTypeId': EditValue[0]?.DocumentTypeId,
        //         'File': EditValue[0]?.FileAttachment, 'ModifiedByUserFK': loginPinID, 'DocumentAccess_Name': EditValue[0]?.DocumentAccess_Name,
        //     });
        //     if (EditValue[0]?.DocumentAccess === 'Group') {
        //         const initialSelectedOptions = groupList.filter(option => accessIDs?.includes(option.value));
        //         setMultiSelected({ optionSelected: initialSelectedOptions });
        //     }
        //     else {
        //         const initialSelectedOptions = agencyOfficerDrpData.filter(option => accessIDs?.includes(option.value));
        //         setMultiSelected({ optionSelected: initialSelectedOptions });
        //     }
        //     setSelectedOption(EditValue[0]?.DocumentAccess)
        // }
    }, [EditValue, agencyOfficerDrpData, groupList]);

    return (
        <>

            <div className="col-md-12">
                <div className="row">
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Document Name{errors.DocFileNameError !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.DocFileNameError}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-4 text-field mt-2" >
                        <input type="text" className='requiredColor' disabled={DocumentID} name='DocumentName' value={value.DocumentName} onChange={handleChange} required autoComplete='off' />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <span data-toggle="modal" onClick={() => {
                            setOpenPage('Document Type')
                        }} data-target="#ListModel" className='new-link'>
                            Document Type
                            {errors.DocumentTypeIDError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.DocumentTypeIDError}</p>
                            ) : null}
                        </span>
                    </div>
                    <div className="col-4 col-md-4 col-lg-4  mt-2" >
                        <Select
                            name='DocumentTypeId'
                            isDisabled={DocumentID}
                            styles={colourStyles}
                            value={documentDrpVal?.filter((obj) => obj.value === value?.DocumentTypeId)}
                            isClearable
                            options={documentDrpVal}
                            onChange={(e) => ChangeDropDown(e, 'DocumentTypeId')}
                            placeholder="Select.."
                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <span htmlFor="" className='label-name '>File Attachment{errors.File_Not_Selected !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.File_Not_Selected}</p>
                        ) : null}</span>
                    </div>
                    <div className="col-10 col-md-10 col-lg-10 text-field mt-2 mb-0">
                        <input type="file" className='requiredColor' name='File' disabled={DocumentID} onChange={changeHandler} required />
                        {selectedFileName?.length > 0 &&
                            <i className="fa fa-close" style={{ position: "absolute", right: "1rem", top: "7px" }} onClick={() => { setSelectedFileName(''); document.querySelector("input[type='file']").value = "" }}></i>}
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Notes</label>
                    </div>
                    <div className="col-10 col-md-10 col-lg-10 mb-0 mt-1" >
                        <textarea name='DocumentNotes' onChange={handleChange} disabled={DocumentID} id="Comments" value={value.DocumentNotes} cols="30" rows='2' className="form-control " ></textarea>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-md-12 col-lg-12">
                        <fieldset>
                            <legend>Document Access</legend>
                            <div className="row">
                                <div className="col-1 col-md-1 col-lg-1 mt-2 pt-1"></div>
                                <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            disabled={DocumentID}
                                            id="Individual"
                                            checked={selectedOption === "Individual"}
                                            onChange={handleRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="Individual">
                                            Individual
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            disabled={DocumentID}
                                            name="flexRadioDefault"
                                            id="Group"
                                            checked={selectedOption === "Group"}
                                            onChange={handleRadioChange}
                                        />
                                        <label className="form-check-label" htmlFor="Group">
                                            Group
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    {selectedOption === "Individual" ? (
                        <>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                                <label htmlFor="" className="label-name">
                                    User Name
                                </label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4 mt-1 mb-1">
                                <SelectBox
                                    options={agencyOfficerDrpData}
                                    isMulti
                                    isDisabled={DocumentID}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={true}
                                    onChange={Agencychange}
                                    allowSelectAll={true}
                                    value={multiSelected.optionSelected}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                                <label htmlFor="" className="label-name">
                                    Group
                                </label>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4 mt-1 mb-1">
                                <SelectBox
                                    options={groupList}
                                    isMulti
                                    isDisabled={DocumentID}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={true}
                                    onChange={Agencychange}
                                    allowSelectAll={true}
                                    value={multiSelected.optionSelected}
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className="btn-box text-right mr-1 mb-2 mt-2">
                    <button type="button" className="btn btn-sm btn-success mr-1 " onClick={() => { setStatusFalse(); setUpdateStatus(updateStatus + 1); }}>New</button>
                    <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus}>Save</button>
                </div>
                <DataTable
                    dense
                    columns={columns}
                    pagination
                    highlightOnHover
                    customStyles={tableCustomStyles}
                    data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? documentdata : [] : documentdata}
                    noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                    fixedHeader
                    onRowClicked={(row) => { setClickedRow(row); set_Edit_Value(row); }}
                    persistTableHead={true}
                    fixedHeaderScrollHeight='300px'
                    paginationPerPage={'100'}
                    paginationRowsPerPageOptions={[100, 150, 200, 500]}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
            <DeletePopUpModal func={DeleteDocumentManagement} />
            <ChangesModal func={check_Validation_Error} setToReset={setToReset} />
            <ListModal {...{ openPage, setOpenPage }} />
            <IdentifyFieldColor />
        </>
    )
}

export default Home

function validate_fileupload(fileName) {
    if (fileName.length > 0 && fileName.length < 2) {
        return 'true';
    } else if (fileName.length > 1) {
        toastifyError("Please Select Single File");
    } else {
        return 'Please Select File*';
    }

}