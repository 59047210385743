import React, { useContext, useEffect, useRef, useState } from 'react'
import { Decrypt_Id_Name, base64ToString, customStylesWithOutColor, tableCustomStyles } from '../../Common/Utility';
import Tab from '../../Utility/Tab/Tab';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import SelectBox from '../../Common/SelectBox';
import { components } from "react-select";
import DeletePopUpModal from '../../Common/DeleteModal';
import { get_LocalStoreData } from '../../../redux/actions/Agency';
import { useDispatch, useSelector } from 'react-redux';
import { Comman_changeArrayFormat, Comman_changeArrayFormatBasicInfo, Comman_changeArrayFormatBasicInfowithoutcode, Comman_changeArrayVictim, offenseArray, threeColArray, threeColArrayWithCode, threeColVictimInjuryArray, threeColVictimOffenseArray } from '../../Common/ChangeArrayFormat';
import { AddDeleteUpadate, fetchPostData } from '../../hooks/Api';
import { useLocation } from 'react-router-dom';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';
import { RequiredFieldIncident } from '../Utility/Personnel/Validation';
import { AgencyContext } from '../../../Context/Agency/Index';
import ChangesModal from '../../Common/ChangesModal';

const OffenderVictim = () => {

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const { setChangesStatus, get_Offence_Count, get_Incident_Count, } = useContext(AgencyContext);

    const [offenseDrp, setOffenseDrp] = useState();
    const [isCrimeIDSelected, setIsCrimeIDSelected] = useState(false);

    const SelectedValue = useRef();
    const [crimeBiasCategoryDrp, setCrimeBiasCategoryDrp] = useState([]);
    const [crimeOffenderUseDrp, setCrimeOffenderUseDrp] = useState([]);
    const [relationShipDrp, setRelationShipDrp] = useState([]);
    const [nameDrp, setNameDrp] = useState([]);
    const [VictimDrp, setVictimDrp] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();
    const [offenderOffenseDrp, setOffenderOffenseDrp] = useState();
    const [loginPinID, setLoginPinID,] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [injuryTypeEditVal, setInjuryTypeEditVal] = useState();
    const [injuryTypeDrp, setInjuryTypeDrp] = useState();
    //ids
    const [VictimOffenderNameData, setVictimOffenderNameData] = useState();
    const [DrpNameID, setDrpNameID] = useState();
    const [victimInjuryID, setVictimInjuryID] = useState();
    const [VictimID, setVictimID] = useState();
    const [OffenseID, setOffenseID] = useState();
    //-----------------------------------------------------offence---------------------------------------------
    const [locationIdDrp, setLocationIdDrp] = useState([]);
    const [crimeOffenderUseEditVal, setCrimeOffenderUseEditVal] = useState([]);
    const [crimeBiasCategoryEditVal, setCrimeBiasCategoryEditVal] = useState([]);
    const [crimeBiasCategory, setCrimeBiasCategory] = useState([])
    const [RelationshipID, setRelationshipID] = useState('');
    const [singleData, setSingleData] = useState([]);
    const [clickedRow, setClickedRow] = useState(null);
    const [clickedRowOff, setclickedRowOff] = useState(null);
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);
    const [statesChangeStatus1, setStatesChangeStatus1] = useState(false);
    //---------------------------------weapon-------------------------------------------
    const [weaponDrp, setWeaponDrp] = useState([]);
    const [weaponID, setWeaponID] = useState([]);
    const [weaponEditVal, setweaponEditVal] = useState([]);


    const [status, setStatus] = useState(false);
    const [statusOffrnce, setstatusOffrnce] = useState(false);
    const [offenderusing, setoffenderusing] = useState(false);
    const [CrimeID, setCrimeID] = useState('');
    const [isProperty, setIsProperty] = useState(true);

    const [value, setValue] = useState({
        'IncidentID': '', 'VictimID': VictimID, 'NameID': '', 'RelationshipTypeID': '', 'VictimNameID': '',
        'OffenderNameID': '', 'RelationshipID': '',
    });

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var IncID = query?.get("IncId");
    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );

    const [errors, setErrors] = useState({
        'RelationshipTypeIDErrors': '', ' VictimNameIDErrors': '', 'RelationshipIDErrors': '',
    })

    const check_Validation_Error = (e) => {
        if (RequiredFieldIncident(value.OffenderNameID)) {
            setErrors(prevValues => { return { ...prevValues, ['RelationshipTypeIDErrors']: RequiredFieldIncident(value.OffenderNameID) } })
        }
        if (RequiredFieldIncident(value.VictimNameID)) {
            setErrors(prevValues => { return { ...prevValues, ['VictimNameIDErrors']: RequiredFieldIncident(value.VictimNameID) } })
        }
        if (RequiredFieldIncident(value.RelationshipTypeID)) {
            setErrors(prevValues => { return { ...prevValues, ['RelationshipIDErrors']: RequiredFieldIncident(value.RelationshipTypeID) } })
        }
    }

    // Check All Field Format is True Then Submit 
    const { RelationshipTypeIDErrors, VictimNameIDErrors, RelationshipIDErrors } = errors

    useEffect(() => {
        if (RelationshipTypeIDErrors === 'true' && VictimNameIDErrors === 'true' && RelationshipIDErrors === 'true') {
            if (status && RelationshipID) update_Relationship()
            else { save_Relationship() }
        }
    }, [RelationshipTypeIDErrors, VictimNameIDErrors, RelationshipIDErrors])

    useEffect(() => {
        if (loginAgencyID) {
            get_Data_RelationShip_Drp(loginAgencyID); LocationIdDrpDwnVal(loginAgencyID);
            getCrimeOffenderUseDrpVal(); getCrimeBiasCategoryDrpVal(loginAgencyID); get_Weapon_DropDown(loginAgencyID);
        }
    }, [loginAgencyID])

    useEffect(() => {
        if (singleData?.RelationshipID) {
            setValue(pre => {
                return {
                    ...pre,
                    RelationshipTypeID: singleData?.RelationshipTypeID,
                    // VictimID: VictimID, 
                    VictimNameID: singleData?.VictimNameID,
                    OffenderNameID: singleData?.OffenderNameID, ModifiedByUserFK: loginPinID, RelationshipID: singleData?.RelationshipID,
                }
            })
        }
    }, [singleData])

    useEffect(() => {
        if (VictimID || loginPinID) {
            setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'NameID': DrpNameID, 'VictimID': VictimID, 'incidentID': IncID, } });
        }
    }, [loginPinID, VictimID])

    useEffect(() => {
        if (IncID || DrpNameID) {
            get_Data_Name_Drp(IncID); get_Data_Victim_Drp(IncID); get_Data_VictimOffenderName(IncID); get_Data_Offense_Drp(DrpNameID);
        }
    }, [IncID, DrpNameID])

    useEffect(() => {
        if (RelationshipID) {
            get_Single_Data(RelationshipID)
        }
    }, [RelationshipID])

    const get_Single_Data = (RelationshipID) => {
        const val = {
            'RelationshipID': RelationshipID,
        }
        fetchPostData('NameRelationship/GetSingleData_NameRelationship', val).then((data) => {
            if (data && data.length > 0) {
                const VictimID = data[0].VictimID;
                get_InjuryType_Data(VictimID);
                get_OffenseName_Data(VictimID);
                setSingleData(data[0])
            } else {
                setSingleData([])
            }
        })
    }

    const get_Data_VictimOffenderName = () => {
        const val = { 'IncidentID': IncID, }
        fetchPostData('Victim/GetData_VictimOffenderName', val).then((data) => {
            if (data) {
                setVictimOffenderNameData(data)
            } else {
                setVictimOffenderNameData([])
            }
        })
    }

    const Reset = () => {
        setValue({
            ...value,
            'OffenderInjuryID': '', 'OffenderNameID': '', 'RelationshipTypeID': '', 'NameID': '', 'VictimNameID': '',
        });
        //  setDrpNameID('');
        setStatesChangeStatus(false);
    }

    const get_Data_RelationShip_Drp = (loginAgencyID) => {
        const val = { 'AgencyID': loginAgencyID }
        fetchPostData('VictimRelationshipType/GetDataDropDown_VictimRelationshipType', val).then((data) => {
            if (data) {
                setRelationShipDrp(Comman_changeArrayFormat(data, 'VictimRelationshipTypeID', 'Description'))
            } else {
                setRelationShipDrp([])
            }
        })
    }

    const get_Data_Name_Drp = (IncID) => {
        const val = {
            'IncidentID': IncID,
        }
        fetchPostData('NameRelationship/GetDataDropDown_OffenderName', val).then((data) => {
            if (data) {
                setNameDrp(Comman_changeArrayFormat(data, 'NameID', 'Name'))
            } else {
                setNameDrp([])
            }
        })
    }

    const get_Data_Victim_Drp = () => {
        const val = {
            'IncidentID': IncID,
            'NameID': DrpNameID,
        }
        fetchPostData('Victim/GetData_InsertVictimName', val).then((data) => {
            if (data) {
                setVictimDrp(Comman_changeArrayVictim(data, 'NameID', 'VictimID', 'Name',))
            } else {
                setVictimDrp([])
            }
        })
    }

    //---------old-----------------
    // const ChangeDropDownVictim = (e, name) => {
    //     setStatesChangeStatus(true); setChangesStatus(true)
    //     const selectedNameId = e ? e.NameID :null ;
    //     const selectedVictimId = e ? e.null : ;
    //     const selectedValue = e ? e.value : null;
    //     setValue(prev => ({ ...prev, [name]: selectedValue }));
    //     setDrpNameID(selectedNameId);
    //     setVictimID(selectedVictimId);
    //     if (!selectedValue) {
    //         setVictimInjuryID([]);
    //         setOffenseID([]);
    //     }
    // };

    const ChangeDropDownVictim = (e, name) => {
        setStatesChangeStatus(true); setChangesStatus(true)
        const selectedNameId = e ? e.NameID : DrpNameID;
        const selectedVictimId = e ? e.VictimID : VictimID;
        const selectedValue = e ? e.value : null;
        setValue(prev => ({ ...prev, [name]: selectedValue }));
        setDrpNameID(selectedNameId);
        setVictimID(selectedVictimId);
        if (!selectedValue) {
            setVictimInjuryID([]);
            setOffenseID([]);
        }
    };

    const ChangeDropDown = (e, name) => {
        setStatesChangeStatus(true)
        if (e) {
            setChangesStatus(true)
            setValue({ ...value, [name]: e.value });
        } else {
            setChangesStatus(true)
            setValue({ ...value, [name]: null });
        }
    };

    useEffect(() => {
        if (injuryTypeEditVal) { setVictimInjuryID(injuryTypeEditVal) }
    }, [injuryTypeEditVal])

    useEffect(() => {
        if (typeOfSecurityEditVal) { setOffenseID(typeOfSecurityEditVal) }
    }, [typeOfSecurityEditVal])

    useEffect(() => {
        if (crimeOffenderUseEditVal) { setoffenderusing(crimeOffenderUseEditVal) }
    }, [crimeOffenderUseEditVal])


    useEffect(() => {
        if (crimeBiasCategoryEditVal) { setCrimeBiasCategory(crimeBiasCategoryEditVal) }
    }, [crimeBiasCategoryEditVal])


    useEffect(() => {
        if (weaponEditVal) { setWeaponID(weaponEditVal); }
    }, [weaponEditVal])

    useEffect(() => {
        get_InjuryType_Data(VictimID); get_OffenseName_Data(VictimID);
    }, [VictimID])

    useEffect(() => {
        if (VictimID || IncID) {
            get_Data_InjuryType_Drp(VictimID); get_Offense_DropDown(VictimID, IncID);
        }
    }, [VictimID, IncID])

    const get_Data_Offense_Drp = () => {
        const val = { 'NameID': DrpNameID, }
        fetchPostData('OffenderOffense/GetData_OffenderOffense', val).then((data) => {
            if (data) {
                setOffenderOffenseDrp(threeColVictimOffenseArray(data, 'OffenderOffenseID', 'Offense_Description', 'OffenseID', 'NameID', 'VictimID',))

            } else {
                setOffenderOffenseDrp([])
            }
        })
    }

    const get_OffenseName_Data = (VictimID) => {
        const val = {
            'VictimID': VictimID,
        }
        fetchPostData('VictimOffense/GetData_VictimOffense', val).then((res) => {
            if (res) {
                setTypeOfSecurityEditVal(offenseArray(res, 'VictimOffenseID', 'OffenseID', 'NameID', 'VictimID', 'Offense_Description', 'PretendToBeID'));
            } else {
                setTypeOfSecurityEditVal([]);
            }
        })
    }

    const get_InjuryType_Data = (VictimID) => {
        const val = { 'VictimID': VictimID, }
        fetchPostData('InjuryVictim/GetData_InjuryVictim', val).then((res) => {
            if (res) {
                setInjuryTypeEditVal(Comman_changeArrayFormatBasicInfowithoutcode(res, 'VictimInjuryID', 'NameID', 'PretendToBeID', 'NameEventInjuryID', 'VictimInjury_Description'));
            } else {
                setInjuryTypeEditVal([]);
            }
        })
    }

    const get_Data_InjuryType_Drp = (incidentID,) => {
        const val = { 'IncidentID': incidentID, 'VictimID': VictimID }
        fetchPostData('InjuryVictim/GetData_InsertVictimInjury', val).then((data) => {
            if (data) {
                setInjuryTypeDrp(threeColVictimInjuryArray(data, 'VictimInjuryID', 'Description', 'InjuryCode'))
            } else {
                setInjuryTypeDrp([])
            }
        })
    }

    const InjuryTypeOnChange = (multiSelected) => {
        setVictimInjuryID(multiSelected);
        const len = multiSelected.length - 1;
        const selectedValue = len >= 0 ? multiSelected[len].value : null;

        if (multiSelected.length < injuryTypeEditVal.length) {
            let missing = null;
            let i = injuryTypeEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(injuryTypeEditVal[--i])) ? missing : injuryTypeEditVal[i];
            }
            if (missing) {
                DelSertBasicInfo(missing.id, 'NameEventInjuryID', 'InjuryVictim/Delete_VictimInjury');
            }
        } else {
            if (selectedValue) {
                InSertBasicInfo(selectedValue, 'VictimInjuryID', 'InjuryVictim/Insert_VictimInjury');
            }
        }
    };

    const offense = (multiSelected) => {
        setOffenseID(multiSelected)
        // setMultiSelected({
        // ...multiSelected,
        // OffenseID: multiSelected
        // })
        const len = multiSelected.length - 1
        const selectedValue = len >= 0 ? multiSelected[len].value : null;
        if (multiSelected?.length < typeOfSecurityEditVal?.length) {
            let missing = null;
            let i = typeOfSecurityEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
            }
            DelSertBasicInfo(missing.value, 'VictimOffenseID', 'VictimOffense/Delete_VictimOffense')
        } else {
            if (selectedValue) {
                InSertBasicInfo(multiSelected[len].value, 'OffenseID', 'VictimOffense/Insert_VictimOffense')
            }
        }
    }

    const get_Offense_DropDown = () => {
        const val = {
            'IncidentID': IncID,
            'VictimID': VictimID
        }
        fetchPostData('VictimOffense/GetData_InsertVictimOffense', val).then((data) => {
            if (data) {
                // setOffenseID(data.CrimeID, 'crimess');
                setOffenseDrp(threeColVictimOffenseArray(data, 'CrimeID', 'Offense_Description'))
            } else {
                setOffenseDrp([])
            }
        })
    }

    const InSertBasicInfo = (id, col1, url) => {
        setDisabled(true)
        const val = {
            'NameID': DrpNameID,
            'VictimID': VictimID,
            'CrimeID': CrimeID,
            'AgencyID': loginAgencyID,
            [col1]: id,
            'CreatedByUserFK': loginPinID,
        }
        AddDeleteUpadate(url, val).then((res) => {
            if (res) {
                setDisabled(false);
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                // toastifySuccess(res.Message);
                // col1 === 'OffenseID' && get_Data_Offense_Drp(IncID, DrpNameID);
                col1 === 'OffenseID' && get_OffenseName_Data(VictimID);
                col1 === 'VictimInjuryID' && get_InjuryType_Data(VictimID);
                col1 === 'CrimeOffenderUseID' && get_Crime_OffenderUse_Data(CrimeID);
                col1 === 'CrimeBiasCategoryID' && get_Crime_Bias_Category_Data(CrimeID);
                col1 === 'WeaponTypeID' && get_Weapon_Data(CrimeID);
            } else {
                console.log("Somthing Wrong");
            }
        })
    }

    const DelSertBasicInfo = (OffenderOffenseID, col1, url) => {
        setDisabled(true)
        const val = {
            [col1]: OffenderOffenseID,
            'DeletedByUserFK': loginPinID,
        }
        AddDeleteUpadate(url, val).then((res) => {
            if (res) {
                setDisabled(false);
                const parsedData = JSON.parse(res.data);
                console.log(parsedData)
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                // col1 === 'OffenderOffenseID' && get_Data_Offense_Drp(IncID, DrpNameID);
                col1 === 'VictimOffenseID' && get_OffenseName_Data(VictimID)
                col1 === 'VictimInjuryID' && get_InjuryType_Data(VictimID);
                col1 === 'CrimeOffenderUseID' && get_Crime_OffenderUse_Data(CrimeID);
                col1 === 'CrimeBiasCategoryID' && get_Crime_Bias_Category_Data(CrimeID);
                col1 === 'WeaponID' && get_Weapon_Data(CrimeID);

            } else {
                console.log("Somthing Wrong");
            }
        })
    }

    const save_Relationship = () => {
        AddDeleteUpadate('NameRelationship/Insert_NameRelationship', value).then((data) => {
            if (data.success) {
                // toastifySuccess(data.Message);
                const parsedData = JSON.parse(data.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message); get_Data_VictimOffenderName(IncID); setStatus(false);
                setStatusFalse(); setStatesChangeStatus(false); setChangesStatus(true)
                setErrors({
                    ...errors,
                    'RelationshipTypeIDErrors': '', ' VictimNameIDErrors': '', 'RelationshipIDErrors': '',
                });
                // Get_Relationship_Data(nameID);
                // setModalStatus(false); resetHooks(); get_NameVictim_Count(victimID)
            } else {
                toastifyError(data.Message)
            }
        })
        // }
    }

    const update_Relationship = () => {
        AddDeleteUpadate('NameRelationship/Update_NameRelationship', value).then((data) => {
            if (data.success) {
                const parsedData = JSON.parse(data.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message); setChangesStatus(false)
                // toastifySuccess(data.Message);
                get_Data_VictimOffenderName(IncID); setStatesChangeStatus(false); setStatusFalse(); setStatus(false);
                // setStatus(true);
                setErrors({
                    ...errors,
                    'RelationshipTypeIDErrors': '', ' VictimNameIDErrors': '', 'RelationshipIDErrors': '',
                });
            } else {
                toastifyError(data.Message)
            }
        })
    }

    const DeleteRelationship = () => {
        const val = {
            'RelationshipID': RelationshipID,
            'DeletedByUserFK': loginPinID,
        }
        AddDeleteUpadate('NameRelationship/Delete_NameRelationship', val).then((res) => {
            if (res) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message); setChangesStatus(false)
                get_Data_VictimOffenderName(IncID); setStatus(false); setStatusFalse()
            } else { toastifyError("Somthing Wrong"); }
        })
    }

    const columns = [
        {
            name: 'Offender',
            selector: (row) => row.OffenderName
            ,
            sortable: true
        },
        {
            name: 'Victim',
            selector: (row) => row.VictimName,
            sortable: true
        },
        {
            name: 'Relationship Type',
            selector: (row) => row.RelationshipType,
            sortable: true
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: '10px' }}>Delete</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, right: 10 }}>
                    <span onClick={() => handleDeleteClick(row, 'RelationshipID')} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal" >
                        <i className="fa fa-trash"></i>
                    </span>
                </div>

        }
    ];

    const set_Edit_Value = (row) => {
        get_Single_Data(row.RelationshipID)
        setStatus(true); setStatesChangeStatus(false)
        setRelationshipID(row.RelationshipID);
        setErrors({});
    }

    const setStatusFalse = () => {
        Reset(); setStatus(false); setErrors('')
        setClickedRow(null); setStatesChangeStatus(false);
        setVictimInjuryID([]);
        setOffenseID([]);
        // setoffenderusing([])
    }

    const handleDeleteClick = (row, type) => {
        if (type === 'RelationshipID') {
            setIsProperty(true);
            setRelationshipID(row.RelationshipID);
        } else {
            setIsProperty(false);
            setCrimeID(row.CrimeID);
        }
    };

    const conditionalRowStyles = [
        {
            when: row => row === clickedRow,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    //------------------------------------------Offence---------------------------------------


    const [Offencedata, setOffencedata] = useState([])
    const [editval, setEditval] = useState();
    const [offenceData, setOffenceData] = useState([]);

    const [values, setValues] = useState({
        'ChargeCodeID': '', 'NIBRSCodeId': null, 'OffenseCodeId': null, 'LawTitleId': null, 'OffenderLeftSceneId': null,
        'CategoryId': null, 'PrimaryLocationId': null, 'SecondaryLocationId': null, 'FTADate': '',
        'Fine': '', 'CourtCost': '', 'FTAAmt': '', 'LitigationTax': '', 'DamageProperty': '', 'OfRoomsInvolved': '', 'PremisesEntered': '',
        'PropertyAbandoned': '', 'IsForceused': '', 'IsIncidentCode': false, 'AttemptComplete': "Completed",
        'CrimeID': '', 'IncidentID': '', 'CreatedByUserFK': '', 'ModifiedByUserFK': '', 'CrimeOffenderUseID': '',
    });


    const [error, setError] = useState({
        'RelationshipTypeIDErrors': '',
        // 'offenderusingErrors': '',
    })

    const check_ValidationError = (e) => {
        if (RequiredFieldIncident(values.PrimaryLocationId)) {
            setError(prevValues => { return { ...prevValues, ['PrimaryLocationIdErrors']: RequiredFieldIncident(values.PrimaryLocationId) } })
        }
        // if (RequiredFieldIncident(values.CrimeOffenderUseID)) {
        //     setError(prevValues => { return { ...prevValues, ['offenderusingErrors']: RequiredFieldIncident(values.CrimeOffenderUseID) } })
        // }
    }

    // Check All Field Format is True Then Submit 
    const { PrimaryLocationIdErrors, } = error

    useEffect(() => {
        if (PrimaryLocationIdErrors === 'true') {
            if (statusOffrnce && CrimeID) { Update_Offence() }
            else { save_Offrnce() }
        }
    }, [PrimaryLocationIdErrors])

    const ChangeDropDownPrimary = (e, name) => {
        setStatesChangeStatus1(true)
        if (e) {
            setChangesStatus(true)
            setValues({ ...values, [name]: e.value });
        } else {
            setChangesStatus(true)
            setValues({ ...values, [name]: null });
        }
    };

    useEffect(() => {
        if (CrimeID) {
            setValues(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'CrimeID': CrimeID, } });
        }
    }, [CrimeID])

    const get_Offence_Data = (IncidentId) => {
        const val = { 'IncidentId': IncidentId, }
        fetchPostData('Crime/GetData_Offense', val)
            .then(res => {
                if (res) {
                    setOffenceData(Comman_changeArrayFormat(res, 'CrimeID', 'OffenseName_Description'));
                }
                else { setOffenceData([]); }
            })
    };

    useEffect(() => {
        if (CrimeID) {
            GetSingleData(CrimeID)
        }
    }, [CrimeID])

    useEffect(() => {
        get_Crime_OffenderUse_Data(CrimeID); get_Offence_Count(CrimeID); get_Offence_Data(IncID); get_Offence_data(IncID);
        get_Crime_Bias_Category_Data(CrimeID); get_Weapon_Data(CrimeID); LocationIdDrpDwnVal(loginAgencyID, CrimeID);
    }, [CrimeID, IncID])

    const get_Offence_data = (IncidentId) => {
        const val = { 'IncidentId': IncidentId, }
        fetchPostData('Crime/GetData_Offense', val)
            .then(res => {
                if (res) {
                    setOffencedata(res);
                }
                else { setOffencedata([]); }
            })
    };

    const GetSingleData = (CrimeID) => {
        const val = { 'CrimeID': CrimeID }
        fetchPostData('Crime/GetSingleData_Offense', val).then((res) => {
            if (res) {
                setEditval(res);
            } else { setEditval() }
        })
    }

    useEffect(() => {
        if (CrimeID) {
            if (editval?.length > 0) {
                setValues({
                    ...value,
                    'CrimeID': CrimeID, 'NIBRSCodeId': editval[0]?.NIBRSCodeId,
                    'ChargeCodeID': editval[0]?.ChargeCodeID, 'PrimaryLocationId': editval[0]?.PrimaryLocationId,
                    'ModifiedByUserFK': loginPinID, 'CreatedByUserFK': loginPinID,
                })
            }
        }
        else {
            setValues({
                ...value, 'CrimeID': '', 'ChargeCodeID': '', 'NIBRSCodeId': '', 'PrimaryLocationId': null,
            });
        }
    }, [editval,])


    const columns1 = [
        {
            name: 'Location',
            selector: (row) => row.PrimaryLocation,
            sortable: true
        },
        {
            name: 'Offense Code',
            selector: (row) => row.OffenseName_Description,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: '10px' }}>Delete</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, right: 10 }}>
                    {/* <span onClick={(e) => { setIsProperty(false); setCrimeID(row.CrimeID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal" > */}
                    <span onClick={() => handleDeleteClick(row, 'crime')} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal" >

                        <i className="fa fa-trash"></i>
                    </span>
                </div>

        }

    ]

    const setEditVal = (row) => {
        if (row.CrimeID) {
            setIsCrimeIDSelected(true);
        }
        GetSingleData(row.CrimeID); setStatesChangeStatus1(false)
        setCrimeID(row.CrimeID);
        setstatusOffrnce(true)
        setError({});

        // setOffenceID(row?.CrimeID);

    }

    const LocationIdDrpDwnVal = () => {
        const val = { AgencyID: loginAgencyID, 'CrimeID': CrimeID }
        fetchPostData('LocationType/GetDataDropDown_LocationType', val).then((data) => {
            if (data) {
                setLocationIdDrp(threeColArray(data, 'LocationTypeID', 'Description', 'LocationTypeCode'))
            } else {
                setLocationIdDrp([]);
            }
        })
    }

    const getCrimeOffenderUseDrpVal = () => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('CrimeOffenderUse/GetDataDropDown_CrimeOffenderUse', val).then((data) => {
            if (data) {
                setCrimeOffenderUseDrp(threeColArrayWithCode(data, 'OffenderUseID', 'Description', 'OffenderUseCode'))
            } else {
                setCrimeOffenderUseDrp([]);
            }
        })
    }

    const get_Crime_OffenderUse_Data = () => {
        const val = { 'CrimeID': CrimeID, }
        fetchPostData('OffenseOffenderUse/GetData_OffenseOffenderUse', val)
            .then((res) => {
                if (res) {
                    setCrimeOffenderUseEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeOffenderUseID', 'Description', 'PretendToBeID', 'OffenderUseID', 'OffenderUseCode'));
                }
                else {
                    setCrimeOffenderUseEditVal([]);
                }
            })
    }

    const getCrimeBiasCategoryDrpVal = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('CrimeBias/GetDataDropDown_CrimeBias', val).then((data) => {
            if (data) {
                setCrimeBiasCategoryDrp(threeColArrayWithCode(data, 'BiasID', 'Description', 'BiasCode'))
            } else {
                setCrimeBiasCategoryDrp([]);
            }
        })
    }

    const get_Weapon_DropDown = (loginAgencyID) => {
        const val = { 'AgencyID': loginAgencyID, }
        fetchPostData('OffenseWeapon/GetData_InsertOffenseWeapon', val).then((data) => {
            if (data) {
                setWeaponDrp(threeColArrayWithCode(data, 'WeaponID', 'Description', 'WeaponCode'));
            } else {
                setWeaponDrp([])
            }
        })
    }

    const get_Crime_Bias_Category_Data = (CrimeID) => {
        const val = { 'CrimeID': CrimeID, }
        fetchPostData('OffenseBiasCategory/GetData_OffenseBiasCategory', val)
            .then((res) => {
                if (res) {
                    setCrimeBiasCategoryEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeBiasCategoryID', 'Description', 'PretendToBeID', 'BiasCategoryID', 'BiasCode'));
                }
                else {
                    setCrimeBiasCategoryEditVal([]);
                }
            })
    }

    // weapon
    const get_Weapon_Data = (CrimeID) => {
        const val = { 'CrimeID': CrimeID, }
        fetchPostData('OffenseWeapon/GetData_OffenseWeapon', val).then((res) => {
            if (res) {
                setweaponEditVal(Comman_changeArrayFormatBasicInfo(res, 'WeaponID', 'Weapon_Description', 'PretendToBeID', 'CrimeID', 'WeaponCode'))
            } else {
                setweaponEditVal([]);
            }
        })
    }

    const OffenderUsechange = (multiSelected) => {
        setoffenderusing(multiSelected)
        const len = multiSelected.length - 1
        const selectedValues = len >= 0 ? multiSelected[len].value : null;
        if (multiSelected?.length < crimeOffenderUseEditVal?.length) {
            let missing = null;
            let i = crimeOffenderUseEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(crimeOffenderUseEditVal[--i])) ? missing : crimeOffenderUseEditVal[i];
            }
            DelSertBasicInfo(missing.id, 'OffenderUseID', 'OffenseOffenderUse/DeleteOffenseOffenderUse')
        } else {
            if (selectedValues) {
                InSertBasicInfo(multiSelected[len].value, 'CrimeOffenderUseID', 'OffenseOffenderUse/InsertOffenseOffenderUse')
            }
        }
    }

    const CrimeBiasCategorychange = (multiSelected) => {
        setCrimeBiasCategory(multiSelected)
        const len = multiSelected.length - 1
        const selectedValues = len >= 0 ? multiSelected[len].value : null;
        if (multiSelected?.length < crimeBiasCategoryEditVal?.length) {
            let missing = null;
            let i = crimeBiasCategoryEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(crimeBiasCategoryEditVal[--i])) ? missing : crimeBiasCategoryEditVal[i];
            }
            DelSertBasicInfo(missing.id, 'BiasCategoryID', 'OffenseBiasCategory/DeleteOffenseBiasCategory')
        } else {
            if (selectedValues) {
                InSertBasicInfo(multiSelected[len].value, 'CrimeBiasCategoryID', 'OffenseBiasCategory/InsertOffenseBiasCategory')
            }
        }
    }

    const Weaponchange = (multiSelected) => {
        setWeaponID(multiSelected)
        const len = multiSelected.length - 1
        if (multiSelected?.length < weaponEditVal?.length) {
            let missing = null;
            let i = weaponEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(weaponEditVal[--i])) ? missing : weaponEditVal[i];
            }
            DelSertBasicInfo(missing.id, 'WeaponID', 'OffenseWeapon/Delete_OffenseWeapon')
        } else {
            InSertBasicInfo(multiSelected[len].value, 'WeaponTypeID', 'OffenseWeapon/Insert_OffenseWeapon')
        }
    }

    function filterArray(arr, key) {
        return [...new Map(arr?.map(item => [item[key], item])).values()]
    }

    const conditionalRowStyles1 = [
        {
            when: row => row.CrimeID == CrimeID,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];


    const setStatusFalseOffrnce = () => {
        setError(''); setStatesChangeStatus1(false)
        setstatusOffrnce(false); setValues({ ...values, CrimeID: null, PrimaryLocationId: null });
        setoffenderusing([]);
        setCrimeBiasCategory([]);
        setCrimeID(null); setIsCrimeIDSelected(false)
    };


    const save_Offrnce = () => {
        AddDeleteUpadate('Crime/Insert_Offense', values).then((data) => {
            if (data.success) {
                toastifySuccess(data.Message); get_Offence_Data(IncID); get_Offence_data(IncID); setstatusOffrnce(true); setChangesStatus(false)
                get_Offence_Count(CrimeID); get_Incident_Count(IncID, loginPinID);
                setError({
                    ...error,
                    'PrimaryLocationIdErrors': '',
                });
            } else {
                toastifyError(data.Message)
            }
        })
        // }
    }

    const Update_Offence = () => {
        AddDeleteUpadate('Crime/Update_Offense', values).then((res) => {
            // toastifySuccess(res.Message); 
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message); setStatesChangeStatus1(false)
            setstatusOffrnce(true); setChangesStatus(false); setStatusFalseOffrnce(); get_Incident_Count(IncID, loginPinID);
            get_Offence_Data(IncID); get_Offence_data(IncID); setError({
                ...error,
                'PrimaryLocationIdErrors': '',
            });
        })

    }

    const DeleteOffence = () => {
        const value1 = { 'CrimeID': CrimeID, 'DeletedByUserFK': loginPinID, }
        AddDeleteUpadate('Crime/Delete_Offense', value1).then((res) => {
            // const parsedData = JSON.parse(res.data);
            // const message = parsedData.Table[0].Message;
            // toastifySuccess(message);
            toastifySuccess(res.Message); setStatusFalseOffrnce();
            get_Offence_Data(IncID); get_Offence_data(IncID); get_Incident_Count(IncID, loginPinID);
        })
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            minHeight: 45,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const customStylesWithColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 45,
            fontSize: 14,
            marginTop: 2,
            boxShadow: 0,
            backgroundColor: '#9d949436',
        }),
    };

    return (
        <>
            <div className="section-body view_page_design  p-1 bt">
                <div className="div">
                    <div className="col-12 inc__tabs">
                        <Tab />
                    </div>
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <div className="col-md-12" style={{ marginTop: '-20px' }}>
                                    <fieldset >
                                        <legend>Offender/Victim</legend>
                                        <div className="row"  >
                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Offender{errors.RelationshipTypeIDErrors !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.RelationshipTypeIDErrors}</p>
                                                ) : null}</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-2 mt-1" >
                                                <Select
                                                    name='OffenderNameID'
                                                    styles={colourStyles}
                                                    isClearable
                                                    value={nameDrp?.filter((obj) => obj.value === value.OffenderNameID)}
                                                    options={nameDrp}
                                                    onChange={(e) => { ChangeDropDown(e, 'OffenderNameID'); }}
                                                    placeholder="Select.."
                                                />

                                            </div>
                                            <div className="col-1 mt-2" data-toggle="modal" data-target="#MasterModal" >
                                                <button className=" btn btn-sm bg-green text-white py-1" >
                                                    <i className="fa fa-plus" >
                                                    </i>
                                                </button>
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Victim{errors.VictimNameIDErrors !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.VictimNameIDErrors}</p>
                                                ) : null}</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1" >
                                                <Select
                                                    name='VictimNameID'
                                                    styles={colourStyles}
                                                    isClearable
                                                    value={VictimDrp?.filter((obj) => obj.value === value.VictimNameID)}
                                                    options={VictimDrp}
                                                    onChange={(e) => { ChangeDropDownVictim(e, 'VictimNameID'); }}
                                                    placeholder="Select.."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Relationship{errors.RelationshipIDErrors !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.RelationshipIDErrors}</p>
                                                ) : null}</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1" >
                                                <Select
                                                    name='RelationshipTypeID'
                                                    styles={colourStyles}
                                                    isClearable
                                                    value={relationShipDrp?.filter((obj) => obj.value === value.RelationshipTypeID)}
                                                    options={relationShipDrp}
                                                    onChange={(e) => { ChangeDropDown(e, 'RelationshipTypeID'); }}
                                                    placeholder="Select.."
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Offense</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-5" >
                                                <SelectBox
                                                    name='OffenseID'
                                                    isClearable={false}
                                                    options={offenseDrp}
                                                    closeMenuOnSelect={false}
                                                    placeholder="Select.."
                                                    components={{ MultiValue, }}
                                                    onChange={(e) => offense(e)}
                                                    value={OffenseID}
                                                    ref={SelectedValue}
                                                    className="basic-multi-select"
                                                    isMulti
                                                    isDisabled={!value?.VictimNameID}
                                                    // styles={customStylesWithOutColor}
                                                    styles={!value?.VictimNameID ? customStylesWithColor : customStylesWithOutColor}

                                                />
                                            </div>

                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Injury Type</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-5 " >
                                                <SelectBox
                                                    name='VictimInjuryID'
                                                    isClearable={false}
                                                    options={injuryTypeDrp}
                                                    closeMenuOnSelect={false}
                                                    placeholder="Select.."
                                                    components={{ MultiValue, }}
                                                    onChange={(e) => InjuryTypeOnChange(e)}
                                                    value={victimInjuryID}
                                                    ref={SelectedValue}
                                                    className="basic-multi-select"
                                                    isMulti
                                                    isDisabled={!value?.VictimNameID}
                                                    // styles={customStylesWithOutColor}
                                                    styles={!value?.VictimNameID ? customStylesWithColor : customStylesWithOutColor}
                                                />
                                            </div>
                                            <div className="btn-box text-right col-12 col-md-12 col-lg-12 mr-1 pt-1">
                                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={() => { setStatusFalse(); }}>New</button>
                                                {
                                                    status ?
                                                        <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }}>Update</button>
                                                        :
                                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                                                }
                                            </div>
                                        </div>

                                    </fieldset>
                                    <div className="col-12 col-md-12 col-lg-12  " style={{ marginTop: '-8px' }}>
                                        <DataTable
                                            dense
                                            columns={columns}
                                            data={VictimOffenderNameData}
                                            pagination
                                            highlightOnHover
                                            customStyles={tableCustomStyles}
                                            fixedHeader
                                            onRowClicked={(row) => {
                                                setClickedRow(row);
                                                set_Edit_Value(row);
                                            }}
                                            conditionalRowStyles={conditionalRowStyles}
                                            persistTableHead={true}
                                            fixedHeaderScrollHeight='90px'

                                        />
                                    </div>
                                </div>
                                {/* <DeletePopUpModal func={DeleteRelationship} /> */}
                                <div className="col-12 col-md-12 col-lg-12  ">
                                    <fieldset>
                                        <legend>Offense</legend>
                                        <div className="row ">
                                            <div className="px-0 mt-2 col-12" >
                                                <DataTable
                                                    columns={columns1}
                                                    showHeader={true}
                                                    data={Offencedata}
                                                    persistTableHead={true}
                                                    dense
                                                    highlightOnHover
                                                    responsive
                                                    customStyles={tableCustomStyles}
                                                    fixedHeader
                                                    fixedHeaderScrollHeight='90px'
                                                    pagination
                                                    onRowClicked={(row) => {
                                                        setclickedRowOff(row);
                                                        setEditVal(row);
                                                    }}
                                                    conditionalRowStyles={conditionalRowStyles1}
                                                    paginationPerPage={'100'}
                                                    paginationRowsPerPageOptions={[100, 150, 200, 500]}
                                                    showPaginationBottom={100}
                                                />
                                            </div>

                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Bias</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-5 pt-1" >
                                                <SelectBox
                                                    className="basic-multi-select"
                                                    name='bias'
                                                    options={crimeBiasCategoryDrp}
                                                    isClearable={false}
                                                    styles={customStylesWithOutColor}
                                                    // isDisabled={disabled}
                                                    isMulti
                                                    menuPlacement="top"
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={true}
                                                    components={{ MultiValue, }}
                                                    onChange={(e) => CrimeBiasCategorychange(e)}
                                                    // value={crimeBiasCategory}
                                                    value={filterArray(crimeBiasCategory, 'label')}
                                                    placeholder='Select Bias From List'
                                                    isDisabled={!isCrimeIDSelected}
                                                // isDisabled={!value?.isCrimeIDSelected}
                                                // styles={!value?.isCrimeIDSelected ? customStylesWithColor : customStylesWithOutColor}
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Offender&nbsp;Using
                                                    {/* {error.offenderusingErrors !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{error.offenderusingErrors}</p>
                                                ) : null} */}
                                                </label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-5 pt-1" >
                                                <SelectBox
                                                    className="basic-multi-select"
                                                    name='CrimeOffenderUseID'
                                                    options={crimeOffenderUseDrp}
                                                    isClearable={false}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={true}
                                                    menuPlacement="top"
                                                    components={{ MultiValue }}
                                                    onChange={(e) => OffenderUsechange(e)}
                                                    // value={crimeOffenderUse}
                                                    value={offenderusing}
                                                    placeholder='Select Offender Using From List'
                                                    styles={customStylesWithOutColor}
                                                    isDisabled={!isCrimeIDSelected}
                                                // isDisabled={!value?.isCrimeIDSelected}
                                                // styles={!value?.isCrimeIDSelected ? customStylesWithColor : customStylesWithOutColor}
                                                />
                                            </div>
                                            {/* //--weapon */}
                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Weapon
                                                </label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-5 pt-1" >
                                                <SelectBox
                                                    className="basic-multi-select"
                                                    name='WeaponTypeID'
                                                    options={weaponDrp}
                                                    isClearable={false}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={true}
                                                    menuPlacement="top"
                                                    components={{ MultiValue }}
                                                    onChange={(e) => Weaponchange(e)}
                                                    // value={weaponID}
                                                    value={filterArray(weaponID, 'label')}
                                                    placeholder='Select Offender Using From List'
                                                    styles={customStylesWithOutColor}
                                                    isDisabled={!isCrimeIDSelected}
                                                // isDisabled={!value?.isCrimeIDSelected}
                                                // styles={!value?.isCrimeIDSelected ? customStylesWithColor : customStylesWithOutColor}
                                                />
                                            </div>
                                            <div className="col-2 col-md-2 col-lg-1 mt-3">
                                                <label htmlFor="" className='label-name '>Location{error.PrimaryLocationIdErrors !== 'true' ? (
                                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{error.PrimaryLocationIdErrors}</p>
                                                ) : null}</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-5 mt-2" >
                                                <Select
                                                    name='PrimaryLocationId'
                                                    styles={colourStyles}
                                                    value={locationIdDrp?.filter((obj) => obj.value === values?.PrimaryLocationId)}
                                                    isClearable
                                                    options={locationIdDrp}
                                                    onChange={(e) => ChangeDropDownPrimary(e, 'PrimaryLocationId')}
                                                    placeholder="Select..."
                                                    isDisabled={!isCrimeIDSelected}
                                                    menuPlacement='top'
                                                />
                                            </div>
                                            <div className="btn-box text-right col-12 col-md-12 col-lg-6 px-0 mt-1">
                                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={() => { setStatusFalseOffrnce(); }} >New</button>
                                                <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus1} onClick={(e) => { check_ValidationError(); }}>Update</button>

                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <ChangesModal func={(e) => { check_ValidationError(e); check_Validation_Error(e); }} />
            <DeletePopUpModal func={!isProperty ? DeleteOffence : DeleteRelationship} />
        </>
    )
}

export default OffenderVictim







