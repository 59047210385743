import React, {useCallback, useState} from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import DataTable from 'react-data-table-component';
import { base64ToString, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, stringToBase64, tableCustomStyles } from '../../Common/Utility';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useContext } from 'react'
import { Comman_changeArrayFormat } from '../../Common/ChangeArrayFormat';
import { fetchPostData } from '../../hooks/Api';

import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmModal from '../Arrest/ConfirmModal';
import ExpungeConfirmModel from '../../Common/ExpungeConfirmModel';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';
import { get_ScreenPermissions_Data } from '../../../redux/actions/IncidentAction';
import { get_AgencyOfficer_Data } from '../../../redux/actions/DropDownsData';
import { debounce } from 'lodash';

const UnExpunge = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);

    const [loginAgencyID, setloginAgencyID] = useState('');
    const [dateOfbirth, setdateOfbirth] = useState();
    const [dateTo, setdateTo] = useState();
    const [nameSearchValue, setNameSearchValue] = useState([]);
    const [logData, setLogData] = useState([]);
    const [loginPinID, setLoginPinID,] = useState('');
    const [nameId, setNameID] = useState('');
    const [masterNameID, setMasterNameID] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [notes, setNotes] = useState('');
    const [transactionName, setTransactionName] = useState([]);
    const [transactionId, setTransactionId] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectionLockedArrest, setselectionLockedArrest] = useState(false);

    const [arrestSelectCount, setArrestSelectCount] = useState([]);
    const [arrestChareData, setArrestChareData] = useState([]);
    const [arrestId ,setArrestId] = useState('');

    const [ChargeID, setChargeID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [ExpungeHistoryID , setExpungeHistoryID] = useState([]);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);


    const [value, setValue] = useState({
        'CreatedDtTmfrom': '', 'CreatedDtTmTo': '', 'AgencyID': '', 'PrimaryOfficerID': ''
    })

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DeNameID = 0, DeMasterNameID = 0

    const query = useQuery();
    var IncID = query?.get("IncId");
    var NameID = query?.get("NameID");
    var MasterNameID = query?.get("MasterNameID");


    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    if (!NameID) NameID = 0;

    if (!MasterNameID) MasterNameID = 0;



    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
            dispatch(get_ScreenPermissions_Data("U117", localStoreData?.AgencyID, localStoreData?.PINID));
            dispatch(get_AgencyOfficer_Data(localStoreData?.AgencyID, ''));
        } 
    }, [localStoreData]);





    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
            // setPossessionID(e.value);
        } else {
            setValue({
                ...value,
                [name]: null
            });
            // setPossessionID('');
        }
    };



    const getNameSearch = async (CreatedDtTmfrom, CreatedDtTmTo, AgencyID , PrimaryOfficerID) => {
        if (CreatedDtTmfrom || CreatedDtTmTo || AgencyID) {
            fetchPostData("MasterName/GetData_ExpungeName", {
                'AgencyID': loginAgencyID,
                "CreatedDtTmfrom": CreatedDtTmfrom, "CreatedDtTmTo": CreatedDtTmTo,
                'PrimaryOfficerID' : PrimaryOfficerID
            }).then((data) => {
                if (data.length > 0) {
                    const [{ MasterNameID, NameIDNumber }] = data;
                    setNameSearchValue(data);
                    console.log(data);
                    setLogData([]);
                } else {
                    setNameSearchValue([]);
                     toastifyError('No Name Available');
                }
            });
        } else {
            toastifyError('No Data Available')
        }
    };


    const get_ArrestChargeData = (ArrestID) => {
        const val2 = { 'ArrestID': ArrestID };
        fetchPostData("Expunge/GetData_UnExpungArrestCharge", val2).then((res) => {
            if (res) {
                setArrestChareData(res);
            } else {
                setArrestChareData([]);
            }
        })
    }



    const get_DeletedDatas = () => {
        // const val2 = { 'NameID': nameId, 'MasterNameID': masterNameID, 'ExpungeHistoryID' : expungHistoryId , 'DeletedByUserFK' : loginPinID  };
        const val2 = { 'ArrestID': arrestId, 'ChargeID': ChargeID, 'DeletedByUserFK': loginPinID,  'ArrestNumber' : '' ,  'PrimaryOfficerID' : loginPinID , 'NameID' : nameId , 'MasterNameID' : masterNameID  };
        fetchPostData("MasterName/Unexpunge", val2).then((res) => {
            if (res) {
                if (res.data) {
                    try {
                        const parsedData = JSON.parse(res.data);
                        if (parsedData && parsedData.length > 0 && parsedData[0].Message) {
                            const deleteMessage = parsedData[0].Message;
                            console.log(res.data);
                            toastifySuccess(deleteMessage);
                            // getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, true);
                            // get_LogData(NameID);
                            get_ArrestChargeData(arrestId);
                            setNameID('');
                        } else {

                        }
                    } catch (error) {

                    }
                } else if (Array.isArray(res) && res.length > 0 && res[0].Message) {
                    const deleteMessage = res[0].Message;
                    getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, true);
                    toastifySuccess(deleteMessage);
                    console.log(deleteMessage);
                    setNameID('');
                } else {

                }
                console.log(res[0]?.message);
                toastifySuccess(res[0]?.message);
                get_ArrestChargeData(arrestId);
                // get_LogData(masterNameID);
            } else {
                setLogData([]);
            }
        }).catch(error => {

        });

    };




    const handleRowDeleted = (selectedRows) => {
        const selectedRow = selectedRows.selectedRows[0];
        if (selectedRows.selectedRows.length > 0) {
            if (selectedRows.selectedCount === logData.length) {

            }

            setSelectedRowData(selectedRow);
            // setStatesChangeStatus(true);
            // setSelectedRow(selectedRow);
            setTransactionName(selectedRows.selectedRows.map(row => row.TransactionName));
            setTransactionId(selectedRows.selectedRows.map(row => row.ID));
            // setTransactionNumber(selectedRows?.ArrestNumber);
            setArrestId(selectedRow?.ArrestID);
            setNameID(selectedRow.NameID);
            // setSelectionLocked(false);
            setArrestSelectCount(selectedRows.selectedRows)
            setselectionLockedArrest(true);
            setIsCheckboxChecked(true);

            // Fetch arrest charge data
            get_ArrestChargeData(selectedRow?.ArrestID);
        } else {
            // setSelectedRow(null);
            setNameID(null);
            // setStatesChangeStatus(false);
            setselectionLockedArrest(false);
            setArrestSelectCount(selectedRows.selectedRows);
            setArrestChareData([]);
            setExpandedRows([]);
            setIsCheckboxChecked(false);
        }
    };


    const reset = () => {
        setValue({
            ...value,
            'CreatedDtTmfrom': '', 'CreatedDtTmTo': '', 'AgencyID': '', 'PrimaryOfficerID': ''
        });
        setdateOfbirth('');
        setdateTo('');
        // setSelectionLocked(false);
        // setPrimaryNameSelect([]);
        setClearSelectedRows([]);
        setselectionLockedArrest(false);
        setSelectedRows('');
        setNameSearchValue([]);
    }




    const columns1 = [
        {
            name: 'Name Number',
            selector: (row) => row.NameIDNumber,
            sortable: true
        },
        {
            name: 'Transaction Name',
            selector: (row) => row.TransactionName,
            sortable: true
        },
        {
            name: 'Transaction Number',
            selector: (row) => row.TransactionNumber,
            sortable: true
        },
        {
            name: 'Date/Time',
            selector: (row) => getShowingDateText(row.ReportedDate),
            sortable: true
        },

    ]



    useEffect(() => {
        document.addEventListener('load', function () {
            document.getElementById('#myModal').modal('showModal');
        });
    }, [])



    const handleExpungeClick = () => {
        get_DeletedDatas();
    };





    const handleConfirmExpunge = () => {
        get_DeletedDatas();
        setShowModal(false);
        // setInitialExpungeDone(false);
        setClearSelectedRows(!clearSelectedRows);
    };

    useEffect(() => {
        document.addEventListener('load', function () {
            document.getElementById('#myModal').modal('showModal');
        });
    }, [])

    const onDashboardClose = () => {
        navigate('/dashboard-page');
    }



    const columns2 = [
        {
            name: 'NIBRS Code',
            selector: (row) => row.ChargeCodeID,
            sortable: true
        },
        {
            name: 'Offense Code / Description',
            selector: (row) => row.ChargeCode_Description,
            sortable: true
        },
        {
            name: 'Transaction Number',
            selector: (row) => row.TransactionNumber,
            sortable: true
        },
        {
            name: 'Date/Time',
            selector: (row) => getShowingDateText(row.ReportedDate),
            sortable: true
        },

    ]

    const ExpandedComponent = ({ data }) => (
        <DataTable
            dense
            columns={columns2}
            data={data}  // Make sure this is coming from 'arrestChareData'
            pagination
            selectableRows
            onSelectedRowsChange={handleRowSelected1}
            customStyles={tableCustomStyles}
            // selectableRowSelected={(row) => (selectedRows)?.some((selectedRow) => selectedRow.ChargeID === row.ChargeID)}
            selectableRowSelected={(row) => Array.isArray(selectedRows) && selectedRows.some((selectedRow) => selectedRow.ChargeID === row.ChargeID)}
            conditionalRowStyles={[
                { when: row => row?.SealUnsealStatus === true, style: { backgroundColor: '#FFCCCC', color: 'black' } }
            ]}
        />
    );

    const handleRowExpand = (rowId) => {
        setExpandedRows(prevExpandedRows =>
            prevExpandedRows.includes(rowId)
                ? prevExpandedRows.filter(id => id !== rowId) // Collapse
                : [...prevExpandedRows, rowId] // Expand
        );
    };



    const handleRowSelected1 = useCallback(
        debounce(({ selectedRows }) => {
            // Check if selectedRows have actually changed before updating state
            if (selectedRows.length > 0) {
                // If the selected rows are different from the previous ones, update the state

                const newPropertyIds = selectedRows?.map(row => row.ChargeID);
                const newTransactionNames = selectedRows?.map(row => row.TransactionName);
                const newTransactionIds = selectedRows?.map(row => row.ID);
                const ExpungeHistoryID = selectedRows?.map(row => row.ExpungeHistoryID);
                   console.log(ExpungeHistoryID);



                // Check if the selected rows have actually changed
                if (newPropertyIds.toString() !== ChargeID.toString()) {
                    setTransactionName(newTransactionNames);
                    setTransactionId(newTransactionIds);
                    console.log(selectedRows);
                    // setTransactionNumber(newTransactionNumbers);
                    setChargeID(newPropertyIds);
                    setSelectedRows(selectedRows);
                    setExpungeHistoryID(ExpungeHistoryID);
                }
            } else {
                setSelectedRows([]);
            }
        }, 1000),
        [ChargeID] // This ensures debounce logic compares the current chargeID to prevent unnecessary updates
    );



    return (
        <>
            <div className="section-body view_page_design pt-1">
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency  name-card">
                            <div className="card-body">
                                <div className="col-12 col-md-12 col-lg-12 ">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="row" style={{ marginTop: '-10px' }}>

                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>From Date</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3">
                                                <DatePicker
                                                    id='DateOfBirth'
                                                    name='CreatedDtTmfrom'
                                                    className=''
                                                    onChange={(date) => {
                                                        setdateOfbirth(date);
                                                        setValue({ ...value, ['CreatedDtTmfrom']: date ? getShowingMonthDateYear(date) : null });
                                                    }}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    isClearable={value?.CreatedDtTmfrom ? true : false}
                                                    selected={dateOfbirth}
                                                    placeholderText={value?.CreatedDtTmfrom ? value.DateOfBirth : 'Select...'}
                                                    maxDate={new Date()}
                                                    timeIntervals={1}
                                                    autoComplete="Off"
                                                />
                                            </div>

                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>To Date</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3">
                                                <DatePicker
                                                    id='DateOfBirth'
                                                    name='CreatedDtTmTo'
                                                    className=''
                                                    onChange={(date) => {
                                                        setdateTo(date);
                                                        setValue({ ...value, ['CreatedDtTmTo']: date ? getShowingMonthDateYear(date) : null });
                                                    }}
                                                    dateFormat="MM/dd/yyyy"
                                                    timeInputLabel
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    isClearable={value?.CreatedDtTmTo ? true : false}
                                                    selected={dateTo}
                                                    placeholderText={value?.CreatedDtTmTo ? value.DateOfBirth : 'Select...'}
                                                    maxDate={new Date()}
                                                    timeIntervals={1}
                                                    autoComplete="Off"
                                                />
                                            </div>

                                            <div className="col-2 col-md-2 col-lg-1 mt-2">
                                                <label htmlFor="" className='new-label'>Primary officer</label>
                                            </div>
                                            <div className="col-4 col-md-4 col-lg-3 mt-1">
                                                <Select
                                                    name='PrimaryOfficerID'
                                                    // styles={colourStyles}
                                                    menuPlacement='top'
                                                    value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.PrimaryOfficerID)}
                                                    isClearable
                                                    options={agencyOfficerDrpData}
                                                    onChange={(e) => ChangeDropDown(e, 'PrimaryOfficerID')}
                                                    placeholder="Select..."
                                                />
                                            </div>

                                            <div className="btn-box col-12 text-right mb-1 mt-3 d-flex justify-content-end">
                                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={() => { onDashboardClose(); }}>Close</button>
                                                {
                                                    effectiveScreenPermission ? effectiveScreenPermission[0]?.AddOK ?
                                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.CreatedDtTmfrom, value.CreatedDtTmTo, value.AgencyID, value.PrimaryOfficerID)}>Search</button>
                                                        : <></> :
                                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.CreatedDtTmfrom, value.CreatedDtTmTo, value.AgencyID , value.PrimaryOfficerID)}>Search</button>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <fieldset className='mt-2 mb-1'>
                                    <legend>Select Event To be Deleted</legend>
                                    <div className="col-12 mt-3 px-0">
                                        {/*<DataTable*/}
                                        {/*    dense*/}
                                        {/*    columns={columns2}*/}
                                        {/*    data={nameSearchValue} // Your log data*/}
                                        {/*    pagination*/}
                                        {/*    highlightOnHover*/}
                                        {/*    fixedHeaderScrollHeight="190px"*/}
                                        {/*    fixedHeader*/}
                                        {/*    onSelectedRowsChange={handleRowSelected}*/}
                                        {/*    persistTableHead={true}*/}
                                        {/*    customStyles={tableCustomStyles}*/}
                                        {/*    selectableRows*/}
                                        {/*    expandableRows*/}
                                        {/*    expandableRowsComponent={({ data }) =>*/}
                                        {/*        selectedRowData ? (*/}
                                        {/*            <ExpandedComponent data={selectedRowData} incidentID={selectedRowData.IncidentID} />*/}
                                        {/*        ) : null*/}
                                        {/*    }*/}
                                        {/*    expandableInheritConditionalStyles*/}
                                        {/*/>*/}
                                        <DataTable
                                            dense
                                            columns={columns1}
                                            data={nameSearchValue}
                                            pagination
                                            highlightOnHover
                                            fixedHeaderScrollHeight='100px'
                                            fixedHeader
                                            persistTableHead={true}
                                            customStyles={tableCustomStyles}
                                            selectableRows
                                            selectableRowsHighlight
                                            selectableRowsSingle
                                            onSelectedRowsChange={handleRowDeleted}
                                            clearSelectedRows={clearSelectedRows}
                                            // expandableRows={isCheckboxChecked}
                                            expandableRows={true}
                                            selectableRowDisabled={(row) => selectionLockedArrest && !arrestSelectCount?.includes(row)}
                                            expandableRowsComponent={({ data }) =>
                                                selectedRowData ? (
                                                    <ExpandedComponent data={arrestChareData} incidentID={selectedRowData.IncidentID} />
                                                ) : null
                                            }
                                            expandableRowExpanded={row => expandedRows.includes(row.ChargeID)}  // Control expansion state
                                            onRowExpandToggled={handleRowExpand}  // Handle expansion toggle
                                            expandableInheritConditionalStyles
                                        />
                                        {/* <DataTable
                                    dense
                                    columns={columns}
                                    data={nameSearchValue}
                                    pagination
                                    selectableRowsHighlight
                                    highlightOnHover
                                /> */}
                                    </div>
                                </fieldset>

                                <div className="col-12 field-button" style={{ position: 'absolute', bottom: '5px', textAlign: 'right' }} >
                                    <button type="button" data-toggle="modal" data-target="#myModal" onClick={() => { reset(); }} className="btn btn-sm btn-success mr-1">Clear</button>
                                    <button type="button" className="btn btn-sm btn-success mr-4" onClick={handleExpungeClick}
                                        {...(showModal && nameId ? { 'data-toggle': 'modal', 'data-target': '#myModal' } : {})}
                                    >UnExpunge</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ExpungeConfirmModel showModal={showModal}
                setShowModal={setShowModal}
                onConfirm={handleConfirmExpunge} />


        </>
    )
}

export default UnExpunge