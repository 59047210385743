import React, { memo, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import { requiredFieldColourStyles } from "../Utility/CustomStylesForReact";
import { useSelector } from "react-redux";
import useObjState from "../../CADHook/useObjState";
import { isEmpty } from "../../CADUtils/functions/common";
import GeoServices from "../../CADServices/APIs/geo";
import { IncidentContext } from "../../CADContext/Incident";


const CloseCallModal = (props) => {
    const { openCloseCallModal, setOpenCloseCallModal, setSelectedButton, getResourceValues, createPayload, isGoogleLocation, createLocationPayload, geoLocationID, setGeoLocationID, insertIncident, setNameData, setVehicleData, onCloseLocation } = props;
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const { incidentRefetch, resourceRefetch } = useContext(IncidentContext);
    const [isChangeFields, setIsChangeFields] = useState(false);
    const [loginAgencyID, setLoginAgencyID] = useState();
    const [loginPinID, setLoginPinID,] = useState('');
    const [dispositionDropDown, setDispositionDropDown] = useState([]);

    const [
        closeCallState,
        setCloseCallState,
        handleCloseCallState,
        clearCloseCallState,
    ] = useObjState({
        incidentID: "",
        callDisposition: "",
        reason: "",
    });

    const [
        errorCloseCall,
        _setErrorCloseCall,
        handleErrorCloseCall,
        clearStateCloseCall,
    ] = useObjState({
        callDisposition: false,
        reason: false,
    });


    const getDispositionskey = `/MasterIncidentDispositions/GetData_IncidentDispositions`;
    const { data: dispositionData, isSuccess: isFetchDispositionData } = useQuery(
        [getDispositionskey, {
            "IsActive": 1,
            "IsSuperAdmin": 1,
            "AgencyID": loginAgencyID,
            "PINID": loginPinID
        },],
        MasterTableListServices.getIncidentDispositions,
        {
            refetchOnWindowFocus: false,
            enabled: !!loginAgencyID && !!loginPinID && openCloseCallModal
        }
    );

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID)
            setLoginPinID(parseInt(localStoreData?.PINID));

        }
    }, [localStoreData]);


    useEffect(() => {
        if (isFetchDispositionData && dispositionData) {
            const data = JSON.parse(dispositionData?.data?.data);
            setDispositionDropDown(data?.Table)
        }
    }, [isFetchDispositionData, dispositionData]);




    const onCloseCallLocation = () => {
        setOpenCloseCallModal(false);
        setSelectedButton(prevSelected =>
            prevSelected.includes(6)
                ? prevSelected.filter(item => item !== 6)
                : [...prevSelected, 6]
        );
        setIsChangeFields(false);
        clearCloseCallState();
        clearStateCloseCall();
    };

    const validateGeoFormValues = () => {
        let isError = false;
        const keys = Object.keys(errorCloseCall);
        keys.map((field) => {
            if (
                field === "callDisposition" &&
                isEmpty(closeCallState[field])) {
                handleErrorCloseCall(field, true);
                isError = true;
            } else if (field === "reason" && isEmpty(closeCallState[field])) {
                handleErrorCloseCall(field, true);
                isError = true;
            } else {
                handleErrorCloseCall(field, false);
            }
        });
        return !isError;
    };


    async function handleSave() {
        if (!validateGeoFormValues()) return;
        const resourceValues = getResourceValues();
        let formData = createPayload(resourceValues);
        formData = {
            ...formData,
            CallDisposition: closeCallState?.callDisposition,
            ClosingReason: closeCallState?.reason,
        };

        if (isGoogleLocation) {
            const locationPayload = createLocationPayload();
            const response = await GeoServices.insertLocation(locationPayload);
            if (response?.data?.success) {
                if (!geoLocationID) {
                    const data = JSON.parse(response?.data?.data);
                    const newGeoLocationID = data?.Table[0]?.GeoLocationID;
                    setGeoLocationID(newGeoLocationID);
                    formData = createPayload(resourceValues, newGeoLocationID);
                    formData = {
                        ...formData,
                        CallDisposition: closeCallState?.callDisposition,
                        ClosingReason: closeCallState?.reason,
                    };
                }
                setIsChangeFields(false);
            }
            await new Promise((resolve) => setTimeout(resolve, 3000));
            await insertIncident(formData);
        } else {
            await insertIncident(formData);
        }
        incidentRefetch();
        resourceRefetch();
        setNameData([]);
        setVehicleData([]);
        onCloseLocation();
        onCloseCallLocation();
    }


    return (
        <>
            {openCloseCallModal ? (
                <>
                    <dialog
                        className="modal fade"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "300" }}
                        id="CloseCallModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 p-0 pb-2">
                                            <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                                <p
                                                    className="p-0 m-0 font-weight-medium"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 500,
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    Close Call
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>
                                            {/* Row 1 */}
                                            <div className="tab-form-container">
                                                {/* <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label className="tab-form-label">
                                                            CAD Event #
                                                        </label>
                                                    </div>
                                                    <div className="col-3 d-flex align-self-center justify-content-end">
                                                        <input
                                                            type="number"
                                                            className="form-control py-1 new-input"
                                                            name="PremiseNo"
                                                            value={closeCallState.incidentID}
                                                            onChange={(e) => handleCloseCallState("incidentID", e.target.value)}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label className="tab-form-label">
                                                            Call Disposition {errorCloseCall.callDisposition && (
                                                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Call Disposition"}</p>
                                                            )}
                                                        </label>
                                                    </div>
                                                    <div className="col-3 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            options={dispositionDropDown}
                                                            getOptionLabel={(v) => v?.Description}
                                                            getOptionValue={(v) => v?.IncidentDispositionsID}
                                                            styles={requiredFieldColourStyles}
                                                            className="w-100"
                                                            value={dispositionDropDown?.find((i) => i?.IncidentDispositionsID === closeCallState?.callDisposition)}
                                                            onChange={(e) => { handleCloseCallState("callDisposition", e?.IncidentDispositionsID); setIsChangeFields(true); }}
                                                            name="CADDisposition" />
                                                    </div>
                                                </div>
                                                <div className="tab-form-row">
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label className="tab-form-label">
                                                            Closing Reason {errorCloseCall.reason && (
                                                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Closing Reason"}</p>
                                                            )}
                                                        </label>
                                                    </div>
                                                    <div className="col-8 d-flex align-self-center justify-content-end">
                                                        <input
                                                            type="text"
                                                            className="form-control py-1 new-input requiredColor"
                                                            name="PremiseNo"
                                                            value={closeCallState.reason}
                                                            onChange={(e) => { handleCloseCallState("reason", e.target.value); setIsChangeFields(true); }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                    <button
                                                        type="button"
                                                        className="save-button ml-2"
                                                        disabled={!isChangeFields}
                                                        onClick={() => handleSave()}
                                                    >
                                                        {"Save"}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="cancel-button"
                                                        onClick={() => {
                                                            onCloseCallLocation();
                                                        }
                                                        }
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog >
                </>
            ) : (
                <> </>
            )
            }
        </>
    );
};

export default memo(CloseCallModal);
