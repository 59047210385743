import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import { useQuery } from 'react-query';
import { toastifyError, toastifySuccess } from '../../../Components/Common/AlertMsg';
import {
  compareStrings,
  isEmpty
} from "../../../CADUtils/functions/common";
import CADConfirmModal from '../../Common/CADConfirmModal';
import { useSelector } from 'react-redux';

const HospitalStatusCodeSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState('');
  const [loginPinID, setLoginPinID] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [confirmType, setConfirmType] = useState('');
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');

  const [
    hospitalStatusCodeState,
    setHospitalStatusCodeState,
    handleHospitalStatusCodeState,
    clearHospitalStatusCodeState,
  ] = useObjState({
    HospitalStatusID: "",
    hospitalCode: "",
    codeDesc: "",
    isActive: 1
  });

  const [
    errorHospitalStatusCodeState,
    _setErrorHospitalStatusCodeState,
    handleErrorHospitalStatusCodeState,
    clearStateHospitalStatusCodeState,
  ] = useObjState({
    hospitalCode: false,
    codeDesc: false,
  });

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
    }
  }, [localStoreData]);

  const getHospitalStatusCodeKey = `/CAD/MasterHospitalStatusCode/HospitalStatusCode`;
  const { data: getHospitalStatusCodeData, isSuccess: isFetchHospitalStatusCode, refetch, isError: isNoData } = useQuery(
    [getHospitalStatusCodeKey, { Action: "GET", isactive: parseInt(pageStatus) },],
    MasterTableListServices.getHospitalStatusCode,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  useEffect(() => {
    if (getHospitalStatusCodeData && isFetchHospitalStatusCode) {
      const data = JSON.parse(getHospitalStatusCodeData?.data?.data);
      setFilterListData(data?.Table)
      setListData(data?.Table)
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [getHospitalStatusCodeData, isFetchHospitalStatusCode])

  function onCancel() {
    clearHospitalStatusCodeState();
    clearStateHospitalStatusCodeState();
  }
  async function handelActiveInactive() {
    const data = {
      HospitalStatusID: activeInactiveData?.HospitalStatusID,
      DeletedByUserFK: loginPinID,
      IsActive: isActive,
    }
    const response = await MasterTableListServices.changeStatusHospitalStatusCode(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      refetch();
    }
    setShowModal(false);
    onCancel();
  }
  const validateCFSCodeForm = () => {
    let isError = false;
    const keys = Object.keys(errorHospitalStatusCodeState);
    keys.map((field) => {
      if (
        field === "hospitalCode" &&
        isEmpty(hospitalStatusCodeState[field])) {
        handleErrorHospitalStatusCodeState(field, true);
        isError = true;
      } else if (field === "codeDesc" && isEmpty(hospitalStatusCodeState[field])) {
        handleErrorHospitalStatusCodeState(field, true);
        isError = true;
      } else {
        handleErrorHospitalStatusCodeState(field, false);
      }
    });
    return !isError;
  };
  async function handelSave() {
    if (!validateCFSCodeForm()) return
    const isUpdate = !!hospitalStatusCodeState?.HospitalStatusID;

    const result = listData?.find(item => {
      if (item.hospitalstatuscode) {
        const code = hospitalStatusCodeState?.hospitalCode?.toLowerCase();
        return code && item.hospitalstatuscode.toLowerCase() === code;
      }
      return false;
    });
    const result1 = listData?.find(item => {
      if (item.codedescription) {
        const description = hospitalStatusCodeState?.codeDesc?.toLowerCase();
        return description && item.codedescription.toLowerCase() === description;
      }
      return false;
    });
    if ((result || result1) && !isUpdate) {
      if (result) {
        toastifyError('Code Already Exists');
      }
      if (result1) {
        toastifyError('Description Already Exists');
      }
    } else {
      const data = {
        Action: isUpdate ? "UPDATE" : "ADD",
        HospitalStatusID: isUpdate ? hospitalStatusCodeState?.HospitalStatusID : undefined,
        hospitalstatuscode: hospitalStatusCodeState?.hospitalCode,
        codedescription: hospitalStatusCodeState?.codeDesc,
        isactive: 1
      }
      const response = await MasterTableListServices.insertHospitalStatusCode(data);
      if (response?.status === 200) {
        toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
        onCancel()
        refetch();
      }
    }
  }

  function handelSetEditData(data) {
    setHospitalStatusCodeState({
      HospitalStatusID: data?.HospitalStatusID || "",
      hospitalCode: data?.hospitalstatuscode || "",
      codeDesc: data?.codedescription || "",
      isActive: data?.IsActive ? 1 : 0
    })
  }

  const conditionalRowStyles = [
    {
      when: row => row?.HospitalStatusID === hospitalStatusCodeState?.HospitalStatusID,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#001f3fbd',
          color: 'white',
        },
      },
    }
  ];

  const columns = [
    {
      name: 'Hospital Status Code',
      selector: row => row.hospitalstatuscode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.hospitalstatuscode, rowB.hospitalstatuscode),
      style: {
        position: "static",
      },
    },
    {
      name: 'Code Description',
      selector: row => row.codedescription,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.codedescription, rowB.codedescription),
      width: "60%",
      style: {
        position: "static",
      },
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.isactive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive"); onCancel() }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); onCancel() }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },
  ];

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); onCancel() }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); onCancel() }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Hospital Status Code {errorHospitalStatusCodeState.hospitalCode && isEmpty(hospitalStatusCodeState?.hospitalCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Hospital Status Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          name="hospitalCode"
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Hospital Status Code'
                          value={hospitalStatusCodeState?.hospitalCode}
                          onChange={(e) => handleHospitalStatusCodeState("hospitalCode", e.target.value)}
                        />
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Code Description {errorHospitalStatusCodeState.codeDesc && isEmpty(hospitalStatusCodeState?.codeDesc) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Code Description"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          name="codeDesc"
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Code Description'
                          value={hospitalStatusCodeState?.codeDesc}
                          onChange={(e) => handleHospitalStatusCodeState("codeDesc", e.target.value)}

                        />
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row mb-2">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder='Search By Code...'
                value={searchValue1}
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'hospitalstatuscode', 'codedescription')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder='Search By Description...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'hospitalstatuscode', 'codedescription')
                  setFilterListData(result)
                }}
              />
            </div>

            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={filterListData}
              customStyles={tableCustomStyles}
              pagination
              conditionalRowStyles={conditionalRowStyles}
              responsive
              striped
              highlightOnHover
              noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
              fixedHeader
              onRowClicked={(row) => {
                handelSetEditData(row);
              }}
            />
          </div>
          <div className="utilities-tab-content-button-container bb pb-2" >
            <button type="button" className="btn btn-sm btn-success" onClick={() => onCancel()}>Cancel</button>
            <button type="button" className="btn btn-sm btn-success" onClick={() => handelSave()}>{hospitalStatusCodeState?.HospitalStatusID ? "Update" : "Save"}</button>
          </div>
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  );
};

export default HospitalStatusCodeSection;