export const dropDownDataModel = (data, value, label) => {
  const result = data?.map((item) => ({
    value: item[value],
    label: item[label],
  }));
  return result;
};

// Allow Text and Number with space
export const handleNumberTextKeyDown = (e) => {
  const regex = /^[a-zA-Z0-9\s]$/;

  // Allow control keys such as Backspace, Delete, Tab, Enter, and arrow keys
  const controlKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Enter",
    "Escape",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];

  // If the key does not match the regex and is not a control key, prevent the input
  if (!regex.test(e.key) && !controlKeys.includes(e.key)) {
    e.preventDefault();
  }
};

// Allow Text and Number with no space
export const handleNumberTextNoSpaceKeyDown = (e) => {
  const regex = /^[a-zA-Z0-9]$/; // Allow only letters and numbers (no spaces)

  // Allow control keys such as Backspace, Delete, Tab, Enter, and arrow keys
  const controlKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Enter",
    "Escape",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];

  // If the key does not match the regex and is not a control key, prevent the input
  if (!regex.test(e.key) && !controlKeys.includes(e.key)) {
    e.preventDefault();
  }
};

// Allow Number only with no space
export const handleNumberNoSpaceKeyDown = (e) => {
  const regex = /^[0-9]$/;

  // Allow control keys such as Backspace, Delete, Tab, Enter, and arrow keys
  const controlKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Enter",
    "Escape",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];

  // If the key does not match the regex and is not a control key, prevent the input
  if (!regex.test(e.key) && !controlKeys.includes(e.key)) {
    e.preventDefault();
  }
};

// Allow Text only with no space
export const handleTextNoSpaceKeyDown = (e) => {
  // Allow only alphabetic characters (a-z, A-Z)
  const regex = /^[a-zA-Z]$/;

  // Allow control keys such as Backspace, Delete, Tab, Enter, and arrow keys
  const controlKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Enter",
    "Escape",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];

  // If the key does not match the regex and is not a control key, prevent the input
  if (!regex.test(e.key) && !controlKeys.includes(e.key)) {
    e.preventDefault();
  }
};

// Allow Text only with space
export const handleTextKeyDown = (e) => {
  const regex = /^[a-zA-Z\s]$/;

  // Allow control keys such as Backspace, Delete, Tab, Enter, and arrow keys
  const controlKeys = [
    "Backspace",
    "Delete",
    "Tab",
    "Enter",
    "Escape",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];

  // If the key does not match the regex and is not a control key, prevent the input
  if (!regex.test(e.key) && !controlKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export function isEmptyObject(obj) {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
}

export function isNotEmpty(str) {
  const pattern = /\S+/;
  return pattern.test(str); // returns a boolean
}

export function isEmpty(str) {
  return !isNotEmpty(str) || str === undefined;
}

// Function to compare strings for table sorting
export function compareStrings(a, b) {
  const resourceA = a ? a.toString().toLowerCase() : '';
  const resourceB = b ? b.toString().toLowerCase() : '';
  return resourceA.localeCompare(resourceB);
};