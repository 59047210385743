import React, {useCallback, useState} from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import DataTable from 'react-data-table-component';
import { base64ToString, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, stringToBase64, tableCustomStyles } from '../../Common/Utility';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useContext } from 'react'
import { Comman_changeArrayFormat } from '../../Common/ChangeArrayFormat';
import { fetchPostData } from '../../hooks/Api';

import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmModal from '../Arrest/ConfirmModal';
import ExpungeConfirmModel from '../../Common/ExpungeConfirmModel';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';
import { get_ScreenPermissions_Data } from '../../../redux/actions/IncidentAction';
import { debounce } from 'lodash';

const Expunge = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);

    const [loginAgencyID, setloginAgencyID] = useState('');
    const [ethinicityDrpData, setEthinicityDrpData] = useState([]);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [raceIdDrp, setRaceIdDrp] = useState([]);
    const [dateOfbirth, setdateOfbirth] = useState();
    const [nameSearchValue, setNameSearchValue] = useState([]);
    const [logData, setLogData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loginPinID, setLoginPinID,] = useState('');
    const [nameId, setNameID] = useState('');
    const [masterNameID, setMasterNameID] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [initialExpungeDone, setInitialExpungeDone] = useState(false);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [notes, setNotes] = useState('');
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);
    const [transactionName, setTransactionName] = useState([]);
    const [transactionId, setTransactionId] = useState([]);
    const [transactionNumber, setTransactionNumber] = useState([]);
    const [selectionLocked, setSelectionLocked] = useState(false);
    const [selectionLockedArrest, setselectionLockedArrest] = useState(false);
    const [primaryNameSelectCount, setPrimaryNameSelect] = useState([]);
    const [arrestSelectCount, setArrestSelectCount] = useState([]);
    const [arrestChareData, setArrestChareData] = useState([]);
    const [arrestId ,setArrestId] = useState('');
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [ChargeID, setChargeID] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);



    const [value, setValue] = useState({
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'DateOfBirth': '', 'SexID': '', 'RaceID': '', 'EthnicityID': '',
    })

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DeNameID = 0, DeMasterNameID = 0

    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    let openPage = query?.get('page');
    var NameID = query?.get("NameID");
    var NameStatus = query?.get("NameStatus");
    var MasterNameID = query?.get("MasterNameID");
    let MstPage = query?.get('page');

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    if (!NameID) NameID = 0;
    else DeNameID = parseInt(base64ToString(NameID));
    if (!MasterNameID) MasterNameID = 0;
    else DeMasterNameID = parseInt(base64ToString(MasterNameID));


    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
            dispatch(get_ScreenPermissions_Data("U117", localStoreData?.AgencyID, localStoreData?.PINID));

        }
    }, [localStoreData]);

    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     if (event) {
    //         setValue((prevState) => ({ ...prevState, [name]: value, }));
    //     }
    //     else {
    //         setValue((prevState) => ({ ...prevState, [name]: null, }));
    //     }
    // };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'SSN') {
            let ele = value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue((prevState) => ({
                        ...prevState,
                        [name]: match[1] + '-' + match[2] + '-' + match[3],
                    }));
                }
            } else {
                ele = value.split('-').join('').replace(/\D/g, '');
                setValue((prevState) => ({
                    ...prevState,
                    [name]: ele,
                }));
            }
        } else {
            if (event) {
                setValue((prevState) => ({ ...prevState, [name]: value }));
            } else {
                setValue((prevState) => ({ ...prevState, [name]: null }));
            }
        }
    };

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
            // setPossessionID(e.value);
        } else {
            setValue({
                ...value,
                [name]: null
            });
            // setPossessionID('');
        }
    };

    useEffect(() => {
        if (loginAgencyID) {

            get_Name_Drp_Data(loginAgencyID)
        }


    }, [loginAgencyID])

    const get_Name_Drp_Data = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('MasterName/GetNameDropDown', val).then((data) => {
            if (data) {

                setEthinicityDrpData(Comman_changeArrayFormat(data[0]?.Ethnicity, 'EthnicityID', 'Description'));
                setSexIdDrp(Comman_changeArrayFormat(data[0]?.Gender, 'SexCodeID', 'Description'));

                setRaceIdDrp(Comman_changeArrayFormat(data[0]?.Race, 'RaceTypeID', 'Description'));

            } else {
                setEthinicityDrpData([]); setSexIdDrp([]); setRaceIdDrp([]);

            }
        })
    };



    const getNameSearch = async (LastName, FirstName, MiddleName, DateOfBirth, SSN, Gender, type) => {
        if (LastName || DateOfBirth || FirstName || MiddleName || SSN || Gender || value.RaceID || value.EthnicityID) {
            fetchPostData("MasterName/Search_Name", {
                "NameTypeID": "", "LastName": LastName, "FirstName": FirstName || null, "MiddleName": MiddleName || null, "SSN": SSN || null,
                'AgencyID': loginAgencyID || null, "NameIDNumber": "", "NameReasonCodeID": "", "SuffixID": "",
                "DateOfBirthFrom": '', "DateOfBirthTo": "", "SexID": Gender, "RaceID": value.RaceID, "EthnicityID": value.EthnicityID, "HairColorID": "",
                "EyeColorID": "", "WeightFrom": "", "WeightTo": "", "SMTTypeID": "", "SMTLocationID": "", "SMT_Description": "", "IncidentNumber": "", "IncidentNumberTo": "",
                "ReportedDate": "", "ReportedDateTo": "", "DateOfBirth": DateOfBirth, "HeightFrom": "", "HeightTo": ""
            }).then((data) => {
                if (data.length > 0) {
                    const [{ MasterNameID, NameIDNumber }] = data;
                    setNameSearchValue(data);
                    setLogData([]);
                } else {
                    setNameSearchValue([]);
                    if (type) toastifyError('No Name Available');
                }
            });
        } else {
            toastifyError('No Data Available')
        }
    };


    const get_LogData = (MasterNameID) => {
        const val2 = { 'NameID': 0, 'MasterNameID': MasterNameID, 'IsMaster': true }
        fetchPostData("Expunge/GetData_ExpungArrest", val2).then((res) => {
            if (res?.length > 0) {
                console.log(res);
                setLogData(res);
            } else {
                // console.log('hello');
                // toastifyError('No Data Available')
            }
        })
    }

    console.log(ChargeID)

    const get_DeletedData = () => {
        // const val2 = { 'NameID': nameId, 'MasterNameID': 0, 'DeletedByUserFK': loginPinID, "Notes": notes, 'TransactionType': transactionName, 'ID': transactionId , 'TransactionNumber' : transactionNumber , 'PrimaryOfficerID' : loginPinID };
        const val2 = { 'ArrestID': arrestId, 'ChargeID': ChargeID, 'DeletedByUserFK': loginPinID, "Notes": notes, 'ArrestNumber' : '' , 'TransactionType': transactionName, 'ID': transactionId , 'TransactionNumber' : transactionNumber , 'PrimaryOfficerID' : loginPinID };
        fetchPostData("MasterName/Delete_Name", val2).then((res) => {
            if (res) {
                if (res.data) {
                    try {
                        const parsedData = JSON.parse(res.data);
                        if (parsedData && parsedData.length > 0 && parsedData[0].Message) {
                            const deleteMessage = parsedData[0].Message;
                            toastifySuccess(deleteMessage);
                            // get_LogData(NameID);
                            get_ArrestChargeData(arrestId);
                            setNameID('');
                        } else {

                        }
                    } catch (error) {

                    }
                } else if (Array.isArray(res) && res.length > 0 && res[0].Message) {
                    const deleteMessage = res[0].Message;
                    toastifySuccess(deleteMessage);
                    setNameID('');
                } else {

                }
                // get_LogData(masterNameID);
                get_ArrestChargeData(arrestId);
            } else {
                setLogData([]);
            }
        }).catch(error => {

        });

    };

    const get_DeletedDatas = () => {
        const val2 = { 'NameID': 0, 'MasterNameID': masterNameID, 'DeletedByUserFK': loginPinID, "Notes": notes };
        fetchPostData("MasterName/Delete_Name", val2).then((res) => {
            if (res) {
                if (res.data) {
                    try {
                        const parsedData = JSON.parse(res.data);
                        if (parsedData && parsedData.length > 0 && parsedData[0].Message) {
                            const deleteMessage = parsedData[0].Message;
                            toastifySuccess(deleteMessage);
                            // getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, true);
                            get_LogData(NameID);
                            setNameID('');
                        } else {

                        }
                    } catch (error) {

                    }
                } else if (Array.isArray(res) && res.length > 0 && res[0].Message) {
                    const deleteMessage = res[0].Message;
                    getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, true);
                    toastifySuccess(deleteMessage);
                    setNameID('');
                } else {

                }
                get_LogData(masterNameID);
            } else {
                setLogData([]);
            }
        }).catch(error => {

        });

    };

    const handleRowSelected = (selectedRows) => {
        if (selectedRows.selectedRows.length > 0) {
            const selectedRow = selectedRows.selectedRows[0];
            setPrimaryNameSelect(selectedRows.selectedRows)
            setSelectedRow(selectedRow);
            setMasterNameID(selectedRow.MasterNameID);
            get_LogData(selectedRow.MasterNameID);
            setStatesChangeStatus(true);
            setSelectionLocked(true);
            setClearSelectedRows([]);

        } else {
            setSelectedRow(null);
            setMasterNameID(null);
            setLogData([]);
            // get_LogData('');
             setStatesChangeStatus(false);
            setSelectionLocked(false);
            setPrimaryNameSelect(selectedRows.selectedRows)
            setClearSelectedRows([]);
        }
    };

    const get_ArrestChargeData = (ArrestID) => {
        const val2 = { 'ArrestID': ArrestID };
        fetchPostData("Expunge/GetData_ExpungArrestCharge", val2).then((res) => {
            if (res) {
                console.log(res);
                setArrestChareData(res); // This will update arrestChareData and trigger a re-render
            } else {
                setArrestChareData([]); // If no data found, set to empty array
            }
        })
    }

    const columns2 = [
        {
            name: 'NIBRS Code',
            selector: (row) => row.ChargeCodeID,
            sortable: true
        },
        {
            name: 'Offense Code / Description',
            selector: (row) => row.ChargeCode_Description,
            sortable: true
        },
        {
            name: 'Transaction Number',
            selector: (row) => row.TransactionNumber,
            sortable: true
        },
        {
            name: 'Date/Time',
            selector: (row) => getShowingDateText(row.ReportedDate),
            sortable: true
        },

    ]

    const ExpandedComponent = ({ data }) => (
        <DataTable
            dense
            columns={columns2}
            data={data}  // Make sure this is coming from 'arrestChareData'
            pagination
            selectableRows
            onSelectedRowsChange={handleRowSelected1}
            customStyles={tableCustomStyles}
            // selectableRowSelected={(row) => (selectedRows)?.some((selectedRow) => selectedRow.ChargeID === row.ChargeID)}
            selectableRowSelected={(row) => Array.isArray(selectedRows) && selectedRows.some((selectedRow) => selectedRow.ChargeID === row.ChargeID)}
            conditionalRowStyles={[
                { when: row => row?.SealUnsealStatus === true, style: { backgroundColor: '#FFCCCC', color: 'black' } }
            ]}
        />
    );

    const handleRowExpand = (rowId) => {
        setExpandedRows(prevExpandedRows =>
            prevExpandedRows.includes(rowId)
                ? prevExpandedRows.filter(id => id !== rowId) // Collapse
                : [...prevExpandedRows, rowId] // Expand
        );
    };



    const handleRowSelected1 = useCallback(
        debounce(({ selectedRows }) => {
            // Check if selectedRows have actually changed before updating state
            if (selectedRows.length > 0) {
                // If the selected rows are different from the previous ones, update the state

                const newPropertyIds = selectedRows?.map(row => row.ChargeID);
                const newTransactionNames = selectedRows?.map(row => row.TransactionName);
                const newTransactionIds = selectedRows?.map(row => row.ID);
                const newTransactionNumbers = selectedRows?.map(row => row.TransactionNumber);

                // Check if the selected rows have actually changed
                if (newPropertyIds.toString() !== ChargeID.toString()) {
                    setTransactionName(newTransactionNames);
                    setTransactionId(newTransactionIds);
                    setTransactionNumber(newTransactionNumbers);
                    setChargeID(newPropertyIds);
                    setSelectedRows(selectedRows);
                }
            } else {
                setSelectedRows([]);
            }
        }, 1000),
        [ChargeID] // This ensures debounce logic compares the current chargeID to prevent unnecessary updates
    );



    const handleRowDeleted = (selectedRows) => {
        const selectedRow = selectedRows.selectedRows[0];
        if (selectedRows.selectedRows.length > 0) {
            if (selectedRows.selectedCount === logData.length) {
                setInitialExpungeDone('');
            }
            console.log(selectedRows);
            setSelectedRowData(selectedRow);
            setStatesChangeStatus(true);
            setSelectedRow(selectedRow);
            setTransactionName(selectedRows.selectedRows.map(row => row.TransactionName));
            setTransactionId(selectedRows.selectedRows.map(row => row.ID));
            setTransactionNumber(selectedRows?.ArrestNumber);
            setArrestId(selectedRow?.ArrestID);
            setNameID(selectedRow.NameID);
            // setSelectionLocked(false);
            setArrestSelectCount(selectedRows.selectedRows)
            setselectionLockedArrest(true);

            // Fetch arrest charge data
            get_ArrestChargeData(selectedRow?.ArrestID);
        } else {
            setSelectedRow(null);
            setNameID(null);
            setStatesChangeStatus(false);
            setselectionLockedArrest(false);
            setArrestSelectCount(selectedRows.selectedRows);
            setArrestChareData([]);
            setExpandedRows([]);
        }
    };



    const reset = () => {
        setValue({
            ...value,
            'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'DateOfBirth': '', 'SexID': '', 'RaceID': '', 'EthnicityID': '',
        });
        setdateOfbirth("");
        setNameSearchValue([]);
        setLogData([]);
        setShowModal('');
        setSelectedRow('');
        setInitialExpungeDone('');
        setNotes('');
        setStatesChangeStatus(false);
        setSelectionLocked(false);
        setPrimaryNameSelect([]);
        setClearSelectedRows([]);
        setselectionLockedArrest(false);
        setSelectedRows('');

    }


    const columns = [
        {
            name: 'Name Number',
            selector: (row) => row.NameIDNumber,
            sortable: true
        },
        {
            name: 'Last Name',
            selector: (row) => row.LastName,
            sortable: true
        },
        {
            name: 'First Name',
            selector: (row) => row.FirstName,
            sortable: true
        },
        {
            name: 'Middle Name',
            selector: (row) => row.MiddleName,
            sortable: true
        },
        {
            name: 'Gender',
            selector: (row) => row.Gender_Description,
            sortable: true
        },
        {
            name: 'DOB',
            selector: (row) => row.DateOfBirth ? getShowingWithOutTime(row.DateOfBirth) : " ",

            sortable: true
        },
        {
            name: 'Race',
            selector: (row) => row.Race_Description,
            sortable: true
        },
        {
            name: 'SSN',
            selector: (row) => row.SSN,
            sortable: true
        },

    ]
    const columns1 = [
        {
            name: 'MNI',
            selector: (row) => row.ArrestNumber,
            sortable: true
        },
        {
            name: 'Transaction Name',
            selector: (row) => row.TransactionName,
            sortable: true
        },
        {
            name: 'Transaction Number',
            selector: (row) => row.TransactionNumber,
            sortable: true
        },
        {
            name: 'Date/Time',
            selector: (row) => getShowingDateText(row.ReportedDate),
            sortable: true
        },

    ]








    useEffect(() => {
        document.addEventListener('load', function () {
            document.getElementById('#myModal').modal('showModal');
        });
    }, [])

    const handleExpungeClick = () => {
        if (!initialExpungeDone) {
            if (!notes.trim()) {
                toastifyError("Notes required");
                return;
            }
             else if (arrestId) {
                get_DeletedData(); setShowModal(true); setNotes('');  setClearSelectedRows([]);  }

            // setInitialExpungeDone(true);
            //  setNotes('');
        } else {
            // setShowModal(true);
        }
    };

    const handleConfirmExpunge = () => {
        get_DeletedDatas();
        setShowModal(false);
        setInitialExpungeDone(false);
        setClearSelectedRows(!clearSelectedRows);
    };

    useEffect(() => {
        document.addEventListener('load', function () {
            document.getElementById('#myModal').modal('showModal');
        });
    }, [])

    const onDashboardClose = () => {
        navigate('/dashboard-page');
    }


    return (
        <>
            <div className="section-body view_page_design pt-1">
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency  name-card">
                            <div className="card-body">
                                <div className="col-12 col-md-12 col-lg-12 ">
                                    <div className="row " style={{ marginTop: '-10px' }}>
                                        <div className="col-2 col-md-2 col-lg-1  mt-2">
                                            <label htmlFor="" className='new-label  '>Last Name</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3  text-field mt-1">
                                            <input type="text" name='LastName' autoComplete='off' value={value.LastName} onChange={(e) => { handleChange(e) }} />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1  mt-2">
                                            <label htmlFor="" className='new-label '>First Name</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                            <input type="text" name='FirstName' autoComplete='off' value={value.FirstName} onChange={(e) => { handleChange(e) }} />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1  mt-2 px-0">
                                            <label htmlFor="" className='new-label px-0'>Middle&nbsp;Name</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                            <input type="text" name='MiddleName' autoComplete='off' value={value.MiddleName} onChange={(e) => { handleChange(e) }} />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Gender</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-1">
                                            <Select
                                                // styles={isAdult ? colourStyles : customStylesWithOutColor}
                                                name='SexID'
                                                value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                                                options={sexIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                isClearable
                                                placeholder="Select..."
                                            // isDisabled={nameTypeCode === "B" ? true : false}
                                            />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 mt-2">
                                            <label htmlFor="" className='new-label'>DOB</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 ">
                                            <DatePicker
                                                id='DateOfBirth'
                                                name='DateOfBirth'
                                                className=''
                                                onChange={(date) => {
                                                    setdateOfbirth(date); setValue({ ...value, ['DateOfBirth']: date ? getShowingMonthDateYear(date) : null, });
                                                }}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                showYearDropdown
                                                showMonthDropdown
                                                dropdownMode="select"
                                                isClearable={value?.DateOfBirth ? true : false}
                                                selected={dateOfbirth}
                                                placeholderText={value?.DateOfBirth ? value.DateOfBirth : 'Select...'}
                                                maxDate={new Date()}
                                                // showTimeSelect
                                                timeIntervals={1}
                                                // timeCaption="Time"
                                                autoComplete="Off"

                                            />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1  mt-2">
                                            <label htmlFor="" className='new-label '>SSN</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                            <input type="text" name='SSN' autoComplete='off' value={value.SSN} maxLength={9} onChange={(e) => { handleChange(e) }} />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Race</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-1">
                                            <Select
                                                name='RaceID'
                                                value={raceIdDrp?.filter((obj) => obj.value === value?.RaceID)}
                                                options={raceIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'RaceID')}
                                                isClearable
                                                placeholder="Select..."
                                            // isDisabled={nameTypeCode === "B" ? true : false}
                                            // styles={isAdult ? colourStyles : customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Ethnicity</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-1">
                                            <Select
                                                name='EthnicityID'
                                                value={ethinicityDrpData?.filter((obj) => obj.value === value?.EthnicityID)}
                                                options={ethinicityDrpData}
                                                onChange={(e) => ChangeDropDown(e, 'EthnicityID')}
                                                isClearable
                                                placeholder="Select..."
                                            // styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        {/* <div className="btn-box col-4  text-right   mb-1 mt-3" >
                                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, value.RaceID, value.EthnicityID, true)}>Search</button>
                                            <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={onDashboardClose} >Close</button>
                                        </div> */}
                                        <div className="btn-box col-4  text-right   mb-1 mt-3">
                                            {
                                                effectiveScreenPermission ? effectiveScreenPermission[0]?.AddOK ?
                                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, value.RaceID, value.EthnicityID, true)}>Search</button>
                                                    : <></> :
                                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, value.RaceID, value.EthnicityID, true)}>Search</button>
                                            }
                                            <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={onDashboardClose} >Close</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-1">
                                    <DataTable
                                        dense
                                        columns={columns}
                                        data={nameSearchValue}
                                        pagination
                                        highlightOnHover
                                        fixedHeaderScrollHeight='120px'
                                        fixedHeader
                                        persistTableHead={true}
                                        customStyles={tableCustomStyles}
                                        // selectableRows="single"
                                        selectableRowsHighlight
                                        onSelectedRowsChange={handleRowSelected}
                                        selectableRowDisabled={(row) => selectionLockedArrest && !primaryNameSelectCount?.includes(row)}
                                         selectableRows
                                        selectableRowsSingle
                                        //   headerCheckboxAll={false}
                                    />
                                    
                                </div>
                                <fieldset className='mt-2 mb-1'>
                                    <legend>Select Event To be Deleted</legend>
                                    <div className="col-12 mt-3 px-0">
                                        <DataTable
                                            dense
                                            columns={columns1}
                                            data={logData}
                                            pagination
                                            highlightOnHover
                                            fixedHeaderScrollHeight='100px'
                                            fixedHeader
                                            persistTableHead={true}
                                            customStyles={tableCustomStyles}
                                            selectableRows
                                            selectableRowsHighlight
                                            onSelectedRowsChange={handleRowDeleted}
                                            clearSelectedRows={clearSelectedRows}
                                            expandableRows={true}  // Make sure rows are expandable
                                            selectableRowDisabled={(row) => selectionLockedArrest && !arrestSelectCount?.includes(row)}
                                            expandableRowsComponent={({ data }) =>
                                                selectedRowData ? (
                                                    <ExpandedComponent data={arrestChareData} incidentID={selectedRowData.IncidentID} />
                                                ) : null
                                            }
                                            expandableRowExpanded={row => expandedRows.includes(row.ChargeID)}  // Control expansion state
                                            onRowExpandToggled={handleRowExpand}  // Handle expansion toggle
                                            expandableInheritConditionalStyles
                                        />


                                        {/* <DataTable
                                    dense
                                    columns={columns}
                                    data={nameSearchValue}
                                    pagination
                                    selectableRowsHighlight
                                    highlightOnHover
                                /> */}
                                    </div>
                                </fieldset>
                                <div className="col-12  bt">
                                    <div className="row">
                                        <div className="col-2 col-md-2 col-lg-1 mt-3">
                                            <label htmlFor="" className='label-name '>Notes</label>
                                        </div>
                                        <div className="col-10 col-md-10 col-lg-11 text-field mt-1" >
                                            <textarea name='Notes' id="Notes" value={notes} onChange={(e) => { setNotes(e.target.value) }} cols="30" rows='1' className="form-control pt-2 pb-2 requiredColor" style={{ resize: "none" }} ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 field-button" style={{ position: 'absolute', bottom: '5px', textAlign: 'right' }} >
                                    <button type="button" className="btn btn-sm btn-success mr-1" disabled={!ChargeID} onClick={handleExpungeClick}
                                        {...(showModal && arrestId ? { 'data-toggle': 'modal', 'data-target': '#myModal' } : {})}
                                    >Expunge</button>
                                    <button type="button" data-toggle="modal" data-target="#myModal" onClick={() => { reset(); }} className="btn btn-sm btn-success mr-4">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ExpungeConfirmModel showModal={showModal}
                setShowModal={setShowModal}
                onConfirm={handleConfirmExpunge} />


        </>
    )
}

export default Expunge