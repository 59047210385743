import React, { useContext, useEffect, useRef, useState, } from 'react';
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import SelectBox from '../../../../Common/SelectBox';
import { Comman_changeArrayFormat, Comman_changeArrayFormatBasicInfo, Comman_changeArrayFormatMethodOfOperation, threeColArray, threeColArrayWithCode } from '../../../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import Select from "react-select";
import { Bias_90C_Error, check_GangCrime_CrimeCode, check_Valid_Bias_Code, checkCrimeActiSuitableCode, checkCriminalActivityIsRequire, checkMethodOfEntryIsRequire, checkWeaponTypeIsRequire, checkWeaponTypeValidate, CrimeActivitySelectNoneError, CrimeActivitySelectSuitableCodesError, ErrorStyle_CriminalActivity, ErrorStyle_NIBRS_09C, ErrorStyleOffenderUse, ErrorStyleWeapon, ErrorTooltip, HomicideOffenseUnknowError, MethodOFEntryMandataryError, NotApplicableError, OffenderUseError_N, OffenderUseError_Other, ValidateBiasCodeError } from '../ErrorNibrs';
import { Link, Navigate } from 'react-router-dom';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import OffListing from '../../../ShowAllList/OffListing';
import ListModal from '../../../Utility/ListManagementModel/ListModal';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { useDispatch, useSelector } from 'react-redux';
import { Decrypt_Id_Name } from '../../../../Common/Utility';

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <p className='ml-2 d-inline'>{props.label}</p>
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const BasicInformation = ({ ListData, offenceID, nibrsCode, setNibrsCode }) => {

  const SelectedValue = useRef();
  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const loginAgencyState = useSelector((state) => state.Ip.loginAgencyState);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

  // DropDown Value
  const [pretentedDrp, setPretentedDrp] = useState([]);
  const [pointExitDrp, setPointExitDrp] = useState([]);
  const [pointEntryDrp, setPointEntryDrp] = useState([]);
  const [crimeOffenderUseDrp, setCrimeOffenderUseDrp] = useState([]);
  const [crimeActivityDrp, setCrimeActivityDrp] = useState([]);
  const [crimeBiasCategoryDrp, setCrimeBiasCategoryDrp] = useState([]);
  const [toolsUseIDDrp, setToolsUseIDDrp] = useState([]);
  const [crimeTargetDrp, setCrimeTargetDrp] = useState([]);
  const [crimeSuspectDrp, setCrimeSuspectDrp] = useState([]);
  const [crimeSecurityviolatedDrp, setCrimeSecurityviolatedDrp] = useState([]);
  const [methodOfOperationDrp, setMethodOfOperationDrp] = useState();
  const [methodEntryDrp, setMethodEntryDrp] = useState();
  const [weaponDrp, setWeaponDrp] = useState();
  const [weaponCode, setWeaponCode] = useState('');
  const [crimeActivityNoneStatus, setCrimeActivityNoneStatus] = useState();
  const [crimeActSelectedCodeArray, setCrimeActSelectedCodeArray] = useState([]);
  const [BiasSelectCodeArray, setBiasSelectCodeArray] = useState([]);
  const [WeaponSelectCodeArray, setWeaponSelectCodeArray] = useState([]);

  // Edit Value Data
  const [editval, setEditval] = useState([]);
  const [pointExitEditVal, setPointExitEditVal] = useState([]);
  const [pointEntryEditVal, setPointEntryEditVal] = useState([]);
  const [crimeOffenderUseEditVal, setCrimeOffenderUseEditVal] = useState([]);
  const [criminalActivityEditVal, setCriminalActivityEditVal] = useState([]);
  const [crimeBiasCategoryEditVal, setCrimeBiasCategoryEditVal] = useState([]);
  const [crimeToolsUseEditVal, setCrimeToolsUseEditVal] = useState([]);
  const [crimeTargeteEditVal, setCrimeTargeteEditVal] = useState([]);
  const [crimeSuspectEditVal, setCrimeSuspectEditVal] = useState([]);
  const [securityViolatedEditVal, setSecurityViolatedEditVal] = useState([]);
  const [methodOfOperationEditVal, setmethodOfOperationEditVal] = useState([]);
  const [methodOfEntryEditVal, setmethodOfEntryEditVal] = useState([]);
  const [weaponEditVal, setweaponEditVal] = useState([]);
  const [openPage, setOpenPage] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [loginPinID, setLoginPinID] = useState('');

  // DRP IDS Arrays objects
  const [crimePointOfEntry, setCrimePointOfEntry] = useState([]);
  const [pretendToBeID, setPretendToBeID] = useState([]);
  const [crimePointOfExitID, setCrimePointOfExitID] = useState([]);
  const [crimeOffenderUse, setCrimeOffenderUse] = useState([]);
  const [crimeActivity, setCrimeActivity] = useState([]);
  const [crimeBiasCategory, setCrimeBiasCategory] = useState([]);
  const [crimeToolsUse, setCrimeToolsUse] = useState([]);
  const [crimeTarget, setCrimeTarget] = useState([]);
  const [crimeSuspect, setCrimeSuspect] = useState([]);
  const [securityViolated, setSecurityViolated] = useState([]);
  const [methodOfOperation, setMethodOfOperation] = useState([]);
  const [methodOfEntry, setMethodOfEntry] = useState([]);
  const [weaponID, setWeaponID] = useState([]);
  const [methodOfEntryCode, setMethodOfEntryCode] = useState('');
  const [methodEntryDelID, setMethodEntryDelID] = useState('');
  const [bias09CCodeStatus, setBias09CCodeStatus] = useState(false);

  const [errors, setErrors] = useState({ 'MethodOfEnrtyError': '', 'WeaponTypeError': '', 'CriminalActivityError': '' });

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID);
      setLoginPinID(localStoreData?.PINID);
    }
  }, [localStoreData]);

  const [multiSelected, setMultiSelected] = useState({
    PretendToBeID: null, CrimePointOfExitID: null, CrimeOffenderUse: null, CrimeActivity: null, CrimeBiasCategory: null, CrimeToolsUse: null, CrimeTarget: null, CrimeSuspect: null, SecurityViolated: null, methodOfOperation: null, methodOfEntry: null, weaponID: null,
  })

  useEffect(() => {
    if (offenceID) { GetBasicInfoData(); get_Point_Exit_Data(); get_Point_Entry_Data(); get_Crime_OffenderUse_Data(); get_Criminal_Activity_Data(); get_Crime_Bias_Category_Data(); get_Crime_Tools_Use_Data(); get_Crime_Target_Data(); get_Crime_Suspect_Data(); get_Security_Violated_Data(); get_MethodOfOperation_Data(); get_MethodOfEntry_Data(); get_Weapon_Data(); }
  }, [])

  const GetBasicInfoData = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('CrimePretendToBe/GetData_CrimePretendToBe', val)
      .then((res) => {
        if (res) {
          setEditval(Comman_changeArrayFormatBasicInfo(res, 'PretendToBeID', 'Description', 'PretendToBeID', 'CrimePretendID', 'PretendToBeCode'));
        }
        else {
          setEditval([]);
        }
      })
  }

  const get_Point_Exit_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffensePointOfExit/GetDataOffensePointOfExit', val)
      .then((res) => {
        if (res) {
          setPointExitEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimePointOfExitID', 'Description', 'PretendToBeID', 'PointOfExitID', 'PointOfExitCode'));
        } else {
          setPointExitEditVal([]);
        }
      })
  }

  const get_Point_Entry_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffensePointOfEntry/GetData_OffensePointOfEntry', val)
      .then((res) => {
        if (res) {
          setPointEntryEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimePointOfEntryID', 'Description', 'PretendToBeID', 'PointOfEntryID', 'EntryPointCode'));
        }
        else {
          setPointEntryEditVal([]);
        }
      })
  }

  const get_Crime_OffenderUse_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffenseOffenderUse/GetData_OffenseOffenderUse', val)
      .then((res) => {
        if (res) {
          setCrimeOffenderUseEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeOffenderUseID', 'Description', 'PretendToBeID', 'OffenderUseID', 'OffenderUseCode'));
        }
        else {
          setCrimeOffenderUseEditVal([]);
        }
      })
  }

  const get_Criminal_Activity_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('CriminalActivity/GetData_OffenseCriminalActivity', val)
      .then((res) => {
        if (res) {
          // console.log("🚀 ~ .then ~ res:", res);
          setCriminalActivityEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeActivityID', 'Description', 'PretendToBeID', 'ActivityID', 'CriminalActivityCode'));
        }
        else {
          setCriminalActivityEditVal([]);
        }
      })
  }

  const get_Crime_Bias_Category_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffenseBiasCategory/GetData_OffenseBiasCategory', val)
      .then((res) => {
        if (res) {
          setCrimeBiasCategoryEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeBiasCategoryID', 'Description', 'PretendToBeID', 'BiasCategoryID', 'BiasCode'));
        }
        else {
          setCrimeBiasCategoryEditVal([]);
        }
      })
  }

  const get_Crime_Tools_Use_Data = () => {
    const val = { 'CrimeID': offenceID }
    fetchPostData('OffenseToolsUse/GetData_OffenseToolsUse', val)
      .then((res) => {
        if (res) {
          setCrimeToolsUseEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeToolsUseID', 'Description', 'PretendToBeID', 'ToolsUseID', 'ToolsUseCode'));
        }
        else {
          setCrimeToolsUseEditVal([]);
        }
      })
  }

  const get_Crime_Target_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffenseTarget/GetData_OffenseTarget', val)
      .then((res) => {
        if (res) {
          setCrimeTargeteEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeTargetID', 'Description', 'PretendToBeID', 'TargetID', 'TargetCode'));
        }
        else {
          setCrimeTargeteEditVal([]);
        }
      })
  }

  const get_Crime_Suspect_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffenseSuspect/GetData_OffenseSuspect', val)
      .then((res) => {
        if (res) {
          // console.log("🚀 ~ .then ~ res:", res);
          setCrimeSuspectEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeSuspectID', 'Description', 'PretendToBeID', 'SuspectID', 'SuspectCode'));
        }
        else {
          setCrimeSuspectEditVal([]);
        }
      })
  }

  const get_Security_Violated_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffenseSecurityViolated/GetData_OffenseSecurityViolated', val)
      .then((res) => {
        if (res) {
          setSecurityViolatedEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeSecurityviolatedID', 'Description', 'PretendToBeID', 'SecurityviolatedID', 'SecurityviolatedCode'));
        }
        else {
          setSecurityViolatedEditVal([]);
        }
      })
  }

  // weapon
  const get_Weapon_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffenseWeapon/GetData_OffenseWeapon', val).then((res) => {
      if (res) {
        setweaponEditVal(Comman_changeArrayFormatBasicInfo(res, 'WeaponID', 'Weapon_Description', 'PretendToBeID', 'CrimeID', 'WeaponCode'))
      } else {
        setweaponEditVal([]);
      }
    })
  }

  useEffect(() => {
    if (editval) { setPretendToBeID(editval) }
  }, [editval])

  useEffect(() => {
    if (pointExitEditVal) { setCrimePointOfExitID(pointExitEditVal) }
  }, [pointExitEditVal])

  useEffect(() => {
    if (pointEntryEditVal) { setCrimePointOfEntry(pointEntryEditVal) }
  }, [pointEntryEditVal])

  useEffect(() => {
    if (crimeOffenderUseEditVal) { setCrimeOffenderUse(crimeOffenderUseEditVal) }
  }, [crimeOffenderUseEditVal])

  useEffect(() => {
    if (criminalActivityEditVal) {
      setCrimeActivity(criminalActivityEditVal);
      const noneStatus = criminalActivityEditVal?.filter((item) => { if (item?.code === "N") { return true } });
      setCrimeActivityNoneStatus(noneStatus?.length > 0);

      const crimeActivityCodesArray = criminalActivityEditVal?.map((item) => { return item?.code });
      setCrimeActSelectedCodeArray(crimeActivityCodesArray);

    }
  }, [criminalActivityEditVal])

  useEffect(() => {
    if (crimeBiasCategoryEditVal) {
      setCrimeBiasCategory(crimeBiasCategoryEditVal);
      const BiasCodesArray = crimeBiasCategoryEditVal?.map((item) => { return item?.code });
      const Bias09CCode = crimeBiasCategoryEditVal?.map((item) => { return item?.code === "88" });
      setBias09CCodeStatus(Bias09CCode?.length > 0)
      // console.log("%c🚀 ~ useEffect ~ BiasCodesArray:" + `${BiasCodesArray}`, "padding: 6px; font-weight: bold; background-color:pink; color: black'");
      setBiasSelectCodeArray(BiasCodesArray);
    }
  }, [crimeBiasCategoryEditVal])

  useEffect(() => {
    if (crimeToolsUseEditVal) { setCrimeToolsUse(crimeToolsUseEditVal) }
  }, [crimeToolsUseEditVal])

  useEffect(() => {
    if (crimeTargeteEditVal) { setCrimeTarget(crimeTargeteEditVal) }
  }, [crimeTargeteEditVal])

  useEffect(() => {
    if (crimeSuspectEditVal) { setCrimeSuspect(crimeSuspectEditVal) }
  }, [crimeSuspectEditVal])

  useEffect(() => {
    if (securityViolatedEditVal) { setSecurityViolated(securityViolatedEditVal) }
  }, [securityViolatedEditVal])

  useEffect(() => {
    if (methodOfOperationEditVal) { setMethodOfOperation(methodOfOperationEditVal) }
  }, [methodOfOperationEditVal])

  useEffect(() => {
    if (methodOfEntryEditVal) {
      setMethodEntryDelID(methodOfEntryEditVal[0]?.id);
      setMethodOfEntryCode(methodOfEntryEditVal[0]?.value)
    }
  }, [methodOfEntryEditVal])

  useEffect(() => {
    if (weaponEditVal) {
      setWeaponID(weaponEditVal);
      const WeaponCodesArray = weaponEditVal?.map((item) => { return item?.code });
      setWeaponSelectCodeArray(WeaponCodesArray);
    }
  }, [weaponEditVal])

  // Onchange Fuction
  const Agencychange = (multiSelected) => {
    // setMultiSelected({
    //   ...multiSelected,
    //   PretendToBeID: multiSelected
    // })
    setPretendToBeID(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < editval?.length) {
      let missing = null;
      let i = editval.length;
      while (i) {
        missing = (~multiSelected.indexOf(editval[--i])) ? missing : editval[i];
      }
      // DelSertBasicInfo(missing.id, 'CrimePretendID', 'CrimePretendToBe/DeleteCrimePretendToBe')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'PretendToBeID', 'CrimePretendToBe/InsertCrimePretendToBe')
    }
  }

  const poinOfExitchange = (multiSelected) => {
    setCrimePointOfExitID(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < pointExitEditVal?.length) {
      let missing = null;
      let i = pointExitEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(pointExitEditVal[--i])) ? missing : pointExitEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'PointOfExitID', 'OffensePointOfExit/DeleteOffensePointOfExit')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimePointOfExitID', 'OffensePointOfExit/InsertOffensePointOfExit')
    }
  }

  const poinOfEntrychange = (multiSelected) => {
    setCrimePointOfEntry(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < pointEntryEditVal?.length) {
      let missing = null;
      let i = pointEntryEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(pointEntryEditVal[--i])) ? missing : pointEntryEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'PointOfEntryID', 'OffensePointOfEntry/DeleteOffensePointOfEntry')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimePointOfEntryID', 'OffensePointOfEntry/InsertOffensePointOfEntry')
    }
  }

  const OffenderUsechange = (multiSelected) => {

    // const status = multiSelected?.filter((item) => { if (item.id === "N") return item });
    // if (status?.length > 0) {
    //   setCrimeOffenderUse(status)
    // } else {
    //   setCrimeOffenderUse(multiSelected)
    // }

    setCrimeOffenderUse(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeOffenderUseEditVal?.length) {
      let missing = null;
      let i = crimeOffenderUseEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeOffenderUseEditVal[--i])) ? missing : crimeOffenderUseEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'OffenderUseID', 'OffenseOffenderUse/DeleteOffenseOffenderUse')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimeOffenderUseID', 'OffenseOffenderUse/InsertOffenseOffenderUse')
    }
  }

  const CrimeActivitychange = (multiSelected) => {
    setCrimeActivity(multiSelected);
    const len = multiSelected.length - 1
    if (multiSelected?.length < criminalActivityEditVal?.length) {
      let missing = null;
      let i = criminalActivityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(criminalActivityEditVal[--i])) ? missing : criminalActivityEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'ActivityID', 'CriminalActivity/DeleteOffenseCriminalActivity')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimeActivityID', 'CriminalActivity/InsertOffenseCriminalActivity')
    }
  }

  const CrimeBiasCategorychange = (multiSelected) => {
    setCrimeBiasCategory(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeBiasCategoryEditVal?.length) {
      let missing = null;
      let i = crimeBiasCategoryEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeBiasCategoryEditVal[--i])) ? missing : crimeBiasCategoryEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'BiasCategoryID', 'OffenseBiasCategory/DeleteOffenseBiasCategory')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimeBiasCategoryID', 'OffenseBiasCategory/InsertOffenseBiasCategory')
    }
  }

  const CrimeToolsUsechange = (multiSelected) => {
    setCrimeToolsUse(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeToolsUseEditVal?.length) {
      let missing = null;
      let i = crimeToolsUseEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeToolsUseEditVal[--i])) ? missing : crimeToolsUseEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'ToolsUseID', 'OffenseToolsUse/DeleteOffenseToolsUse')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimeToolsUseID', 'OffenseToolsUse/InsertOffenseToolsUse')
    }
  }

  const CrimeTargetchange = (multiSelected) => {
    setCrimeTarget(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeTargeteEditVal?.length) {
      let missing = null;
      let i = crimeTargeteEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeTargeteEditVal[--i])) ? missing : crimeTargeteEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'TargetID', 'OffenseTarget/DeleteOffenseTarget')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimeTargetID', 'OffenseTarget/InsertOffenseTarget')
    }
  }

  const CrimeSuspectchange = (multiSelected) => {
    setCrimeSuspect(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeSuspectEditVal?.length) {
      let missing = null;
      let i = crimeSuspectEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeSuspectEditVal[--i])) ? missing : crimeSuspectEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'SuspectID', 'OffenseSuspect/DeleteOffenseSuspect')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimeSuspectID', 'OffenseSuspect/InsertOffenseSuspect')
    }
  }

  const SecurityViolatedchange = (multiSelected) => {
    setSecurityViolated(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < securityViolatedEditVal?.length) {
      let missing = null;
      let i = securityViolatedEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(securityViolatedEditVal[--i])) ? missing : securityViolatedEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'SecurityviolatedID', 'OffenseSecurityViolated/DeleteOffenseSecurityViolated')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimeSecurityviolatedID', 'OffenseSecurityViolated/InsertOffenseSecurityViolated')
    }
  }

  const CrimeMethodOfEntrychange = (multiSelected) => {
    // console.log(multiSelected)
    // setMethodOfEntryCode(multiSelected[0]?.id)
    const len = multiSelected.length - 1
    setMethodOfEntry(len < 1 ? [] : multiSelected.slice(0, len));
    if (multiSelected?.length < methodOfEntryEditVal?.length) {
      let missing = null;
      let i = methodOfEntryEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(methodOfEntryEditVal[--i])) ? missing : methodOfEntryEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'MethodOfEntryID', 'OffenseMethodOfEntry/Delete_OffenseMethodOfEntry')
    } else {
      if (len < 1) {
        // InSertBasicInfo(multiSelected[len].value, 'CrimeMethodOfEntryID', 'OffenseMethodOfEntry/Insert_OffenseMethodOfEntry')
      } else {
        toastifyError('please select only one');
      }
    }
  }

  const Weaponchange = (multiSelected) => {
    setWeaponID(multiSelected)
    const len = multiSelected.length - 1
    if (multiSelected?.length < weaponEditVal?.length) {
      let missing = null;
      let i = weaponEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(weaponEditVal[--i])) ? missing : weaponEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'WeaponID', 'OffenseWeapon/Delete_OffenseWeapon')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'WeaponTypeID', 'OffenseWeapon/Insert_OffenseWeapon')
    }
  }

  useEffect(() => {
    if (openPage || loginAgencyID) {
      getPretendTobeDrpVal(loginAgencyID); getPointExitTobeDrpVal(loginAgencyID); getPointEntryTobeDrpVal(loginAgencyID); getCrimeOffenderUseDrpVal(loginAgencyID); getCrimeActivityDrpVal(loginAgencyID); getCrimeBiasCategoryDrpVal(loginAgencyID); getCrimeToolsUseDrpVal(loginAgencyID); getCrimeTargetDrpVal(loginAgencyID); getCrimeSuspectDrpVal(loginAgencyID); getCrimeSecurityviolatedDrpVal(loginAgencyID); get_MethodOfOperation_DropDown(loginAgencyID); get_MethodOfEntry_DropDown(loginAgencyID); get_Weapon_DropDown(loginAgencyID);
    }
  }, [openPage, loginAgencyID])

  const getPretendTobeDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('PretendToBe/GetDataDropDown_PretendToBe', val).then((data) => {
      if (data) {
        setPretentedDrp(threeColArrayWithCode(data, 'PretendToBeID', 'Description', 'PretendToBeCode'))
      } else {
        setPretentedDrp([]);
      }
    })
  }

  const getPointExitTobeDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimePointOfexit/GetDataDropDown_CrimePointOfexit', val).then((data) => {
      if (data) {
        setPointExitDrp(threeColArrayWithCode(data, 'PointOfExit', 'Description', 'PointOfExitCode'))
      } else {
        setPointExitDrp([]);
      }
    })
  }

  const getPointEntryTobeDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimePointOfEntry/GetDataDropDown_CrimePointOfEntry', val).then((data) => {
      if (data) {
        setPointEntryDrp(threeColArrayWithCode(data, 'EntryPointId', 'Description', 'EntryPointCode'))
      } else {
        setPointEntryDrp([]);
      }
    })
  }

  const getCrimeOffenderUseDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimeOffenderUse/GetDataDropDown_CrimeOffenderUse', val).then((data) => {
      if (data) {
        setCrimeOffenderUseDrp(threeColArrayWithCode(data, 'OffenderUseID', 'Description', 'OffenderUseCode'))
      } else {
        setCrimeOffenderUseDrp([]);
      }
    })
  }

  const getCrimeActivityDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CriminalActivity/GetDataDropDown_CriminalActivity', val).then((data) => {
      if (data) {
        setCrimeActivityDrp(threeColArrayWithCode(data, 'CriminalActivityID', 'Description', 'CriminalActivityCode'))
      } else {
        setCrimeActivityDrp([]);
      }
    })
  }

  const getCrimeBiasCategoryDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimeBias/GetDataDropDown_CrimeBias', val).then((data) => {
      if (data) {
        // console.log("🚀 ~ fetchPostData ~ data:", data);
        setCrimeBiasCategoryDrp(threeColArrayWithCode(data, 'BiasID', 'Description', 'BiasCode'))
      } else {
        setCrimeBiasCategoryDrp([]);
      }
    })
  }

  const getCrimeToolsUseDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimeToolsUse/GetDataDropDown_CrimeToolsUse', val).then((data) => {
      if (data) {
        setToolsUseIDDrp(threeColArrayWithCode(data, 'ToolsUseID', 'Description', 'ToolsUseCode'))
      } else {
        setToolsUseIDDrp([]);
      }
    })
  }

  const getCrimeTargetDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimeTarget/GetDataDropDown_CrimeTarget', val).then((data) => {
      if (data) {
        setCrimeTargetDrp(threeColArrayWithCode(data, 'TargetID', 'Description', 'TargetCode'))
      } else {
        setCrimeTargetDrp([]);
      }
    })
  }

  const getCrimeSuspectDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimeSuspect/GetDataDropDown_CrimeSuspect', val).then((data) => {
      if (data) {
        setCrimeSuspectDrp(threeColArrayWithCode(data, 'SuspectID', 'Description', 'SuspectCode'))

      } else {
        setCrimeSuspectDrp([]);
      }
    })
  }

  const getCrimeSecurityviolatedDrpVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimeSecurityviolated/GetDataDropDown_CrimeSecurityviolated', val).then((data) => {
      if (data) {
        setCrimeSecurityviolatedDrp(threeColArrayWithCode(data, 'SecurityviolatedID', 'Description', 'SecurityviolatedCode'))
      } else {
        setCrimeSecurityviolatedDrp([]);
      }
    })
  }

  const CrimeMethodOfOpeationchange = (multiSelected) => {
    // setMultiSelected({
    //   ...multiSelected,
    //   methodOfOperation: multiSelected
    // })
    setMethodOfOperation(multiSelected);
    const len = multiSelected.length - 1
    if (multiSelected?.length < methodOfOperationEditVal?.length) {
      let missing = null;
      let i = methodOfOperationEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(methodOfOperationEditVal[--i])) ? missing : methodOfOperationEditVal[i];
      }
      // DelSertBasicInfo(missing.id, 'methodOfOperationID', 'OffenseMethodOfOperation/Delete_OffenseMethodOfOperation')
    } else {
      // InSertBasicInfo(multiSelected[len].value, 'CrimeMethodOfOpeationID', 'OffenseMethodOfOperation/Insert_OffenseMethodOfOperation')
    }
  }

  const check_Validation_Error = (e) => {
    const methodEntryArr = methodOfEntryCode ? [methodOfEntryCode] : []
    const MethodOfEnrtyErr = checkMethodOfEntryIsRequire(nibrsCode, loginAgencyState) ? validateFields(methodEntryArr) : 'true';
    const WeaponTypeErr = checkWeaponTypeIsRequire(nibrsCode, loginAgencyState) ? validateFields(weaponID) : 'true';
    const CriminalActivityErr = checkCriminalActivityIsRequire(nibrsCode, loginAgencyState) ? validateFields(crimeActivity) : 'true';
    setErrors(pre => {
      return {
        ...pre,
        ['MethodOfEnrtyError']: MethodOfEnrtyErr || pre['MethodOfEnrtyError'],
        ['WeaponTypeError']: WeaponTypeErr || pre['WeaponTypeError'],
        ['CriminalActivityError']: CriminalActivityErr || pre['CriminalActivityError'],
      }
    })
  }

  const { MethodOfEnrtyError, WeaponTypeError, CriminalActivityError } = errors

  useEffect(() => {
    if (MethodOfEnrtyError === 'true' && WeaponTypeError === 'true' && CriminalActivityError === 'true') {
      // console.log("Call useEffect")
      InSertBasicInfo();
    }
  }, [MethodOfEnrtyError, WeaponTypeError, CriminalActivityError])

  const InSertBasicInfo = () => {
    const val = {
      'CrimeID': offenceID,
      'CreatedByUserFK': loginPinID,
      'PretendToBeID': pretendToBeID.map((item) => item?.value),
      'CrimePointOfExitID': crimePointOfExitID.map((item) => item?.value),
      'CrimePointOfEntryID': crimePointOfEntry.map((item) => item?.value),
      'CrimeOffenderUseID': crimeOffenderUse.map((item) => item?.value),
      'CrimeActivityID': crimeActivity.map((item) => item?.value),
      'CrimeBiasCategoryID': crimeBiasCategory.map((item) => item?.value),
      'CrimeToolsUseID': crimeToolsUse.map((item) => item?.value),
      'CrimeTargetID': crimeTarget.map((item) => item?.value),
      'CrimeSuspectID': crimeSuspect.map((item) => item?.value),
      'CrimeSecurityviolatedID': securityViolated.map((item) => item?.value),
      'CrimeMethodOfOpeationID': methodOfOperation.map((item) => item?.value),
      'CrimeMethodOfEntryID': [methodOfEntryCode],
      'WeaponTypeID': weaponID.map((item) => item?.value),
    }
    AddDeleteUpadate('Crime/Insert_OffenseInformation', val).then((res) => {
      if (res) {
        const parsedData = JSON.parse(res.data);
        // console.log(parsedData)
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
        GetBasicInfoData();
        get_Point_Exit_Data();
        get_Point_Entry_Data();
        get_Crime_OffenderUse_Data();
        get_Criminal_Activity_Data();
        get_Crime_Bias_Category_Data();
        get_Crime_Tools_Use_Data();
        get_Crime_Target_Data();
        get_Crime_Suspect_Data();
        get_Security_Violated_Data();
        get_MethodOfOperation_Data();
        get_MethodOfEntry_Data();
        get_Weapon_Data();
        setErrors({ ...errors, 'MethodOfEnrtyError': '', 'CriminalActivityError': '', 'WeaponTypeError': '', })
      } else {
        console.log("Somthing Wrong");
        setErrors({ ...errors, 'MethodOfEnrtyError': '', 'CriminalActivityError': '', 'WeaponTypeError': '', })
      }
    })
    // console.log(val);
  }

  // const InSertBasicInfo = (id, col1, url) => {
  //   const val = { 'CrimeID': offenceID, [col1]: id, 'CreatedByUserFK': loginPinID, }
  //   AddDeleteUpadate(url, val).then((res) => {
  //     if (res) {
  //       const parsedData = JSON.parse(res.data);
  //       const message = parsedData.Table[0].Message;
  //       toastifySuccess(message);
  //       col1 === 'PretendToBeID' && GetBasicInfoData();
  //       col1 === 'CrimePointOfExitID' && get_Point_Exit_Data();
  //       col1 === 'CrimePointOfEntryID' && get_Point_Entry_Data();
  //       col1 === 'CrimeOffenderUseID' && get_Crime_OffenderUse_Data();
  //       col1 === 'CrimeActivityID' && get_Criminal_Activity_Data();
  //       col1 === 'CrimeBiasCategoryID' && get_Crime_Bias_Category_Data();
  //       col1 === 'CrimeToolsUseID' && get_Crime_Tools_Use_Data();
  //       col1 === 'CrimeTargetID' && get_Crime_Target_Data();
  //       col1 === 'CrimeSuspectID' && get_Crime_Suspect_Data();
  //       col1 === 'CrimeSecurityviolatedID' && get_Security_Violated_Data();
  //       col1 === 'CrimeMethodOfOpeationID' && get_MethodOfOperation_Data();
  //       col1 === 'CrimeMethodOfEntryID' && get_MethodOfEntry_Data();
  //       col1 === 'WeaponTypeID' && get_Weapon_Data();
  //     } else {
  //       console.log("Somthing Wrong");
  //     }
  //   })
  // }

  const DelSertBasicInfo = (CrimePretendID, col1, url) => {
    const val = { [col1]: CrimePretendID, 'DeletedByUserFK': loginPinID, }
    AddDeleteUpadate(url, val).then((res) => {
      const parsedData = JSON.parse(res.data);
      const message = parsedData.Table[0].Message;
      if (res) {
        toastifySuccess(message);
        col1 === 'CrimePretendID' && GetBasicInfoData();
        col1 === 'PointOfExitID' && get_Point_Exit_Data();
        col1 === 'PointOfEntryID' && get_Point_Entry_Data();
        col1 === 'OffenderUseID' && get_Crime_OffenderUse_Data();
        col1 === 'ActivityID' && get_Criminal_Activity_Data();
        col1 === 'BiasCategoryID' && get_Crime_Bias_Category_Data();
        col1 === 'ToolsUseID' && get_Crime_Tools_Use_Data();
        col1 === 'TargetID' && get_Crime_Target_Data();
        col1 === 'SuspectID' && get_Crime_Suspect_Data();
        col1 === 'SecurityviolatedID' && get_Security_Violated_Data();
        col1 === 'methodOfOperationID' && get_MethodOfOperation_Data();
        col1 === 'MethodOfEntryID' && get_MethodOfEntry_Data();
        col1 === 'WeaponID' && get_Weapon_Data();
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  //  method of operation
  const get_MethodOfOperation_Data = () => {
    const val = { 'CrimeID': offenceID, };
    fetchPostData('OffenseMethodOfOperation/GetData_OffenseMethodOfOperation', val)
      .then((res) => {
        if (res) {
          setmethodOfOperationEditVal(Comman_changeArrayFormatMethodOfOperation(res, 'MethodOfOperationID', 'CrimeID', 'PretendToBeID', 'CrimeMethodOfOpeationID', 'MethodOfOperation_Description'));
        } else {
          setmethodOfOperationEditVal([]);
        }
      });
  };

  const get_MethodOfOperation_DropDown = (loginAgencyID) => {
    const val = { 'AgencyID': loginAgencyID, }
    fetchPostData('OffenseMethodOfOperation/GetData_InsertGetData_OffenseMethodOfOperation', val).then((data) => {
      if (data) {
        setMethodOfOperationDrp(threeColArrayWithCode(data, 'MethodOfOperationID', 'Description', 'MethodOfOperationCode',));
      }
      else {
        setMethodOfOperationDrp([])
      }
    })
  }

  const get_Weapon_DropDown = (loginAgencyID) => {
    const val = { 'AgencyID': loginAgencyID, }
    fetchPostData('OffenseWeapon/GetData_InsertOffenseWeapon', val).then((data) => {
      if (data) {
        setWeaponDrp(threeColArrayWithCode(data, 'WeaponID', 'Description', 'WeaponCode'));
      } else {
        setWeaponDrp([])
      }
    })
  }

  const get_MethodOfEntry_Data = () => {
    const val = { 'CrimeID': offenceID, }
    fetchPostData('OffenseMethodOfEntry/GetData_OffenseMethodOfEntry', val).then((res) => {
      if (res) {
        // console.log(res)
        setmethodOfEntryEditVal(Comman_changeArrayFormatMethodOfOperation(res, 'MethodOfEntryID', 'CrimeID', 'PretendToBeID', 'CrimeMethodOfEntryID', 'MethodOfEntry_Description'));
      } else {
        setmethodOfEntryEditVal([]);
      }
    })
  }

  const get_MethodOfEntry_DropDown = (loginAgencyID) => {
    const val = { 'AgencyID': loginAgencyID, }
    fetchPostData('OffenseMethodOfEntry/GetData_InsertGetData_OffenseMethodOfEntry', val).then((data) => {
      if (data) {
        setMethodEntryDrp(threeColArrayWithCode(data, 'EntryMethodID', 'Description', 'MethodOfEntryCode'));
      } else {
        setMethodEntryDrp([])
      }
    })
  }

  const onChangeMethodOfEntry = (e) => {
    // console.log(e)
    if (e) {
      setMethodOfEntryCode(e.value)
      const val = { 'CrimeID': offenceID, CrimeMethodOfEntryID: e.value, 'CreatedByUserFK': loginPinID, }
      AddDeleteUpadate('OffenseMethodOfEntry/Insert_OffenseMethodOfEntry', val).then((res) => {
        setErrors({ ...errors, 'MethodOfEnrtyError': '' })
        // console.log(res);
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
      })
    } else {
      const val = { 'CrimeID': offenceID, MethodOfEntryID: methodEntryDelID, 'DeletedByUserFK': loginPinID, }
      AddDeleteUpadate('OffenseMethodOfEntry/Delete_OffenseMethodOfEntry', val).then((res) => {
        setErrors({ ...errors, 'MethodOfEnrtyError': '' })
        setMethodOfEntryCode(0)
        // console.log(res);
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
      })
    }
  }

  function filterArray(arr, key) {
    return [...new Map(arr?.map(item => [item[key], item])).values()]
  }

  const validateFields = (field) => {
    if (field?.length == 0) {
      return 'Required *';
    } else {
      return 'true'
    }
  };

  const getWeaponDrpData = (data, nibrsCode) => {
    // console.log("🚀 ~ getWeaponDrpData ~ nibrsCode:", nibrsCode);
    if (loginAgencyState === 'TX' && nibrsCode == "13B") {
      const weaponData = data.filter((item) => { if (item?.id === '19' || item?.id === '77' || item?.id === '99' || item?.id === '11' || item?.id === '12' || item?.id === '13' || item?.id === '14' || item?.id === '15') return item });
      // console.log("🚀 ~ getWeaponDrpData ~ weaponData:", weaponData);
      return weaponData
    } else if (loginAgencyState === 'TX' && nibrsCode === '09A' && nibrsCode === '09B' && nibrsCode === '09C') {
      const weaponData = data.filter((item) => { if (item?.id != '77' && item?.id != '99') return item });
      return weaponData
    } else {
      return data
    }
  }

  const get_CriminalActivity_DrpData = (data) => {
    const nibrsCodeArray = ["09A", "09B", "100", "120", "11A", "11B", "11C", "11D", "13A", "13B", "13C"]
    if (loginAgencyState === 'TX' && nibrsCodeArray.includes(nibrsCode)) {
      const crimeActiDrpData = data.filter((item) => { if (item?.id === "J" || item?.id === "G" || item?.id === "N") return item });
      return crimeActiDrpData
    } else {
      return data
    }
  }

  const getBiasDrpData = (data) => {
    if (loginAgencyState === 'TX' && nibrsCode === '09A' && nibrsCode === '09B' && nibrsCode === '09C') {
      const weaponData = data.filter((item) => { if (item.id == "88") return item });
      return weaponData
    } else {
      return data
    }
  }

  // Check NotApplicable Offender Using Drp Data
  const getCheckNotApplicable = () => {
    if (loginAgencyState === 'TX') {
      let offenderUsingValues = filterArray(crimeOffenderUse, 'label');
      // console.log(offenderUsingValues)
      const status = offenderUsingValues?.filter((item) => { if (item.code === "N") return item });
      return status?.length > 0 && offenderUsingValues?.length > 1 ? true : false
    } else {
      return false
    }
  }

  const getOffenderUsingDrpData = (data) => {
    if (loginAgencyState === 'TX') {

      let offenderUsingValues = filterArray(crimeOffenderUse, 'label');
      // console.log(offenderUsingValues)
      const status = offenderUsingValues?.filter((item) => { if (item.code === "N") return item });
      // console.log(status?.length > 0)

      return status?.length > 0 ? [] : data
    } else {
      return data
    }
  }

  // Custom Style
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      minHeight: 58,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      minHeight: 58,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  const Nibrs_ErrorStyle = {
    control: base => ({
      ...base,
      backgroundColor: "rgb(255 202 194)",
      minHeight: 58,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      <OffListing  {...{ ListData }} />
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                {/* <Link to={'/ListManagement?page=Pretend%20To%20Be&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Pretented To Be
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Pretend To Be') }} data-target="#ListModel" className='new-link px-0'>
                  Pretented To Be
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-9">
                <SelectBox
                  className="basic-multi-select"
                  options={pretentedDrp}
                  styles={customStylesWithOutColor}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => Agencychange(e)}
                  // value={pretendToBeID}
                  value={filterArray(pretendToBeID, 'label')}
                  placeholder='Select Pretented To Be From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                {/* <Link to={'/ListManagement?page=Point%20Of%20Entry&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Point Of Entry
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Point Of Entry') }} data-target="#ListModel" className='new-link px-0'>
                  Point Of Entry
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-9 mt-1 ">
                <SelectBox
                  className="basic-multi-select"
                  styles={customStylesWithOutColor}
                  name='pointofentry'
                  options={pointEntryDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => poinOfEntrychange(e)}
                  // value={crimePointOfEntry}
                  value={filterArray(crimePointOfEntry, 'label')}
                  placeholder='Select Point Of Entry From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                {/* <Link to={'/ListManagement?page=Criminal%20Activity&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Criminal Activity
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Criminal Activity') }} data-target="#ListModel" className='new-link px-0'>
                  Criminal Activity
                  {
                    loginAgencyState === 'TX' ?
                      checkCrimeActiSuitableCode(nibrsCode, crimeActSelectedCodeArray, loginAgencyState, 'tooltip') ? checkCrimeActiSuitableCode(nibrsCode, crimeActSelectedCodeArray, loginAgencyState, 'tooltip') : <></>
                      :
                      <></>
                  }
                  {/* {
                    loginAgencyState === 'TX' ?

                      check_GangCrime_CrimeCode(nibrsCode, crimeActSelectedCodeArray, 'Tooltip') ? check_GangCrime_CrimeCode(nibrsCode, crimeActSelectedCodeArray, 'Tooltip')
                        :
                        checkCrimeActiSuitableCode(nibrsCode, crimeActSelectedCodeArray, loginAgencyState) ? <ErrorTooltip ErrorStr={CrimeActivitySelectSuitableCodesError} /> : <></>
                      :
                      <></>
                  } */}
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-9 mt-1">
                <SelectBox
                  className="basic-multi-select"
                  // styles={customStylesWithOutColor}
                  styles={
                    loginAgencyState === 'TX'
                      ?
                      checkCrimeActiSuitableCode(nibrsCode, crimeActSelectedCodeArray, loginAgencyState, 'Color') ? checkCrimeActiSuitableCode(nibrsCode, crimeActSelectedCodeArray, loginAgencyState, 'Color')
                        :
                        checkCriminalActivityIsRequire(nibrsCode, loginAgencyState) ? ErrorStyle_CriminalActivity(false)
                          :
                          customStylesWithOutColor
                      :
                      customStylesWithOutColor
                  }
                  // styles={
                  //   loginAgencyState === 'TX'
                  //     ?
                  //     check_GangCrime_CrimeCode(nibrsCode, crimeActSelectedCodeArray, 'Color') ? check_GangCrime_CrimeCode(nibrsCode, crimeActSelectedCodeArray, 'Color')
                  //       :
                  //       checkCriminalActivityIsRequire(nibrsCode, loginAgencyState) ? ErrorStyle_CriminalActivity(false)
                  //         :
                  //         checkCrimeActiSuitableCode(nibrsCode, crimeActSelectedCodeArray, loginAgencyState) ? ErrorStyle_CriminalActivity(true)
                  //           :
                  //           customStylesWithOutColor
                  //     :
                  //     customStylesWithOutColor
                  // }
                  name='CrimeActivity'
                  options={crimeActivityDrp?.length > 0 ? get_CriminalActivity_DrpData(crimeActivityDrp) : []}
                  // options={crimeActivityDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeActivitychange(e)}
                  // value={crimeActivity}
                  value={filterArray(crimeActivity, 'label')}
                  placeholder='Select Criminal Activity From List'
                />
                {errors.CriminalActivityError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CriminalActivityError}</span>
                ) : null}
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                {/* <Link to={'/ListManagement?page=Tools%20Use&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Tools
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Tools Use') }} data-target="#ListModel" className='new-link px-0'>
                  Tools
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-1">
                <SelectBox
                  className="basic-multi-select"
                  styles={customStylesWithOutColor}
                  name='btoolsias'
                  options={toolsUseIDDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeToolsUsechange(e)}
                  menuPlacement="top"
                  // value={crimeToolsUse}
                  value={filterArray(crimeToolsUse, 'label')}
                  placeholder='Select Tools From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                {/* <Link to={'/ListManagement?page=Crime%20Suspect&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Suspect Action
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Crime Suspect') }} data-target="#ListModel" className='new-link px-0'>
                  Suspect Action
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-1">
                <SelectBox
                  className="basic-multi-select"
                  styles={customStylesWithOutColor}
                  name='suspectaction'
                  options={crimeSuspectDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeSuspectchange(e)}
                  menuPlacement="top"
                  // value={crimeSuspect}
                  value={filterArray(crimeSuspect, 'label')}
                  placeholder='Select Suspect Action From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                {/* <Link to={'/ListManagement?page=Method%20Of%20Entry&call=Off-Home?page=CrimeInformation'} className='new-link'>
                  Method Of Entry
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Method Of Entry') }} data-target="#ListModel" className='new-link px-0'>
                  Method Of Entry {
                    loginAgencyState === 'TX' ?
                      nibrsCode === '220' ? <ErrorTooltip ErrorStr={MethodOFEntryMandataryError} /> : <></>
                      :
                      <></>
                  }
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-1">
                <Select
                  // styles={ customStylesWithOutColor}
                  styles={
                    loginAgencyState === 'TX'
                      ?
                      nibrsCode === '220' ? colourStyles : customStylesWithOutColor
                      :
                      customStylesWithOutColor
                  }
                  isDisabled={
                    loginAgencyState === 'TX'
                      ?
                      nibrsCode === '220' ? false : true
                      :
                      false
                  }
                  name='CrimeMethodOfEntryID'
                  menuPlacement='top'
                  isClearable
                  options={methodEntryDrp}
                  value={methodEntryDrp?.filter((obj) => obj.value === methodOfEntryCode)}
                  onChange={(e) => onChangeMethodOfEntry(e)}
                  placeholder='Select Method Of Entry From List'
                  className='only-method'
                // className="basic-multi-select"
                />
                {errors.MethodOfEnrtyError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.MethodOfEnrtyError}</span>
                ) : null}
                {/* dont remove code Dev kashyap */}
                {/* <Select
                  className="basic-multi-select"
                  styles={customStylesWithOutColor}
                  isMulti
                  name='CrimeMethodOfEntryID'
                  menuIsOpen={methodOfEntryEditVal?.length >= 1 ? false : true}
                  menuPlacement='top'
                  isClearable={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  // working after lunch
                  options={methodEntryDrp?.filter((item) => item?.id != methodOfEntryCode)}
                  // options={methodEntryDrp}
                  value={filterArray(methodOfEntry, 'label')}
                  onChange={(e) => CrimeMethodOfEntrychange(e)}
                  placeholder='Select Method Of Entry From List'
                /> */}
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                {/* <Link to={'/ListManagement?page=Method%20Of%20Operation&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Method&nbsp;Of&nbsp;Operation
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Method Of Operation') }} data-target="#ListModel" className='new-link px-0'>
                  Method&nbsp;Of&nbsp;Operation
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-1">
                <SelectBox
                  className="basic-multi-select"
                  styles={customStylesWithOutColor}
                  isMulti
                  isClearable={false}
                  hideSelectedOptions={true}
                  closeMenuOnSelect={false}
                  menuPlacement='top'
                  options={methodOfOperationDrp}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeMethodOfOpeationchange(e)}
                  // value={methodOfOperation}
                  value={filterArray(methodOfOperation, 'label')}
                  placeholder='Select Method Of Operation From List'
                // ref={SelectedValue}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                {/* <Link to={'/ListManagement?page=Point%20Of%20Exit&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Point Of Exit
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Point Of Exit') }} data-target="#ListModel" className='new-link px-0'>
                  Point Of Exit
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-8  ">
                <SelectBox
                  className="basic-multi-select"
                  styles={customStylesWithOutColor}
                  name='pointofexit'
                  options={pointExitDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => poinOfExitchange(e)}
                  // value={crimePointOfExitID}
                  value={filterArray(crimePointOfExitID, 'label')}
                  placeholder='Select Point Of Exit From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                {/* <Link to={'/ListManagement?page=Offender%20Suspected%20of%20Using&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Offender Using  />}
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Offender Suspected of Using') }} data-target="#ListModel" className='new-link px-0'>
                  Offender Using {
                    loginAgencyState === 'TX' ?
                      getCheckNotApplicable() ? <ErrorTooltip ErrorStr={NotApplicableError} /> : <> </>
                      :
                      <></>
                  }
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-2">
                <SelectBox
                  className="basic-multi-select"
                  name='offenderusing'
                  // options={getOffenderUsingDrpData(crimeOffenderUseDrp)}
                  options={crimeOffenderUseDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  styles={
                    loginAgencyState == 'TX' ?
                      getCheckNotApplicable() ? Nibrs_ErrorStyle : customStylesWithOutColor
                      :
                      customStylesWithOutColor
                  }
                  // style={customStylesWithOutColor}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => OffenderUsechange(e)}
                  // value={crimeOffenderUse}
                  value={filterArray(crimeOffenderUse, 'label')}
                  placeholder='Select Offender Using From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                {/* <Link to={'/ListManagement?page=Bias%20Motivation&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Bias  /> : <></>}
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Bias Motivation') }} data-target="#ListModel" className='new-link px-0'>
                  Bias {
                    loginAgencyState === 'TX' ?
                      nibrsCode === '09C' && !bias09CCodeStatus ? <ErrorTooltip ErrorStr={Bias_90C_Error} />
                        :
                        check_Valid_Bias_Code(BiasSelectCodeArray) ? <ErrorTooltip ErrorStr={ValidateBiasCodeError} />
                          :
                          <></>
                      :
                      <></>
                  }
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-1">
                <SelectBox
                  className="basic-multi-select"
                  name='bias'
                  // options={crimeBiasCategoryDrp}
                  options={crimeBiasCategoryDrp?.length > 0 ? getBiasDrpData(crimeBiasCategoryDrp) : []}
                  isClearable={false}
                  // styles={customStylesWithOutColor}
                  styles={
                    loginAgencyState === 'TX' ?
                      nibrsCode === '09C' && !bias09CCodeStatus ? ErrorStyle_NIBRS_09C(nibrsCode)
                        :
                        check_Valid_Bias_Code(BiasSelectCodeArray) ? Nibrs_ErrorStyle : customStylesWithOutColor
                      :
                      customStylesWithOutColor
                  }
                  // styles={
                  //   loginAgencyState === 'TX' ?
                  //     nibrsCode != '09C' && !bias09CCodeStatus ?
                  //       check_Valid_Bias_Code(BiasSelectCodeArray) ? Nibrs_ErrorStyle : customStylesWithOutColor
                  //       :
                  //       ErrorStyle_NIBRS_09C(nibrsCode)
                  //     :
                  //     customStylesWithOutColor
                  // }
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeBiasCategorychange(e)}
                  // value={crimeBiasCategory}
                  value={filterArray(crimeBiasCategory, 'label')}
                  placeholder='Select Bias From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                {/* <Link to={'/ListManagement?page=Crime%20Target&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Target
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Crime Target') }} data-target="#ListModel" className='new-link px-0'>
                  Target
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-1" >
                <SelectBox
                  className="basic-multi-select"
                  name='target'
                  options={crimeTargetDrp}
                  styles={customStylesWithOutColor}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeTargetchange(e)}
                  // value={crimeTarget}
                  value={filterArray(crimeTarget, 'label')}
                  menuPlacement="top"
                  placeholder='Select Target From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                {/* <Link to={'/ListManagement?page=Crime%20Security%20Violated&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Security Violated
                </Link> */}
                <span data-toggle="modal" onClick={() => { setOpenPage('Crime Security Violated') }} data-target="#ListModel" className='new-link px-0'>
                  Security Violated
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-1">
                <SelectBox
                  className="basic-multi-select"
                  name='SecurityViolated'
                  options={crimeSecurityviolatedDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  styles={customStylesWithOutColor}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => SecurityViolatedchange(e)}
                  menuPlacement="top"
                  // value={securityViolated}
                  value={filterArray(securityViolated, 'label')}
                  placeholder='Select Security Violated From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                <span data-toggle="modal" onClick={() => { setOpenPage('Weapon Type') }} data-target="#ListModel" className='new-link px-0'>
                  Weapon Type
                  {
                    loginAgencyState === 'TX' ?
                      checkWeaponTypeValidate(nibrsCode, WeaponSelectCodeArray, 'ToolTip', loginAgencyState)
                      :
                      <></>
                  }
                </span>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-1">
                <SelectBox
                  className="basic-multi-select"
                  name='WeaponTypeID'
                  styles={
                    loginAgencyState === 'TX'
                      ?
                      checkWeaponTypeValidate(nibrsCode, WeaponSelectCodeArray, 'Color', loginAgencyState) ? checkWeaponTypeValidate(nibrsCode, WeaponSelectCodeArray, 'Color', loginAgencyState)
                        :
                        customStylesWithOutColor
                      :
                      customStylesWithOutColor
                  }
                  isClearable={false}
                  options={weaponDrp?.length > 0 ? getWeaponDrpData(weaponDrp, nibrsCode) : []}
                  // options={weaponDrp}
                  hideSelectedOptions={true}
                  closeMenuOnSelect={false}
                  components={{ MultiValue, }}
                  onChange={(e) => Weaponchange(e)}
                  // onChange={(e) => { ChangeDropDown(e, 'WeaponTypeID'); }}
                  value={filterArray(weaponID, 'label')}
                  // value={weaponID}
                  placeholder='Select Weapon Type From List'
                  // ref={SelectedValue}
                  isMulti
                  menuPlacement='top'
                />
                {errors.WeaponTypeError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.WeaponTypeError}</span>
                ) : null}
              </div>
              <div className="col-12  mt-4  text-right">

                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { check_Validation_Error() }}>Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ListModal {...{ openPage, setOpenPage }} />
    </>
  )
}

export default BasicInformation
