import React, { useContext, useEffect, useState } from 'react';
import SelectBox from '../../../../Common/SelectBox';
import Select, { components } from 'react-select';
import DataTable from 'react-data-table-component';
import { base64ToString, changeArrayFormat, changeArrayFormat_WithFilter, Decrypt_Id_Name, tableCustomStyles } from '../../../../Common/Utility';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchPostData } from '../../../../hooks/Api';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { AgencyContext } from '../../../../../Context/Agency/Index';

const MultiValue = (props) => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const DocumentAccess = ({ DecdocumentID }) => {

    const dispatch = useDispatch()
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const incReportedDate = useSelector((state) => state.Agency.incReportedDate);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
    const [selectedOption, setSelectedOption] = useState('Individual');
    const [multiSelected, setMultiSelected] = useState({ optionSelected: null });
    const [EditValue, setEditValue] = useState([])
    const { get_Incident_Count, setChangesStatus, } = useContext(AgencyContext);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return { get: (param) => params.get(param) };
    };

    const query = useQuery();
    var IncID = query?.get("IncId");
    var documentID = query?.get("documentId");

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));

    const [groupList, setGroupList] = useState([])
    // const [DocumentID, setDocumentID] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [value, setValue] = useState({ AgencyID: '', DocumentID: '', GroupName: '', DocumentAccessID: '', DocumentAccess: '' });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(parseInt(localStoreData?.PINID));
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID) {
            get_Group_List(loginAgencyID);
        }
    }, [loginAgencyID]);

    const get_Group_List = (loginAgencyID) => {
        const value = { AgencyId: loginAgencyID }
        fetchPostData("Group/GetData_Group", value).then((res) => {
            if (res) {
                setGroupList(changeArrayFormat(res, 'group'))
                if (res[0]?.GroupID) { setValue({ ...value, ['GroupName']: changeArrayFormat_WithFilter(res, 'group', res[0]?.GroupID) }) }
            }
            else { setGroupList() }
        })
    }

    const Agencychange = (multiSelected) => {
        // setStatesChangeStatus(true)
        setMultiSelected({ optionSelected: multiSelected });
        const id = []
        const name = []
        if (multiSelected) {
            multiSelected.map((item, i) => { id.push(item.value); name.push(item.label) })
            setValue({ ...value, ['DocumentAccessID']: id.toString(), ['DocumentAccess_Name']: name.toString() })
        }
    }

    useEffect(() => {
        if (DecdocumentID) {
            GetSingleData(DecdocumentID)
        }
    }, [DecdocumentID])

    const GetSingleData = (DecdocumentID) => {
        const val = { 'DocumentID': DecdocumentID }
        fetchPostData('IncidentDocumentManagement/GetSingleData_IncidentDocManagement', val)
            .then((res) => {
                if (res) { setEditValue(res) }
                else { setEditValue() }
            })
    }

    useEffect(() => {
        if (EditValue?.length > 0) {
            const accessIDs = EditValue[0]?.DocumentAccessID?.split(',').map(id => parseInt(id));
            setValue({
                ...value,
                'DocumentID': EditValue[0]?.documentID, 'DocumentAccess': EditValue[0]?.DocumentAccess, 'DocumentAccessID': EditValue[0]?.DocumentAccessID,
                'ModifiedByUserFK': loginPinID, 'DocumentAccess_Name': EditValue[0]?.DocumentAccess_Name,
            });
            if (EditValue[0]?.DocumentAccess === 'Group') {
                const initialSelectedOptions = groupList.filter(option => accessIDs?.includes(option.value));
                setMultiSelected({ optionSelected: initialSelectedOptions });
            }
            else {
                const initialSelectedOptions = agencyOfficerDrpData.filter(option => accessIDs?.includes(option.value));
                setMultiSelected({ optionSelected: initialSelectedOptions });
            }
            setSelectedOption(EditValue[0]?.DocumentAccess)


        }
    }, [EditValue, agencyOfficerDrpData, groupList]);



    const reset = () => {
        setValue({ ...value, GroupName: '', DocumentAccessID: '', DocumentAccess: '' });
        // setDocumentID('');
        setMultiSelected({ optionSelected: ' ' }); setSelectedOption("Individual");
    }

    const handleRadioChange = (e) => { setSelectedOption(e.target.id); };

    const columns = [
        {
            name: 'User Name', selector: (row) => row.DocumentName, sortable: true,
        },
    ];

    const columns1 = [
        {
            name: 'Group Name', selector: (row) => row.DocumentName, sortable: true,
        },
    ];

    return (
        <div className="row mt-3">
            <div className="col-12 col-md-12 col-lg-12">
                <fieldset>
                    <legend>Document Access</legend>
                    <div className="row">
                        <div className="col-1 col-md-1 col-lg-1 mt-2 pt-1"></div>
                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="Individual"
                                    checked={selectedOption === 'Individual'}
                                    onChange={handleRadioChange}
                                />
                                <label className="form-check-label" htmlFor="Individual">
                                    Individual
                                </label>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3 mt-2 pt-1">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="Group"
                                    checked={selectedOption === 'Group'}
                                    onChange={handleRadioChange}
                                />
                                <label className="form-check-label" htmlFor="Group">
                                    Group
                                </label>
                            </div>
                        </div>

                    </div>
                </fieldset>
            </div>
            {selectedOption === 'Individual' ? (
                <>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className="label-name">
                            User Name
                        </label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-4 mt-1 mb-1">
                        <SelectBox
                            options={agencyOfficerDrpData}
                            isMulti
                            isDisabled={DecdocumentID}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            onChange={Agencychange}
                            allowSelectAll={true}
                            value={multiSelected.optionSelected}
                        />
                    </div>
                    {/* <div className="btn-box text-right mt-2 col-6 ">
                        <button type="button" className="btn btn-sm btn-success mr-1 mb-2">Search</button>
                        <button type="button" className="btn btn-sm btn-success mr-1 mb-2">Clear</button>
                    </div> */}
                    <div className='mt-3 col-12'>
                        <DataTable
                            dense
                            columns={columns}
                            pagination


                            highlightOnHover
                            customStyles={tableCustomStyles}
                            data={''}
                            noDataComponent={'There are no data to display'}
                            fixedHeader
                            persistTableHead={true}
                            fixedHeaderScrollHeight="300px"
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[100, 150, 200, 500]}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                        <label htmlFor="" className="label-name">
                            Group
                        </label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-4 mt-1 mb-1">
                        <SelectBox
                            options={groupList}
                            isMulti
                            isDisabled={DecdocumentID}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            onChange={Agencychange}
                            allowSelectAll={true}
                            value={multiSelected.optionSelected}
                        />
                    </div>
                    {/* <div className="btn-box text-right mt-2  col-6">
                        <button type="button" className="btn btn-sm btn-success mr-1 mb-2">Search</button>
                        <button type="button" className="btn btn-sm btn-success mr-1 mb-2" onClick={() => { reset() }}>Clear</button>
                    </div> */}
                    <div className='mt-3 col-12'>
                        <DataTable
                            dense
                            columns={columns1}
                            pagination
                            highlightOnHover
                            customStyles={tableCustomStyles}
                            data={''}
                            noDataComponent={'There are no data to display'}
                            fixedHeader
                            persistTableHead={true}
                            fixedHeaderScrollHeight="300px"
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[100, 150, 200, 500]}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default DocumentAccess;
