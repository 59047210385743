import axios from "axios";
class GeoServices {
  getGeoZone = async ({ queryKey }) => {
    const [_key, payload] = queryKey;
    if (payload) {
      return await axios.post(`/CAD/GeoPetrolZone/GetData_Zone`, payload);
    }
    console.warn("zoneType not provided, GeoServices.getGeoZone");
    return null;
  };

  getPremise = async ({ queryKey }) => {
    const [_key, { payload }] = queryKey;
    // if (payload) {
    return await axios.post(`/CAD/GeoPremiseType/GetData_Premise`, payload);
    // }
    // console.warn("payload not provided, GeoServices.getPremise");
    // return null;
  };

  getFlag = async ({ queryKey }) => {
    const [_key, { payload }] = queryKey;
    // if (payload) {
    return await axios.post(`/CAD/GeoFlage/GetData_Flag`, payload);
    // }
    // console.warn("payload not provided, GeoServices.getFlag");
    // return null;
  };

  insertLocation = async (locationPayload) => {
    // const [_key, { payload }] = queryKey;

    // if (payload) {
    return await axios.post(`/CAD/GeoLocation/InsertLocation`, locationPayload);
    // }
    // console.warn("zoneType not provided, GeoServices.insertLocation");
    // return null;
  };

  updateLocation = async (payload) => {
    // const [_key, { payload }] = queryKey;

    // if (payload) {
    return await axios.post(`/CAD/GeoLocation/UpdateLocation`, payload);
    // }
    // console.warn("zoneType not provided, GeoServices.updateLocation");
    // return null;
  };

  insertContactData = async (payload) => {
    // const [_key, { payload }] = queryKey;

    // if (payload) {
    return await axios.post(
      `/CAD/GeoLocationContactDetail/InsertGeoContact`,
      payload
    );
    // }
    // console.warn("zoneType not provided, GeoServices.getGeoPetrolZone");
    // return null;
  };
  updateContactData = async (payload) => {
    // const [_key, { payload }] = queryKey;

    // if (payload) {
    return await axios.post(
      `/CAD/GeoLocationContactDetail/UpdateGeoContact`,
      payload
    );
    // }
    // console.warn("zoneType not provided, GeoServices.getGeoPetrolZone");
    // return null;
  };
  getContactData = async ({ queryKey }) => {
    const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(
        `/CAD/GeoLocationContactDetail/GetData_GeoContact`,
        payload
      );
    }
    console.warn("payload not provided, GeoServices.getContactData");
    return null;
  };

  getSingleContactData = async (payload) => {
    // const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(
        `/CAD/GeoLocationContactDetail/GetDataSingle`,
        payload
      );
    }
    console.warn("payload not provided, GeoServices.getSingleContactData");
    return null;
  };

  deleteContact = async (payload) => {
    // const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(
        `/CAD/GeoLocationContactDetail/DeleteGeoContact`,
        payload
      );
    }
    console.warn("payload not provided, GeoServices.deleteContact");
    return null;
  };

  getLocationData = async (payload) => {
    // const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(`/CAD/GeoLocation/GetLocation`, payload);
    }
    console.warn("payload not provided, GeoServices.getLocationData");
    return null;
  };

  getLocationDataByID = async (payload) => {
    // const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(`/CAD/GeoLocation/Get_LocationByID`, payload);
    }
    console.warn("payload not provided, GeoServices.getLocationDataByID");
    return null;
  };

  getFlagHistoryList = async ({ queryKey }) => {
    console.log("queryKey", queryKey)
    const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(
        `/CAD/Flag/Flags_History`,
        payload
      );
    }
    console.warn("payload not provided, GeoServices.getFlagHistoryList");
    return null;
  };
}

const instance = new GeoServices();

export default instance;
