import React, { useEffect, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AgencyContext } from '../../Context/Agency/Index';
import { Decrypt_Id_Name } from "../Common/Utility";
import IncSidebar from "./SidebarCom/IncSidebar";
import DashboardSidebar from "./SidebarCom/DashboardSidebar";
import ListMangSidebar from "./SidebarCom/ListMangSidebar";
import AgencySidebar from "./SidebarCom/AgencySidebar";
import PersonnelSidebar from "./SidebarCom/PersonnelSidebar";
import { NameSearchSidebar } from "./SidebarCom/NameSearchSidebar";
import ArrestSearch from "./SidebarCom/ArrestSearch";
import ReportSidebar from "./SidebarCom/ReportSidebar";
import SearchSidebar from "./SidebarCom/SearchSidebar";
import ConsolidationSidebar from "./SidebarCom/ConsolidationSidebar";
import PropertyRoomSideBar from "./SidebarCom/PropertyRoomSidebar";
import useNoBackNavigation from "../../useNoBackNavigation";
import ExpungeSidebar from "./SidebarCom/ExpungeSidebar";

const Sidebar = (props) => {

  // useNoBackNavigation();
  const { listManagementSideBar, agencySideBar, propertyRoomSideBar, personnelSideBar, path, incidentSideBar, dashboardSidebar, nameSearchSideBar, arrestSearchSideBar, reportSidebar, searchSidebar, consolidationSideBar , expungeSideBar } = props

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  let openPage = query?.get('page');

  return (
    <>
      <div id="left-sidebar" className="sidebar ">
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <div className="">
            <h5 className="brand-name text-center" >
              GoldLine{" "}
            </h5>
          </div>
          <ul className="metismenu ">
            {
              dashboardSidebar && <DashboardSidebar />
            }
            {
              incidentSideBar && < IncSidebar />
              // (incidentSideBar && !openPage) && < IncSidebar />
            }
            {
              agencySideBar && <AgencySidebar />
            }
            {
              propertyRoomSideBar && <PropertyRoomSideBar />
            }
            {
              consolidationSideBar && <ConsolidationSidebar />
            }
            {
              expungeSideBar &&  <ExpungeSidebar />
            }

            {
              personnelSideBar && <PersonnelSidebar />
            }
            {
              listManagementSideBar && <ListMangSidebar />
            }
            {
              searchSidebar && <SearchSidebar />
            }
            {
              reportSidebar && <ReportSidebar />
            }
            {/* {
              searchSidebar && <SearchSidebar />
            } */}
          </ul>
        </nav>
      </div>
    </>
  );
};


export default Sidebar;
