import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import CADConfirmModal from '../../Common/CADConfirmModal';
import { compareStrings, handleNumberTextNoSpaceKeyDown, isEmpty, isEmptyObject } from '../../../CADUtils/functions/common';
import useObjState from '../../../CADHook/useObjState';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import { toastifyError, toastifySuccess } from '../../../Components/Common/AlertMsg';
import Select from "react-select";
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { coloredStyle_Select } from '../../Utility/CustomStylesForReact';
import Tooltip from '../../Common/Tooltip';

function TypeOfFlagsSection() {
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
    const [pageStatus, setPageStatus] = useState("1");
    const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('');
    const [searchValue2, setSearchValue2] = useState('');
    const [prioritiesDropDown, setPrioritiesDropDown] = useState([]);
    const [filterListData, setFilterListData] = useState([]);
    const [listData, setListData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState('');
    const [activeInactiveData, setActiveInactiveData] = useState({})
    const [confirmType, setConfirmType] = useState('');
    const [loginPinID, setLoginPinID] = useState(1);
    const [loginAgencyID, setLoginAgencyID] = useState('');

    const [
        typeOfFlagsState,
        setTypeOfFlagsState,
        handleTypeOfFlagsState,
        clearTypeOfFlagsState,
    ] = useObjState({
        nameCode: "",
        description: "",
        PriorityID: "",
        FlagID: "",
        IsActive: 1
    })

    const [
        errorTypeOfFlagsState,
        _setErrorTypeOfFlagsState,
        handleErrorTypeOfFlagsState,
        clearErrorTypeOfFlagsState,
    ] = useObjState({
        nameCode: false,
        description: false,
        PriorityID: false,
    });

    const getTypeOfFlagKey = `/CAD/MasterTypeOfFlag/TypeOfFlag`;
    const { data: getTypeOfFlagData, isSuccess: isFetchGetTypeOfFlag, refetch, isError: isNoData } = useQuery(
        [getTypeOfFlagKey, {
            "Action": "GET",
            "IsActive": parseInt(pageStatus),
            "AgencyID": loginAgencyID,
        },],
        MasterTableListServices.getTypeOfFlag,
        {
            refetchOnWindowFocus: false,
            retry: 0,
            enabled: !!loginAgencyID
        }
    );

    const getPriorityKey = `/CAD/MasterPriority/GetMasterPriority`;
    const { data: prioritiesData, isSuccess: isFetchPrioritiesData } = useQuery(
        [getPriorityKey, {
            IsActive: 1,
            AgencyID: loginAgencyID,
        }],
        MasterTableListServices.getMasterPriority,
        {
            refetchOnWindowFocus: false,
            retry: 0,
            enabled: !!loginAgencyID,
        }
    );

    useEffect(() => {
        if (isFetchPrioritiesData && prioritiesData) {
            const data = JSON.parse(prioritiesData?.data?.data);
            setPrioritiesDropDown(data?.Table);
        }
    }, [isFetchPrioritiesData, prioritiesData]);

    useEffect(() => {
        if (localStoreData) {
            setLoginPinID(localStoreData?.PINID);
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (getTypeOfFlagData && isFetchGetTypeOfFlag) {
            const data = JSON.parse(getTypeOfFlagData?.data?.data);
            setFilterListData(data?.Table)
            setListData(data?.Table)
        } else {
            setFilterListData([])
            setListData([])
        }
    }, [getTypeOfFlagData, isFetchGetTypeOfFlag])

    function handleClose() {
        clearTypeOfFlagsState();
        clearErrorTypeOfFlagsState();
    }

    const columns = [
        {
            name: 'Flag Name',
            selector: row => row.FlagNameCode,
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.FlagNameCode, rowB.FlagNameCode),
            style: {
                position: "static",
            },
        },
        {
            name: 'Description',
            selector: row => row.Description,
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.Description, rowB.Description),
            style: {
                position: "static",
            },
            cell: (row) => (
                <Tooltip text={row?.Description || ''} maxLength={30} />
            ),
        },
        {
            name: 'Priority Code',
            selector: row => prioritiesDropDown?.find((i) => i?.PriorityID === row?.PriorityID)?.Description,
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.PriorityID, rowB.PriorityID),
            style: {
                position: "static",
            },
        },
        {
            name: 'Priority Color',
            selector: row => row.PriorityColor,
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.PriorityColor, rowB.PriorityColor),
            style: {
                position: "static",
            },
            cell: row => (
                <div style={{
                    backgroundColor: row.PriorityColor, width: '100%', height: '100%', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                </div>)
        },
        {
            name:
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    {'Status'}
                </div>,
            cell: (row, index) =>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <span
                        className="btn btn-sm text-white px-1 py-0 mr-1"
                        style={{ background: "#ddd", cursor: "pointer" }}
                    >
                        {row?.IsActive ? (
                            <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive"); handleClose() }}></i>
                        ) : (
                            <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); handleClose() }}></i>
                        )}
                    </span>
                </div>,
            width: "70px",
            style: {
                position: "static",
            },
        },
    ];

    async function handelActiveInactive() {
        const data = {
            "FlagID": activeInactiveData?.FlagID,
            "ModifiedByUserFK": loginPinID,
            "IsActive": isActive,
            "AgencyID": loginAgencyID,
        }
        const response = await MasterTableListServices.changeStatusTypeOfFlag(data);
        if (response?.status === 200) {
            const data = JSON.parse(response?.data?.data)?.Table?.[0];
            toastifySuccess(data?.Message);
            refetch();
        }
        setShowModal(false);
        handleClose();
    }

    const validation = () => {
        let isError = false;
        const keys = Object.keys(errorTypeOfFlagsState);
        keys.map((field) => {
            if (
                field === "nameCode" &&
                isEmpty(typeOfFlagsState[field])) {
                handleErrorTypeOfFlagsState(field, true);
                isError = true;
            } else if (
                field === "description" &&
                isEmpty(typeOfFlagsState[field])) {
                handleErrorTypeOfFlagsState(field, true);
                isError = true;
            } else if (field === "PriorityID" && isEmptyObject(typeOfFlagsState[field])) {
                handleErrorTypeOfFlagsState(field, true);
                isError = true;
            } else {
                handleErrorTypeOfFlagsState(field, false);
            }
        });
        return !isError;
    };

    function handelSetEditData(data) {
        setTypeOfFlagsState({
            nameCode: data?.FlagNameCode,
            description: data?.Description,
            PriorityID: data?.PriorityID ? prioritiesDropDown?.find((i) => i?.PriorityID === data?.PriorityID) : "",
            FlagID: data?.FlagID,
            IsActive: data?.IsActive ? 1 : 0
        })
    }

    const conditionalRowStyles = [
        {
            when: row => row?.FlagID === typeOfFlagsState?.FlagID,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: '#001f3fbd',
                    color: 'white',
                },
            },
        }
    ];

    async function handleSave() {
        if (!validation()) return

        const isUpdate = !!typeOfFlagsState?.FlagID;
        const result = listData?.find(item => {
            if (item.FlagNameCode) {
                const code = typeOfFlagsState?.nameCode?.toLowerCase();
                return code && item.FlagNameCode.toLowerCase() === code;
            }
            return false;
        });
        const result1 = listData?.find(item => {
            if (item.Description) {
                const description = typeOfFlagsState?.description?.toLowerCase();
                return description && item.Description.toLowerCase() === description;
            }
            return false;
        });
        if ((result || result1) && !isUpdate) {
            if (result) {
                toastifyError('Code Already Exists');
            }
            if (result1) {
                toastifyError('Description Already Exists');
            }
        } else {
            const payload = {
                Action: isUpdate ? "UPDATE" : "ADD",
                FlagID: isUpdate ? typeOfFlagsState?.FlagID : undefined,
                AgencyID: "19",
                FlagNameCode: typeOfFlagsState?.nameCode,
                Description: typeOfFlagsState?.description,
                PriorityID: typeOfFlagsState?.PriorityID?.PriorityID,
                CreatedByUserFK: isUpdate ? undefined : loginPinID,
                ModifiedByuserFk: isUpdate ? loginPinID : undefined,
                isActive: 1
            };
            const response = await MasterTableListServices.insertTypeOfFlag(payload);

            if (response?.status === 200) {
                toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
                handleClose();
                refetch();
            }
        }
    }

    return (
        <>
            <div className='utilities-tab-content-main-container'>
                <div className='utilities-tab-content-form-container'>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 incident-tab ">
                            <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); handleClose() }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); handleClose() }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
                            </ul>
                        </div>    {
                            pageStatus === '1' ?
                                <>
                                    <div className='utilities-tab-content-form-main'>
                                        <div className="row">
                                            <div className="col-2 d-flex align-self-center justify-content-end">
                                                <label for="" className="tab-form-label">
                                                    Flag Name Code  {errorTypeOfFlagsState.nameCode && isEmpty(typeOfFlagsState?.nameCode) && (
                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Flag Name Code"}</p>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="col-2 d-flex align-self-center justify-content-end">
                                                <input
                                                    name="nameCode"
                                                    type="text"
                                                    className="form-control py-1 new-input requiredColor"
                                                    placeholder='Flag Name Code'
                                                    value={typeOfFlagsState?.nameCode}
                                                    onChange={(e) => handleTypeOfFlagsState("nameCode", e.target.value)}
                                                    onKeyDown={handleNumberTextNoSpaceKeyDown} 
                                                    maxLength={20}   
                                                />
                                            </div>
                                            <div className="col-1 d-flex align-self-center justify-content-end">
                                                <label for="" className="tab-form-label">
                                                    Description  {errorTypeOfFlagsState.description && isEmpty(typeOfFlagsState?.description) && (
                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="col-3 d-flex align-self-center justify-content-end">
                                                <input
                                                    name="description"
                                                    type="text"
                                                    className="form-control py-1 new-input requiredColor"
                                                    placeholder='Description'
                                                    value={typeOfFlagsState?.description}
                                                    onChange={(e) => handleTypeOfFlagsState("description", e.target.value)}
                                                    maxLength={256}
                                                />
                                            </div>
                                            <div className="col-1 d-flex align-self-center justify-content-end">
                                                <label for="" className="tab-form-label">
                                                    Priority  {errorTypeOfFlagsState.PriorityID && isEmpty(typeOfFlagsState?.PriorityID) && (
                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Priority"}</p>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="col-2 d-flex align-self-center justify-content-end">
                                                <Select
                                                    isClearable
                                                    options={prioritiesDropDown}
                                                    placeholder="Select..."
                                                    styles={coloredStyle_Select}
                                                    getOptionLabel={(v) => `${v?.PriorityCode} | ${v?.Description}`}
                                                    getOptionValue={(v) => v?.PriorityCode}
                                                    formatOptionLabel={(option, { context }) => {
                                                        return context === 'menu'
                                                            ? `${option?.PriorityCode} | ${option?.Description}`
                                                            : option?.PriorityCode;
                                                    }}
                                                    className="w-100"
                                                    name="PriorityID"
                                                    value={typeOfFlagsState.PriorityID ? typeOfFlagsState.PriorityID : ""}
                                                    onChange={(e) => { handleTypeOfFlagsState("PriorityID", e) }}
                                                    onInputChange={(inputValue, actionMeta) => {
                                                        if (inputValue.length > 12) {
                                                            return inputValue.slice(0, 12);
                                                        }
                                                        return inputValue;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                </>
                        } </div>
                </div>

                <div className='utilities-tab-content-table-container'>
                    <div className="row mb-2">
                        <div className="col-5 d-flex align-self-center justify-content-end">
                            <input
                                type="text"
                                className="form-control"
                                placeholder='Search By Flag Name...'
                                value={searchValue1}
                                onChange={(e) => {
                                    setSearchValue1(e.target.value);
                                    const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'FlagNameCode', 'Description')
                                    setFilterListData(result)
                                }}
                            />
                        </div>
                        <div className="col-1 d-flex align-self-center justify-content-end" >
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                                    <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col-5 d-flex align-self-center justify-content-end">
                            <input
                                type="text"
                                className="form-control"
                                placeholder='Search By Description...'
                                value={searchValue2}
                                onChange={(e) => {
                                    setSearchValue2(e.target.value);
                                    const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'FlagNameCode', 'Description')
                                    setFilterListData(result)
                                }}
                            />
                        </div>

                        <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                                    <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <DataTable
                            dense
                            columns={columns}
                            data={filterListData}
                            customStyles={tableCustomStyles}
                            conditionalRowStyles={conditionalRowStyles}
                            pagination
                            responsive
                            noDataComponent={true ? "There are no data to display" : 'There are no data to display'}
                            striped
                            persistTableHead={true}
                            highlightOnHover
                            fixedHeader
                            onRowClicked={(row) => {
                                handelSetEditData(row);
                            }}
                        />
                    </div>
                    {parseInt(pageStatus) === 1 &&
                        <div className="utilities-tab-content-button-container" >
                            <button type="button" className="btn btn-sm btn-success" onClick={() => handleClose()} >New</button>
                            <button type="button" className="btn btn-sm btn-success" onClick={() => handleSave()} >{!!typeOfFlagsState?.FlagID ? "Update" : "Save"}</button>
                        </div>}
                </div>
            </div>
            <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
        </>
    )
}

export default TypeOfFlagsSection