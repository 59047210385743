import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Dropdown from 'react-bootstrap/Dropdown';
import { useQuery } from 'react-query';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import { coloredStyle_Select } from '../../Utility/CustomStylesForReact';
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import MasterTableListServices from '../../../CADServices/APIs/masterTableList'
import { isEmpty, compareStrings, handleTextKeyDown } from '../../../CADUtils/functions/common';
import { toastifyError, toastifySuccess } from '../../../Components/Common/AlertMsg';
import { SearchFilter, SendIcon } from '../../Common/SearchFilter'
import CADConfirmModal from '../../Common/CADConfirmModal';
import { useSelector } from 'react-redux';

const PrioritySection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [isActive, setIsActive] = useState('');
  const [confirmType, setConfirmType] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [loginPinID, setLoginPinID] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');

  const [
    priorityState,
    setPriorityState,
    handlePriorityState,
    clearPriorityState,
  ] = useObjState({
    PriorityID: "",
    PriorityCode: "",
    Description: "",
    BackColor: "#000000",
  })
  const [
    errorPriorityState,
    _setErrorPriorityState,
    handleErrorPriorityState,
    clearErrorPriorityState,
  ] = useObjState({
    PriorityCode: false,
    Description: false,
    BackColor: false,
  });

  const validatePriorityForm = () => {
    let isError = false;
    const keys = Object.keys(errorPriorityState);
    keys.map((field) => {
      if (
        field === "PriorityCode" &&
        isEmpty(priorityState[field])) {
        handleErrorPriorityState(field, true);
        isError = true;
      } else if (field === "Description" && isEmpty(priorityState[field])) {
        handleErrorPriorityState(field, true);
        isError = true;
      } else {
        handleErrorPriorityState(field, false);
      }
    });
    return !isError;
  };


  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
      setLoginAgencyID(localStoreData?.AgencyID);

    }
  }, [localStoreData]);


  const getPriorityKey = `/CAD/MasterPriority/GetMasterPriority`;
  const { data: priorityList, isSuccess: isFetchPriorityList, refetch: priorityListRefetch, isError: isNoData } = useQuery(
    [getPriorityKey, {
      IsActive: parseInt(pageStatus),
      AgencyID: loginAgencyID,
    }],
    MasterTableListServices.getMasterPriority,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!loginAgencyID
    }
  );

  useEffect(() => {
    if (isFetchPriorityList && priorityList) {
      const res = JSON.parse(priorityList?.data?.data);
      const data = res?.Table
      setFilterListData(data || [])
      setListData(data || [])
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [isFetchPriorityList, priorityList])

  function handleClose() {
    clearPriorityState();
    clearErrorPriorityState();
  }

  const onSave = async () => {
    if (!validatePriorityForm()) return;

    const result = listData?.find(item => {
      if (item.PriorityCode) {
        const code = priorityState?.PriorityCode?.toLowerCase();
        return code && item.PriorityCode.toLowerCase() === code;
      }
      return false;
    });
    const result1 = listData?.find(item => {
      if (item.Description) {
        const description = priorityState?.Description?.toLowerCase();
        return description && item.Description.toLowerCase() === description;
      }
      return false;
    });

    const result2 = listData?.find(item => {
      if (item.BackColor) {
        const backColor = priorityState?.BackColor?.toLowerCase();
        return backColor && item.BackColor.toLowerCase() === backColor;
      }
      return false;
    });

    if ((result || result1 || result2) && !priorityState?.PriorityID) {
      if (result) {
        toastifyError('Code Already Exists');
      }
      if (result1) {
        toastifyError('Description Already Exists');
      }
      if (result2) {
        toastifyError('BackColor Already Exists');
      }
    } else {
      try {
        const serviceMethod = priorityState?.PriorityID
          ? MasterTableListServices.updateMasterPriority
          : MasterTableListServices.insertMasterPriority;
        const payload = {
          AgencyID: loginAgencyID,
          PriorityID: priorityState?.PriorityID,
          PriorityCode: priorityState?.PriorityCode,
          Description: priorityState?.Description,
          BackColor: priorityState?.BackColor,
          IsActive: 1,
          CreatedByUserFK: loginPinID
        }
        const res = await serviceMethod(payload);

        if (res) {
          const message = priorityState?.PriorityID
            ? "Data Updated Successfully"
            : "Data Saved Successfully";

          toastifySuccess(message);
          priorityListRefetch();
          handleClose();
        }
      } catch (error) {
        console.error("Error during insert/update:", error);
      }
    }
  };

  async function handelActiveInactive() {
    const data = {
      PriorityID: activeInactiveData?.PriorityID,
      DeletedByUserFK: loginPinID,
      IsActive: isActive,
    }
    const response = await MasterTableListServices.changeStatusMasterPriority(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      priorityListRefetch();
    }
    setShowModal(false);
  }

  const columns = [
    {
      name: 'Priority Code',
      selector: row => row.PriorityCode,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.PriorityCode, rowB.PriorityCode),
      style: {
        position: "static",
      },
    },

    {
      name: 'Description',
      selector: row => row.Description,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Description, rowB.Description),
      style: {
        position: "static",
      },
    },
    {
      name: 'Back Color Code',
      selector: row => row.BackColor,
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.BackColor, rowB.BackColor),
      style: {
        position: "static",
      },
      cell: row => (
        <div style={{
          backgroundColor: row.BackColor, width: '100%', height: '100%', display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        </div>
      ),
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.IsActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive") }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); }}></i>
            )}
          </span>
        </div>,
      width: "70px",
      style: {
        position: "static",
      },
    },

  ];

  const setEditValue = (row) => {
    setPriorityState({
      ...row,
      PriorityID: parseInt(row.PriorityID),
      IsActive: row.IsActive ? 1 : 0
    });
  }

  const conditionalRowStyles = [
    {
      when: row => row?.PriorityID === priorityState?.PriorityID,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#001f3fbd',
          color: 'white',
        },
      },
    }
  ];

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Priority Code{errorPriorityState.PriorityCode && isEmpty(priorityState.PriorityCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Priority Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          name="PriorityCode"
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Priority Code'
                          value={priorityState.PriorityCode}
                          onChange={(e) => { handlePriorityState("PriorityCode", e.target.value) }}
                        // onKeyDown={handleTextKeyDown}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Description{errorPriorityState.Description && isEmpty(priorityState.Description) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-3 d-flex align-self-center justify-content-end">
                        <input
                          name="description"
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Description'
                          value={priorityState.Description}
                          onChange={(e) => { handlePriorityState("Description", e.target.value) }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Back Color
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          name="BackColor"
                          type="color"
                          className="w-100 py-1 new-input requiredColor"
                          style={{
                            height: '30px',
                            border: '1px solid #ced4da',
                            borderRadius: '.25rem',
                          }}
                          value={priorityState.BackColor}
                          onChange={(e) => { handlePriorityState("BackColor", e.target.value) }}
                        />
                      </div>
                    </div>

                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                value={searchValue1}
                placeholder='Search By Code...'
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = SearchFilter(listData, e.target.value, searchValue2, filterTypeIdOption, 'PriorityCode', 'Description')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control"
                placeholder='Search By Description...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = SearchFilter(listData, searchValue1, e.target.value, filterTypeDescOption, 'PriorityCode', 'Description')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-responsive">
            <DataTable
              dense
              columns={columns}
              data={filterListData}
              customStyles={tableCustomStyles}
              pagination
              responsive
              conditionalRowStyles={conditionalRowStyles}
              striped
              highlightOnHover
              noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
              fixedHeader
              fixedHeaderScrollHeight="360px"
              onRowClicked={(row) => {
                setEditValue(row);
              }}
              selectableRowsHighlight
              persistTableHead={true}
            />
          </div>

          {parseInt(pageStatus) === 1 && <div className="utilities-tab-content-button-container" >
            <button type="button" className="btn btn-sm btn-success" onClick={() => handleClose()}>New</button>
            <button type="button" className="btn btn-sm btn-success" onClick={() => onSave()}>{!!priorityState?.PriorityID ? "Update" : "Save"}</button>
          </div>}
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  );
};

export default PrioritySection;